import { ToastProps } from 'core/components/Toast';
import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';


export type ToasterContextProps = [
    ToastProps[], 
    (value : ToastProps) => void
];

export const useToaster = () : ToasterContextProps => {

    const [values, setValues] = useState([] as ToastProps[]);

    const addToast = useCallback((value : ToastProps) => {
        const key = _.uniqueId('notification');
        const newValue = {
            ...value,
            key
        };
        setValues(values => [...values, newValue])
    }, []);

    const context = useMemo(() => [
        values, addToast
    ], [values, addToast]);

    return context as ToasterContextProps;
}

export const ToasterContext = React.createContext((value : ToastProps) => {});

