import Publication from "publications/api/models/Publication";
import React from 'react';
import { withEndDot } from 'core/helpers/string';
import ShortUserRenderer from "directory/renderers/ShortUserRenderer";
import Latex from 'react-latex';
import ShortSerialRenderer from "serials/renderers/ShortSerialRenderer";

class PublicationRenderer {

    publication : Publication;

    constructor(publication: Publication) {
        this.publication = publication;
    }

    getAuthors() : React.ReactNode {
        const pub = this.publication;
        if(pub.user_authors.length === 0) {
            return null;
        }
        const authors = pub.user_authors.map(author => (
            author.user === null ? <>{author.getFullName()}</> : (new ShortUserRenderer(author.user)).getFullNameWithTip()
        ));
        const first_author = authors.shift();
        return authors.reduce(((element, author) => <>{element}, {author}</>), first_author);
    }

    getJournal() : React.ReactNode {
        const pub = this.publication;
        if(pub.serial === null) {
            return pub.journal;
        }
        else {
            return (new ShortSerialRenderer(pub.serial)).getTitleWithTip();
        }
    }

    getSerial() : React.ReactNode {
        const pub = this.publication;
        if(pub.serial !== null) {
            return (new ShortSerialRenderer(pub.serial)).getTitleWithTip();
        }
        if(pub.journal !== '') {
            return pub.journal;
        }
        if(pub.book_title !== '') {
            return pub.book_title;
        }
        return null;
    }

    getArxivId() : React.ReactNode {
        const pub = this.publication;
        if(pub.arxiv_id !== null) {
            return <a href={"https://arxiv.org/abs/" + pub.arxiv_id}>{pub.arxiv_id}</a>
        }
        return null;
    }

    getZbmathId() : React.ReactNode {
        const pub = this.publication;
        if(pub.zbmath_id !== null) {
            return <a href={"https://zbmath.org/" + pub.zbmath_id}>{pub.zbmath_id}</a>
        }
        return null;
    }

    getThumbnail() : React.ReactNode {

        const pub = this.publication;

        const downloadFile = () => {
            if(pub.pdf)
            window.location.href = pub.pdf;
        };

        if(pub.thumbnail) {
            return <img role="button" src={pub.thumbnail} onClick={downloadFile} className="w-100 border" alt="PDF" />
        }

        if(pub.pdf) {
            return <img role="button" src="/images/pdf.png" onClick={downloadFile} className="w-100" style={{maxWidth: '128px'}} alt="PDF" />
        }

        return <img src="/images/unknown.png" className="w-100" style={{maxWidth: '128px'}} alt="PDF" />
    }

    getTitle() : React.ReactNode {

        const pub = this.publication;

        return <Latex>{withEndDot(pub.title || pub.book_title)}</Latex>;

    }

    toElement() : React.ReactNode {

        const pub = this.publication;

        const authors = this.getAuthors();

        const title = this.getTitle();
        
        const journal = this.getJournal();

        const volume = pub.volume ? ' ' + pub.volume : '';

        const year = pub.year ? ' (' + pub.year + ')' : '';

        const number = pub.number ? ', no.' + pub.number : '';

        const pages = pub.pages ? ', ' + pub.getPages() : '';

        switch(pub.type) {
            case 'ARTICLE' :
            return <>
                {authors}. 
                <strong> {title}</strong> <em>{journal}.</em> {volume}{year}{number}{pages}
                </>;
    
            default :
            return <>
                {authors}. 
                <strong> {title}</strong> <em>{journal}</em> {volume}{year}{number}{pages}
                </>;
        }
    };


}

export default PublicationRenderer;