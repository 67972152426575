
import BasicForm from "core/components/forms/BasicForm";
import MainLayout from "core/components/layouts/MainLayout";
import LoginForm from "auth/forms/LoginForm";
import React from 'react';
import { Col, Row } from "reactstrap";
import { NavLink } from "react-router-dom";

const LoginPage = () => {

    return  <MainLayout>
        <section className="py-5 row justify-content-center">
        <BasicForm title="Log in" style={{maxWidth: '500px'}}>
            <LoginForm />
            <Row className="mt-2">
                <Col><small><NavLink to="/auth/register">Register</NavLink></small></Col>
                <Col className="text-end"><small><NavLink to="/auth/forgottenPassword">Forgot your password?</NavLink></small></Col>
            </Row>
        </BasicForm>
        </section>
        </MainLayout>;
};

export default LoginPage;