import UserPage from "directory/pages/UserPage";
import UserListPage from 'directory/pages/UserListPage';
import { Routes, Route } from "react-router-dom";
import React from 'react';

const DirectoryRoute : React.FC<{}> = () => {

    return <Routes>
        <Route path="/:userId" element={<UserPage />} />
        <Route path="/" element={<UserListPage />} />
    </Routes>;

};

export default DirectoryRoute;