import { useFormikContext } from "formik";
import { DatePicker } from "react-widgets/cjs";
import React from 'react';
import { DatePickerProps } from "react-widgets/cjs/DatePicker";

/**
    Formik-controlled datepicker field
 */

interface Props extends DatePickerProps<unknown> {
    name : string;
    id : string;
};

const DatePickerField : React.FC<Props> = (props) => {

    const { name } = props;
    const { values , setFieldValue } = useFormikContext<object>();



    return <DatePicker {...props} value={values[name]} onChange={value => setFieldValue(name, value)} />;
};

export default DatePickerField;