import {Form, Row, Col, Button} from 'reactstrap';
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import { institutionAPI } from "institutions/api/entities";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from 'yup';
import { NewInstitutionType, UpdateInstitutionType } from "institutions/api/types";
import AutoDismissAlert from 'core/components/AutoDismissAlert';
import { errorToText } from 'core/helpers/error';
import Institution from "institutions/api/models/Institution";
import { CUForm } from 'core/forms/types';
import { useCallback, useMemo } from 'core/api/equality';
import InputGroup from 'core/forms/groups/InputGroup';
import CitySelectGroup from 'geonames/forms/groups/CitySelectGroup';
import { City } from 'geonames/api/models';
import { useAction } from 'core/hooks/action';
import { ToasterContext } from 'core/hooks/toaster';
import { ObjectWithId } from 'core/api/BaseAPI';

export type InstitutionFormProps = CUForm<Institution> & {
    defaultName? : string;
};

type FormInstitutionType = {
    id? : number,
    name_en : string;
    name_local : string;
    website : string;
    city : City | null;
};

const InstitutionConverter = {

    newInstitution : (name_en : string | undefined) : FormInstitutionType => {
        return {
            name_en : name_en || '',
            name_local : '',
            website : '',
            city : null
        }
    },

    institutionToForm : (institution : Institution) : FormInstitutionType => {
        return institution;
    },

    formToNewInstitution : (values : FormInstitutionType) : NewInstitutionType => {
        return {
            name_en : values.name_en,
            name_local : values.name_local,
            website : values.website,
            city_id : values.city === null ? null : values.city.id
        };
    },


    formToUpdateInstitution : (values : FormInstitutionType & ObjectWithId<number>) : UpdateInstitutionType => {
        return {
            id : values.id,
            name_en : values.name_en,
            name_local : values.name_local,
            website : values.website,
            city_id : values.city === null ? null : values.city.id
        };
    },

}

const InstitutionForm : React.FC<InstitutionFormProps> = ({ 
    value : institution,
    defaultName,
    onButtonsChange,
    onCreate, 
    onUpdate,
    onSubmit,
    displayButtons=true}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const addToast = useContext(ToasterContext);

    const editedInstitution = useMemo(() => (institution === undefined ? 
        InstitutionConverter.newInstitution(defaultName) : InstitutionConverter.institutionToForm(institution)
    ), [institution, defaultName]);

    const handleCreate = useCallback(async (value: NewInstitutionType) => {
        const newValue = await institutionAPI.create(value);
        onCreate && onCreate(newValue);
        onSubmit && onSubmit(newValue);
        addToast({
            type : 'success',
            title : 'Institution created',
            children : <><a href={value.website}>{value.name_en}</a> successfully created</>
        });
        return newValue;
    }, [onCreate, onSubmit, addToast]);

    const handleUpdate  = useCallback(async (value : UpdateInstitutionType) => {
        const updatedValue =  await institutionAPI.update(value);
        onUpdate && onUpdate(updatedValue);
        onSubmit && onSubmit(updatedValue);
        addToast({
            type : 'success',
            title : 'Institution updated',
            children : <><a href={value.website}>{value.name_en}</a> successfully updated</>
        });
        return updatedValue;
    }, [onSubmit, onUpdate, addToast]);

    const submitFunction :  () => void = () => submitAction.trigger();

    const formik = useFormik({
        initialValues : editedInstitution,
        validationSchema : Yup.object({
            name: Yup.string().required('Required')
        }),
        onSubmit : submitFunction
    });

    const {values, isValid, setSubmitting } = formik;


    const submitAction = useAction(useCallback(async () => {
        setSubmitting(false);
        try {
            if(values.id !== undefined) {
                return await handleUpdate(InstitutionConverter.formToUpdateInstitution(values as FormInstitutionType & ObjectWithId<number>));
            }
            return await handleCreate(InstitutionConverter.formToNewInstitution(values));
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return;
    }, [values, handleCreate, handleUpdate, setSubmitting]));

    const buttons = useMemo(() => {
        return [<Button key="save" color="primary" {...submitAction.buttonProps} disabled={!isValid || submitAction.loading}>Save</Button>];
    }, [submitAction, isValid]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange]);

    return <>
    <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

    <FormikProvider value={formik}>
        <Form tag={FkForm}>
            <InputGroup name="name" label="Institution name" />

            <InputGroup name="webpage" label="Webpage" />

            <CitySelectGroup label="City" name="city" />
             
            { displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
            </Row> : null}
        </Form>
    </FormikProvider>
    </>;
};

export default InstitutionForm;