import User from "directory/api/models/User";
import React, { useMemo } from 'react';
import Obfuscate from 'react-obfuscate';
import { Col, Row } from 'reactstrap';
import { BasicItemProps, SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { useCallback } from "core/api/equality";
import ShortInstitutionRenderer from "institutions/renderers/ShortInstitutionRenderer";


type UserProps = BasicItemProps<User>
	& SelectableItemProps<User>;
	
const UserItem : React.FC<UserProps> = ({
		value : user, style, rootRef,
		selectable, onSelect
	}) => {
	
		const primaryAffiliation = user.getPrimaryOrFirstCurrentAffiliation();

		const renderer = useMemo(() => {
			if(primaryAffiliation === undefined) {
				return undefined;
			}
			return new ShortInstitutionRenderer(primaryAffiliation.institution);
		}, [primaryAffiliation])
	
		const smallIfExists = useCallback((text : string | undefined) => 
			text ? <><br /><small className="text-muted">{text}</small></> : null,
			[]);
	
		const handleSelect = useCallback(() => {
				onSelect && onSelect(user);
			}, [onSelect, user]);

		return <BaseGroupItem role="button" onClick={selectable ? handleSelect : undefined} style={style} rootRef={rootRef}>
				<Row>
				<Col md={1} className="d-none d-md-block"><img src={user.getAvatar()} className="w-100 rounded-circle" alt="Avatar" /></Col>
				<Col md={3}>{user.first_name} {user.last_name}
						{smallIfExists(primaryAffiliation?.position)}</Col>
				<Col md={4}>{primaryAffiliation ? renderer?.getNameWithTip() : 'No institution'}
						{smallIfExists(primaryAffiliation?.department)}
				</Col>
				<Col md={4}><Obfuscate className="small" email={user.email} /></Col>
			</Row>
			</BaseGroupItem>;
};

export default UserItem;