
import React, { useState } from 'react';
import { useCallback } from 'core/api/equality';
import { bookAPI } from 'books/api/entities';
import BookItem from 'books/items/BookItem';
import BookCard from 'books/cards/BookCard';
import { fetchBookListKey } from 'books/queryKeys';
import { BookFilter } from 'books/forms/searches/BookSearch';
import { Book } from 'books/api/models';
import { useNavigate } from 'react-router-dom';
import PaginatedComponent, { PaginationFooter, PaginationHeader } from 'core/components/PaginatedComponent';
import { Button, Table } from 'reactstrap';
import { Grid, Grid3x3Gap, ListColumns } from 'react-bootstrap-icons';


export type BookListProps  = {
    filter : BookFilter;
};

export const enum ViewStyle {
  TABLE = 'TABLE',
  CARD = 'CARD',
  BIG_CARD = 'BIG_CARD'
};

const BookList : React.FC<BookListProps> = ({filter}) => {

    const navigate = useNavigate();
    const [viewStyle, setViewStyle] = useState(ViewStyle.BIG_CARD);
    const query = useCallback((params : object) => {
        return bookAPI.getAll({
            ...params,
            search : filter.search,
            msc_code : filter.msc_subjects.map(subject => subject.code).join(','),
            category_id : filter.categories.map(category => category.id).join(',')
        })
    }, [filter.categories, filter.msc_subjects, filter.search]);

    const handleSelect = useCallback((book:Book) => {
        navigate(book.getPage());
    }, [navigate]);

    const content = useCallback((values : Book[]) => {
      switch(viewStyle) {
        case ViewStyle.TABLE :
          return <Table striped hover className="border bg-white">
            <thead>
                    <tr>
                      <th>Books</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.length <= 0? (
                    <tr>
                      <td align="center">
                        <b>No book found.</b>
                      </td>
                    </tr>
                    ) : (
                      values.map(value => (
                      <tr key={value.id}><td><BookItem value={value}  selectable={true} onSelect={handleSelect}/></td></tr>
                      ))
                    )}
                  </tbody>
              </Table>;
        case ViewStyle.CARD :
              return <div className="row row-cols-2 row-cols-lg-3">
              {values.map(value => (
                <BookCard key={value.id} value={value} selectable={true} onSelect={handleSelect} />
               ))}
               </div>;
        case ViewStyle.BIG_CARD :
              return <div className="row row-cols-2 row-cols-lg-2">
              {values.map(value => (
                <BookCard key={value.id} value={value} selectable={true} onSelect={handleSelect} />
              ))}
              </div>;
      }
    }, [handleSelect, viewStyle])

  return <PaginatedComponent queryKey={[fetchBookListKey, filter]} query={query} defaultLimit={20}>
  {(values, pagination) => <>
    <PaginationHeader pagination={pagination}>
      <Button outline className="border ms-2" onClick={() => setViewStyle(ViewStyle.CARD)} active={viewStyle === ViewStyle.CARD}>
              <Grid3x3Gap /></Button>
          <Button outline className="border ms-2" onClick={() => setViewStyle(ViewStyle.BIG_CARD)} active={viewStyle === ViewStyle.BIG_CARD}>
              <Grid /></Button>
          <Button outline className="border ms-2"  onClick={() => setViewStyle(ViewStyle.TABLE)} active={viewStyle === ViewStyle.TABLE}>
              <ListColumns /></Button>
    </PaginationHeader>{content(values)}<PaginationFooter pagination={pagination} />
  </>}
  </PaginatedComponent>;


   
};

export default BookList;
  