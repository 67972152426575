import React from 'react';
import { PanelProps } from 'core/pages/PanelPage';
import BasicForm from "core/components/forms/BasicForm";
import TalkListForm from 'talks/forms/TalkListForm';


const TalksPanel : React.FC<PanelProps> = ({user}) => {

    return <div>

        <BasicForm title="Talks">
            <TalkListForm value={user} />
        </BasicForm>          

    </div>;
}

export default TalksPanel;