import { Book } from "books/api/models";
import BookCard from "books/cards/BookCard";
import BaseBookRenderer from "books/renderers/BaseBookRenderer";
import { Tooltip, TooltipContent, TooltipTrigger } from "core/components/Tooltip";
import React from 'react';
import { NavLink } from "react-router-dom";

class BookRenderer extends BaseBookRenderer<Book> {

    getTitleWithTooltips() : React.ReactNode {
        return <Tooltip placement="bottom">
                <TooltipTrigger asChild={true}><NavLink to={this.book.getPage()}>{this.book.title}</NavLink></TooltipTrigger>
                <TooltipContent className="p-0 m-0"><BookCard value={this.book} /></TooltipContent>
            </Tooltip>;
    }
};

export default BookRenderer;