import React, { useEffect, useMemo, useState } from "react";
import {Button, Col, Row, Table} from 'reactstrap';
import { useModalState } from "core/components/Modal";
import { useAction } from "core/hooks/action";
import User from "directory/api/models/User";
import Publication from "publications/api/models/Publication";
import { useQuery } from "react-query";
import { fetchPublicationsKey } from "publications/queryKeys";
import { publicationAPI } from "publications/api/entities";
import PublicationFormModal from "publications/modals/PublicationFormModal";
import { useCallback } from "core/api/equality";
import ImportFormModal from "publications/modals/ImportFormModal";
import { ZeroFormProps } from "core/forms/types";
import DetailedPublicationItem from "publications/items/DetailedPublicationItem";

export type PublicationListFormProps = ZeroFormProps<User>;

const PublicationListForm : React.FC<PublicationListFormProps> = ({
    value : user, 
    displayButtons=true,
    onButtonsChange
}) => {

    const { data : publications, isLoading, isError } = useQuery([fetchPublicationsKey, user.id], () => publicationAPI.getAll({
        user_id : user.id,
        limit : 50
    }));
    const [editedPublication, setEditedPublication] = useState(undefined as Publication | undefined);
    const publicationModal = useModalState();
    const bibtexModal = useModalState();
    
    const createHandler = useAction(useCallback(async () => {
        setEditedPublication(undefined);
        publicationModal.open();
        return null;
    }, [publicationModal]));

    const bibtexHandler = useAction(useCallback(async () => {
        bibtexModal.open();
        return null;
    }, [bibtexModal]));

    const editPublication = useCallback((publication : Publication) => {
        setEditedPublication(publication);
        publicationModal.open();
    }, [publicationModal]);


    const buttons = useMemo(() => {
        return [
            <Button key="import" color="secondary" className="me-2" {...bibtexHandler.buttonProps}>Import Bibtex</Button>,
            <Button key="new" color="primary" {...createHandler.buttonProps}>New publication</Button>
        ];
    }, [createHandler, bibtexHandler]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [onButtonsChange, buttons]);

    if(isLoading || isError || publications === undefined)
        return null;
  
    return <>

        <PublicationFormModal modal={publicationModal}
            user={user} value={editedPublication} />
        <ImportFormModal modal={bibtexModal} user={user.toShortUser()} />

        {displayButtons ? <Row className="row-cols-lg-auto  mb-3">
            <Col className="ms-auto">{buttons}</Col>
        </Row> : null}

        <Table striped hover={publications.count > 0} className="border bg-white">
            <thead>
            <tr>
                <th>Publications</th>
            </tr>
            </thead>
            <tbody>
            {publications.count === 0 ? (<tr><td className="text-center small">You have no publication yet</td></tr>)
            : (publications.results.map(publication => (
                <tr key={publication.id} role="button" onClick={() => editPublication(publication)}>
                    <td><DetailedPublicationItem value={publication} /></td>
                </tr>
            )))}
            </tbody>
        </Table>
    </>;

};

export default PublicationListForm;