import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { videoAPI } from 'videos/api/entities';
import { useQuery } from 'react-query';
import { fetchVideosKey } from 'videos/queryKeys';
import { DetailLayout, DetailLeftColumn, DetailMainColumn } from 'core/pages/DetailPage';
import Youtube from 'react-youtube';
import { useCallback, useMemo } from 'core/api/equality';
import { useElementSize } from 'usehooks-ts';
import Latex from 'react-latex';
import { List } from 'reactstrap';
import { ShortVideo } from 'videos/api/models/ShortVideo';
import VeryShortVideoItem from 'videos/items/VeryShortVideoItem';


const VideoPage : React.FC<{}> = () => {


    const {videoId} = useParams();

    const [divRef, { width  } ] = useElementSize<HTMLDivElement>();

    const navigate = useNavigate();

    if(videoId === undefined) {
        throw Error('Invalid video ID');
    }

    const { data : video, error, isError, isLoading } = 
        useQuery([fetchVideosKey, videoId], () => videoAPI.get(videoId) );

    const { data : relatedVideos } = 
        useQuery([fetchVideosKey], () => videoAPI.getShortAll({
            limit : 5,
            order : 'random'
        }));

    const handleSelect = useCallback((video:ShortVideo) => {
        navigate(video.getPage());
    }, [navigate]);

    const relatedList = useMemo(() => {
        if(relatedVideos === undefined) {
            return null;
        }
        return <List hover>
            {relatedVideos.results.map(video => (
                <VeryShortVideoItem key={video.id} value={video} 
                    className="mb-3 me-2 small" selectable={true} onSelect={handleSelect} />))}
        </List>
    }, [relatedVideos, handleSelect]);

    const youtubeOpts = useMemo(() => ({
            height: width*390/640,
            width: width,
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
              modestbranding: 1,
              showinfo: 0
            },
          }), [width]);

    if (isError)
        throw error;

    if (isLoading || !video ) {
        return null;
    }

    return <DetailLayout>
        <DetailLeftColumn>
            <h6 className="text-center m-3 mb-4">Random videos</h6>
            {relatedList}
        </DetailLeftColumn>
        <DetailMainColumn>
            <div className="ms-5 me-5" ref={divRef}></div>
            <h5 className="text-center mb-3"><Latex>{video.title}</Latex></h5>

            {<Youtube videoId={video.id} opts={youtubeOpts} className="text-center ms-5 me-5" />}
        </DetailMainColumn>
    </DetailLayout>;
}
    
    
export default VideoPage;
