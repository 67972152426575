import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { BasicItemProps, SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { Book } from "books/api/models";
import { useMemo } from 'core/api/equality';
import BookRenderer from 'books/renderers/BookRenderer';


type BookItemProps = BasicItemProps<Book> &
    SelectableItemProps<Book>;
  
const BookItem : React.FC<BookItemProps> = ({
        value, style, rootRef,
        selectable, onSelect
    }) => {

    const renderer = useMemo(() => new BookRenderer(value), [value]);
  
    const handleClick = useCallback(() => {
        onSelect && onSelect(value);
    }, [onSelect, value]);

    return <BaseGroupItem role="button" onClick={selectable ? handleClick : undefined} style={style} rootRef={rootRef}>
        <Row className="small">
        <Col className="d-none d-md-block text-start" style={{maxWidth: '100px'}}>{renderer.getThumbnail()}</Col>
        <Col>
            <table>
                <tr>
                    <td className="text-secondary align-top pe-2">Title</td>
                    <td>{renderer.getFullTitle()}</td>    
                </tr>
                <tr>
                    <td className="text-secondary align-top pe-2">Authors</td>
                    <td>{renderer.getAuthors()}</td>
                </tr>
                {value.publisher === '' ? null : (
                <tr>
                    <td className="text-secondary align-top pe-2">Publisher</td>
                    <td>{value.publisher}</td>
                </tr>
                )}
            </table>
        </Col>
        <Col md="2">
            <table>
                {value.year === null ? null : (
                <tr>
                    <td className="text-secondary align-top pe-2">Year</td>
                    <td>{value.year}</td>
                </tr>
                )}
                {value.page_count === 0 ? null : (
                <tr>
                    <td className="text-secondary align-top pe-2">Pages</td>
                    <td>{value.page_count}</td>
                </tr>
                )}
            </table>
        </Col>
        <Col md="3" className="small">
            <table>
                <tr>
                    <td className="text-secondary align-top pe-2">ISBN</td>
                    <td>{value.isbn}</td>
                </tr>
                {renderer.getGoogleBooksId() === undefined ? null : (
                <tr>
                    <td className="text-secondary align-top pe-2">Google ID</td>
                    <td>{renderer.getGoogleBooksId()}</td>
                </tr>
                )}
                {renderer.getZbmathId() === undefined ? null : (
                <tr>
                    <td className="text-secondary align-top pe-2">ZbMath ID</td>
                    <td>{renderer.getZbmathId()}</td>
                </tr>
                )}
            </table>
        </Col>
      </Row>
      </BaseGroupItem>;
};

export default BookItem;