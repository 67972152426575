import { useMemo } from "core/api/equality";
import SideMainLayout from "core/components/layouts/SideMainLayout";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { playlistAPI } from "videos/api/entities";
import PlaylistVideoSearch, { PlaylistVideoFilter } from "videos/forms/searches/PlaylistVideoSearch";
import { VideoFilter } from "videos/forms/searches/VideoSearch";
import VideoList from "videos/lists/VideoList";
import { fetchPlaylistsKey } from "videos/queryKeys";

const PlaylistPage : React.FC<{}> = () => {

    const {playlistId} = useParams();

    const [filter, setFilter] = useState({
        search : '',
        playlists : [],
        msc_subjects : [],
        speakers : []
    } as PlaylistVideoFilter);

    const { data : playlist } = useQuery([fetchPlaylistsKey, playlistId], () => playlistAPI.get( playlistId || '' ));

    const playlistHeader = useMemo(() => {
        if(playlist === undefined)
            return null;
        
        return <div className="text-center">
            <h3>{playlist.title}</h3>
            <p>{playlist.description}</p>
            </div>;
    }, [playlist])

    const videoFilter = useMemo(() => {
        return {
            ...filter,
            playlists : playlist === undefined ? [] : [playlist]
        } as VideoFilter;
    }, [filter, playlist])

    if(playlistId === undefined) {
        throw Error('Invalid playlist ID');
    }

    return <SideMainLayout sideBarTitle="Filter" sideBarBody={
        <nav>
            <PlaylistVideoSearch onChange={setFilter} />
        </nav>}>

            {playlistHeader}
            
            <VideoList filter={videoFilter} />
        </SideMainLayout>;
};

export default PlaylistPage;