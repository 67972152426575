import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useCallback, useState } from 'react';
import Modal, { ModalState } from "core/components/Modal";
import AboutForm from "directory/forms/AboutForm";
import User from "directory/api/models/User";
import { UFormModal } from "core/modals/types";
import { ButtonType } from "core/forms/types";

export type AboutFormModalProps = UFormModal<string> & {
    user : User;
    onSubmit?(about:string):void;
    modal : ModalState
};

const AboutFormModal : React.FC<AboutFormModalProps>= ({
    user, modal, onSubmit}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);

    const handleSubmit = useCallback((value:string) => {
        modal.close();
        return onSubmit && onSubmit(value);
    }, [modal, onSubmit]);

    return <Modal state={modal} size="lg">
        <ModalHeader className="bg-light" toggle={modal.close}>Edit about tab</ModalHeader>

        <ModalBody>
            <AboutForm value={user} onButtonsChange={setButtons} displayButtons={false} onSubmit={handleSubmit}  />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default AboutFormModal;