import { FormGroupProps } from 'core/forms/groups/types';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';


export type InputGroupProps = FormGroupProps & {
    type? : InputType;
    as? : string;
    children? : React.ReactNode;
    validate? : (values : any, props : any) => string;
}

/*
Shorthand for FormGroup, Label, Input, FormFeedback
*/

const InputGroup : React.FC<InputGroupProps> = ({
    label, placeholder = label, 
    validate,
    type = 'text', name, 
    as, children, className='', bsSize
}) => {

    const { touched, errors } = useFormikContext<object>();

    return <FormGroup className={className}>
        <Label for={name}>{label}</Label>
        <Input 
            bsSize={bsSize}
            id={name} tag={Field} 
            name={name} as={as} type={type} placeholder={placeholder}
            validate={validate}
            invalid={touched[name] && name in errors}>
        {children}
        </Input>
        <FormFeedback component="small" className="text-danger">{touched[name] && errors[name]}</FormFeedback>
    </FormGroup>
}

export default InputGroup;