import { Language } from "languages/api/models";

export class Video {

    id : string;
    title : string;
    description : string;
    publication_time : Date;
    channel_id : string;
    thumbnail : string;
    player : string;
    view_count : number;
    duration : string;
    language : Language;
    
    constructor(json : any) {
        json.publication_time = new Date(json.publication_time);
        json.language = new Language(json.language);
        Object.assign(this, json);
    }

    getPage() {
        return '/videos/' + this.id;
    }

    is(video : Video) : boolean {
        return this.id === video.id
            && this.title === video.title
            && this.description === video.description
            && this.thumbnail === video.thumbnail
            && this.channel_id === video.channel_id
            && this.publication_time.getTime() === video.publication_time.getTime()
            && this.view_count === video.view_count
            && this.player === video.player
            && this.duration === video.duration;
    }
};
