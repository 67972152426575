import { useCallback } from 'core/api/equality';
import { MarkdownEditor } from 'core/components/Markdown';
import { FormGroupProps } from 'core/forms/groups/types';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';


export type MarkdownGroupProps = FormGroupProps;

const MarkdownGroup : React.FC<MarkdownGroupProps> = ({
    label, placeholder = label, 
    name, className='', bsSize
}) => {

    const { values, touched, errors, setFieldValue } = useFormikContext<object>();

    const setValue = useCallback(( value : string ) => {
        setFieldValue(name, value);
    }, [setFieldValue, name])

    return <FormGroup className={className}>
        <Label for={name}>{label}</Label>
        <div className={(touched[name] && errors[name]) ? 'is-invalid' : ''}>
        <MarkdownEditor value={values[name]} onChange={setValue} /> 
        </div>
        <FormFeedback component="small" className="text-danger">{touched[name] && errors[name]}</FormFeedback>
    </FormGroup>
}

export default MarkdownGroup;