import { userAPI }from 'directory/api/entities';
import { useParams } from 'react-router-dom';
import { useQuery } from "react-query";
import { Nav, NavItem, TabContent, NavLink, TabPane, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchUserKey } from 'directory/queryKeys';
import { AuthState } from 'store';
import ProfilePictureFormModal from 'directory/modals/ProfilePictureFormModal';
import BasicInformationFormModal from 'directory/modals/BasicInformationFormModal';
import { useModalState } from 'core/components/Modal';
import AffiliationListFormModal from 'directory/modals/AffiliationListFormModal';
import ResearchInterestListFormModal from 'directory/modals/ResearchInterestListFormModal';
import AboutFormModal from 'directory/modals/AboutFormModal';
import User from "directory/api/models/User";
import Obfuscate from 'react-obfuscate';
import PublicationListFormModal from 'publications/modals/PublicationListFormModal';
import AboutTab from 'directory/tabs/AboutTab';
import PublicationsTab from 'publications/tabs/PublicationsTab';
import TalksTab from 'talks/tabs/TalksTab';
import TalkListFormModal from 'talks/modals/TalkListFormModal';
import ShortInstitutionRenderer from 'institutions/renderers/ShortInstitutionRenderer';
import { useMemo } from 'core/api/equality';
import "core/pages/DetailPage.scss";
import { DetailLayout, DetailLeftColumn, DetailMainColumn } from 'core/pages/DetailPage';


type UserProps = {
    user : User;
}


/**
    LEFT COLUMN
    Components for the left column of the profile page
 */

/**
    Avatar component
    Displays the profile picture of the user
 */
const Avatar : React.FC<UserProps>  = ({user}) => {

    const auth = useSelector((state : AuthState) => state.auth);
    const modal = useModalState();

    return <>
        <ProfilePictureFormModal value={user} modal={modal} />
        <div className="app-detail-page-img">
        <img src={user.getAvatar()} width={286} alt="Avatar" />
        {auth.user && user.id === auth.user.id ? (
        <div className="file btn btn-lg btn-primary" onClick={modal.open}>
            Change Picture
        </div>
        ) : <></>}
    </div>
    </>;
};

/**
    Lists component
    Displays a list of links and of research interets
    on the left column of the profile page
 */
type ListsProps = {
    user: User;
    className? : string;
};

const Lists : React.FC<ListsProps> = (props) => {

    const {user, className} = props;

    let links = [];
    if(user.webpage) {
        links.push({
            url : user.webpage,
            label : 'Personal website'
        })
    };

    return <div className={'app-detail-page-links ' + className}>
        {links.length > 0 ? (
            <>
                <h6>Links</h6> 
                <ul className="p-0">
                {links.map(link => (
                    <li key={link.url} className="p-0"><span><a href={link.url}>{link.label}</a></span></li>
                ))}
                </ul>
            </>
        ) : null}
        
    </div>
};



/**
    MAIN COLUMN
    Components for the main column of the profile page
 */


const EditProfileButton : React.FC<UserProps>  = ({user}) => {
    const auth = useSelector( (state:AuthState) => state.auth);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const basicInformationModal = useModalState();
    const affiliationListModal = useModalState();
    const researchInterestListModal = useModalState();
    const aboutModal = useModalState();
    const publicationListModal = useModalState();
    const talkListModal = useModalState();
    
    const toggle = () => setDropdownOpen(prevState => !prevState);

    // If this is not the same user
    if(!auth.user || user.id !== auth.user.id) {
        return null;
    }

    return <>
    <BasicInformationFormModal value={user} modal={basicInformationModal} />
    <AffiliationListFormModal user={user} modal={affiliationListModal} />
    <ResearchInterestListFormModal user={user} modal={researchInterestListModal} />
    <AboutFormModal value="" user={user} modal={aboutModal} />
    <PublicationListFormModal user={user} modal={publicationListModal} />
    <TalkListFormModal user={user} modal={talkListModal} />

    <Dropdown isOpen={isDropdownOpen} toggle={toggle} direction="down">
        <DropdownToggle caret className="btn btn-light border app-detail-page-edit">Edit Profile</DropdownToggle>
        <DropdownMenu className="shadow-sm">
            <DropdownItem onClick={basicInformationModal.open}>Basic Information</DropdownItem>
            <DropdownItem onClick={affiliationListModal.open}>Affiliations</DropdownItem>
            <DropdownItem onClick={researchInterestListModal.open}>Research Interests</DropdownItem>
            <DropdownItem onClick={aboutModal.open}>About</DropdownItem>
            <DropdownItem onClick={publicationListModal.open}>Publications</DropdownItem>
            <DropdownItem onClick={talkListModal.open}>Talks</DropdownItem>
        </DropdownMenu>
    </Dropdown>
    </>;
};


const MainColumn : React.FC<UserProps>  = ({user}) => {

    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        <AboutTab user={user} name="About" />,
        <PublicationsTab user={user} name="Publications" />,
        <TalksTab user={user} name="Talks" />
    ];

    const primaryAffiliation = user.getPrimaryOrFirstCurrentAffiliation();

    const renderer = useMemo(() => {
        if(primaryAffiliation === undefined) {
            return undefined;
        }
        return new ShortInstitutionRenderer(primaryAffiliation.institution);
    }, [primaryAffiliation])

    return <DetailMainColumn>
        <div className="float-md-end mb-3">
            <EditProfileButton user={user} />
        </div>
        
        <h3>{user.first_name} {user.last_name}</h3>
        <h6>{primaryAffiliation ? <a href={primaryAffiliation.institution.website}>{renderer?.getNameWithTip()}</a> : 'No institution'}</h6>
        <p className="small muted pt-2">Email : <span><Obfuscate email={user.email} /></span></p>

        <Nav tabs>
            {tabs.map((tab, index) => (
                <NavItem key={index}>
                <NavLink href="#" onClick={() => setActiveTab(index)} active={activeTab===index}>
                    {tab.props.name}
                </NavLink>
            </NavItem>
            ))}
        </Nav>
        <TabContent className="app-detail-page-tab pb-3" id="profileTabs" activeTab={activeTab}>
            {tabs.map((tab, index) => (
                <TabPane key={index} tabId={index}>
                    {tab}
                </TabPane>
            ))}
        </TabContent>

        <Lists user={user} className="d-sm-block d-md-none" />
    </DetailMainColumn>;
};

/**
    PROFILE PAGE
 */
const UserPage : React.FC<{}> = () => {


    const {userId} = useParams();
    const validUserId = parseInt(userId || '');

    const { data : user, error, isError, isLoading } = 
        useQuery([fetchUserKey, validUserId], () => userAPI.get(validUserId) );

    if (isError)
        throw error;

    if (isLoading || !user ) {
        return null;
    }

    return <DetailLayout>
            <DetailLeftColumn>
                <Avatar user={user} />
                <Lists user={user} className="d-none d-md-block" />
            </DetailLeftColumn>
            <MainColumn user={user} />
        </DetailLayout>;
}


export default UserPage;