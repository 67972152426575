import React, { useCallback } from 'react';
import { BasicItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { Playlist } from 'videos/api/models/Playlist';
import { NavLink, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { videoAPI } from 'videos/api/entities';
import { fetchShortVideosKey } from 'videos/queryKeys';
import { useQuery } from 'react-query';
import { useMemo } from 'core/api/equality';
import ShortVideoCard from 'videos/cards/ShortVideoCard';
import { ShortVideo } from 'videos/api/models/ShortVideo';

export type PlaylistItemProps = BasicItemProps<Playlist>;

const PlaylistItem : React.FC<PlaylistItemProps> = ({
    value : playlist, 
    style, rootRef}) => {

    const NUM_VIDEOS = 4;
    const navigate = useNavigate();

    const { data : videos } = useQuery([fetchShortVideosKey, playlist.id, NUM_VIDEOS], () => videoAPI.getShortAll({
        playlist_id : playlist.id,
        limit : NUM_VIDEOS
    }));

    const handleSelectVideo = useCallback((video:ShortVideo) => {
        navigate(video.getPage());
    }, [navigate]);

    const content = useMemo(() => {
        if(videos === undefined) {
            return <>Loading...</>;
        }
        else {
            return videos.results.map(video => <Col key={video.id} className="mb-3 btn">
                <ShortVideoCard value={video} selectable={true} onSelect={handleSelectVideo} /></Col>)
        }
    }, [videos, handleSelectVideo])


    return <BaseGroupItem style={style} rootRef={rootRef}>
        <Row>
            <Col className='me-auto'>
                <h4><NavLink to={playlist.getPage()}>{playlist.title}</NavLink></h4>
                <p>{playlist.description}</p>
            </Col>
            <Col md={3} className="text-end">
                ({playlist.num_videos} videos)
            </Col>

        </Row>
        <Row>
            {content}
        </Row>
        
        
    </BaseGroupItem>;
};

export default PlaylistItem;