import { Routes, Route } from "react-router-dom";
import React from 'react';
import VideoListPage from "videos/pages/VideoListPage";
import VideoPage from "videos/pages/VideoPage";
import PlaylistPage from "videos/pages/PlaylistPage";

const VideosRoute : React.FC<{}> = () => {

    return <Routes>
        <Route path="/playlists/:playlistId" element={<PlaylistPage />} />
        <Route path="/:videoId" element={<VideoPage />} />
        <Route path="/" element={<VideoListPage />} />
    </Routes>;

};

export default VideosRoute;