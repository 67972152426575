import { useModalState } from 'core/components/Modal';
import ConfirmationModal from 'core/modals/confirmations/ConfirmationModal';
import React from 'react';
import { Button, ButtonProps } from 'reactstrap';

export type ConfirmationButtonProps = ButtonProps & {

    children? : React.ReactNode;

    confirmLabel? : React.ReactNode;

    cancelLabel? : React.ReactNode;

    confirmQuestion? : React.ReactNode;

    color? : "primary" | "danger" | "secondary";

    size? : 'sm' | 'md' | 'lg';

    onClick : () => void;
};

const ConfirmationButton : React.FC<ConfirmationButtonProps> = ({
    onClick,
    children,
    confirmQuestion,
    confirmLabel = children,
    cancelLabel,
    color = 'danger',
    size,
    ...buttonProps
}) => {

    const modalState = useModalState();

    return <Button onClick={modalState.open} color={color} {...buttonProps}>
        <ConfirmationModal modal={modalState} size={size}
            confirmLabel={confirmLabel} 
            cancelLabel={cancelLabel}
            onConfirm={onClick}>
        {confirmQuestion}
        </ConfirmationModal>
        {children}
    </Button>

};

export default ConfirmationButton;