import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useCallback, useState } from 'react';
import ProfilePictureForm from "directory/forms/ProfilePictureForm";
import Modal from "core/components/Modal";
import User from "directory/api/models/User";
import { UFormModal } from "core/modals/types";
import { ButtonType } from "core/forms/types";

export type ProfilePictureFormModalProps = UFormModal<User>;

const ProfilePictureFormModal : React.FC<ProfilePictureFormModalProps>= ({
    value,
    modal,
    onSubmit, onUpdate}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);

    const handleSubmit = useCallback((value : User) => {
        modal.close();
        onSubmit && onSubmit(value);
    }, [modal, onSubmit]);

    return <Modal state={modal} size="lg" style={{maxWidth: '600px'}}>
        <ModalHeader className="bg-light" toggle={() => modal.close()}>Edit profile picture</ModalHeader>

        <ModalBody>
            <ProfilePictureForm value={value} onButtonsChange={setButtons} displayButtons={false} onSubmit={handleSubmit} onUpdate={onUpdate}  />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="secondary" onClick={() => modal.close()}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default ProfilePictureFormModal;