import { CompletionProps } from 'core/completions/types';
import React from 'react';
import { useQuery } from 'react-query';
import { ListGroup } from 'reactstrap';
import { SelectableItemProps } from "core/items/types";
import { BaseIdType, PaginatedData } from 'core/api/BaseAPI';

export type DefaultCompletionProps<IdType extends BaseIdType, ValueType extends { id : IdType }> = CompletionProps<ValueType> & {
    Item : React.FC<SelectableItemProps<ValueType>>;
    queryKey : string;
    query : (params : object) => Promise<PaginatedData<ValueType>>;
    placeholder? : string;
};

const DefaultCompletion = <IdType extends BaseIdType, ValueType extends { id : IdType }>({
    Item,
    queryKey, query,
    text, onSelect, 
    exclude = [],
    placeholder = 'Type a text to autocomplete...',
    emptyList, selectedValue, limit = 5
} : DefaultCompletionProps<IdType, ValueType>) => {

    const queryHandler = () => {
        return query({
            search : text,
            limit: limit + exclude.length
        });
    };
    

    const { data, isError, isLoading } = useQuery(
        [queryKey, text], queryHandler, { keepPreviousData : true } );

    if(text === '') {

        if(emptyList === undefined) {
            return <p className="p-2 m-0 small text-muted">{placeholder}</p>;
        }

        return <ListGroup>
            {emptyList.map(value => <Item
                key={value.id} value={value} 
                active={(selectedValue !== undefined) && selectedValue.id === value.id} 
                selectable={true}
                onSelect={onSelect} />)}
        </ListGroup>
    }

    if(text === '' || isError || isLoading) {
        return null;
    }

    if(!data) {
        return null;
    }

    const values = data.results.filter(value => exclude.filter(ex_value => ex_value.id === value.id).length=== 0).slice(0, limit);

    return <ListGroup>
        {values.map(value => (
            <Item key={value.id} value={value} 
                active={(selectedValue !== undefined) && selectedValue.id === value.id}
                selectable={true}
                onSelect={onSelect} />
        ))}
    </ListGroup>;

};

export default DefaultCompletion;