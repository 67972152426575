import { msc2020SubjectAPI } from 'msc/api/entities';
import { MscSubject } from 'msc/api/models';
import { CompletionProps } from 'core/completions/types';
import MscSubjectItem from 'msc/items/MscSubjectItem';
import React from 'react';
import { fetchMscSubjectListKey } from "msc/queryKeys";
import DefaultCompletion from 'core/completions/DefaultCompletion';

export type MscSubjectCompletionProps = CompletionProps<MscSubject>;

const MscSubjectCompletion : React.FC<MscSubjectCompletionProps> = (props) => {
    return <DefaultCompletion 
        Item={MscSubjectItem} 
        queryKey={fetchMscSubjectListKey}
        query={msc2020SubjectAPI.getAll.bind(msc2020SubjectAPI)}
        {...props} />
};


export default MscSubjectCompletion;

