
import { QueryClient} from 'react-query';

const queryClientConfig = {
    defaultOptions: {
      queries: {
        retry: 2,
        staleTime: 1000 * 30,// 30 seconds
        cacheTime: 1000 * 30, //30 seconds
        refetchOnMount: "always" as "always",
        refetchOnWindowFocus: "always" as "always",
        refetchOnReconnect: "always" as "always",
        refetchInterval: 1000 * 30, //30 seconds
        refetchIntervalInBackground: false,
        suspense: false,

      },
      mutations: {
        retry: 2,
      },
    }
};

export const queryClient = new QueryClient(queryClientConfig);