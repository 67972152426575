import AppNavBar from "core/components/AppNavBar";
import React from 'react';

export interface MainLayoutProps {
    children : React.ReactNode;
};

const MainLayout : React.FC<MainLayoutProps> = ({children}) => {

    return <>
        <AppNavBar />
        <div className="container-xxl bd-gutter mt-3 my-md-4">
            <main className="bd-main order-1">
                {children}
            </main>
        </div>
    </>;
}

export default MainLayout;