import Publication from "publications/api/models/Publication";
import PublicationAuthorAPI from 'publications/api/PublicationAuthorAPI';
import PublicationImportAPI from 'publications/api/PublicationImportAPI';
import PublicationAPI from 'publications/api/PublicationAPI';


export const publicationAPI = new PublicationAPI({
    baseURL: 'publications/',
    factory: Publication
});

export const publicationAuthorAPI = new PublicationAuthorAPI({
    baseURL: 'publications/authors/'
});

export const publicationImportAPI = new PublicationImportAPI({
    baseURL: 'publications/imports/'
});
