import {Form, FormGroup, Label, Row, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import { talkAPI } from "talks/api/entities";
import React, { useContext, useState } from "react";
import * as Yup from 'yup';
import DatePickerField from 'core/components/forms/DatePickerField';
import { NewTalkType, UpdateTalkType } from "talks/api/types";
import AutoDismissAlert from 'core/components/AutoDismissAlert';
import { errorToText } from 'core/helpers/error';
import User from "directory/api/models/User";
import Talk from "talks/api/models/Talk";
import { format as format_date } from 'date-fns';
import { CUForm } from 'core/forms/types';
import CitySelectGroup from 'geonames/forms/groups/CitySelectGroup';
import InputGroup from 'core/forms/groups/InputGroup';
import CheckGroup from 'core/forms/groups/CheckGroup';
import { City } from 'geonames/api/models';
import { useAction } from 'core/hooks/action';
import { ToasterContext } from 'core/hooks/toaster';
import { useUpdateTalks } from 'talks/hooks/talk';
import { useCallback, useEffect, useMemo } from 'core/api/equality';
import { MscSubject } from 'msc/api/models';
import MscSubjectManyOneGroup from 'msc/forms/groups/MscSubjectManyOneGroup';
import ShortVideoSelectGroup from 'videos/forms/groups/ShortVideoSelectGroup';
import { ShortVideo } from 'videos/api/models/ShortVideo';
import PdfFileGroup from 'core/forms/groups/PdfFileGroup';
import { ObjectWithId } from 'core/api/BaseAPI';
import MarkdownGroup from 'core/forms/groups/MarkdownGroup';



export type TalkFormProps = CUForm<Talk> & {
    user : User;
};

type FormTalkType = {
    id? : number,
    user_id : number,
    date : Date | null,
    title : string,
    abstract : string,
    event_name: string,
    event_url : string,
    slides : File | null | string,
    city : City | null,
    video : ShortVideo | null,
    online : boolean,
    msc_subjects : MscSubject[]
};

const DateConverter = {

    dateToTextDate : (date : Date | null) : string | null => {
        if(date === null || isNaN(date as unknown as number)) {
            return null;
        }
        return format_date(date, 'yyyy-MM-dd');
    }

};

const TalkConverter = {

    talkToFormType : (talk:Talk) : FormTalkType => {
        return {
            ...talk,
            slides : talk.slides,
            user_id : talk.getUserId()
        }
    },
    
    userIdToFormType : (userId : number) : FormTalkType => {
        return {
            id : undefined,
            user_id : userId,
            slides : null,
            date : null,
            title : '',
            abstract : '',
            event_name: '',
            event_url : '',
            online : false,
            city : null,
            video : null,
            msc_subjects : []
        }
    },
    
    formTypeToNewTalk : (values: FormTalkType) : NewTalkType => {
        return {
            ...values,
            slides : typeof values.slides === 'string' ? undefined : values.slides,
            city_id : values.city === null ? null : values.city.id,
            video_id : values.video === null ? null : values.video.id,
            date : DateConverter.dateToTextDate(values.date),
            msc_subject_codes : values.msc_subjects.map(subject => subject.code)
        };
    },
    
    formTypeToUpdateTalk : (values : FormTalkType & ObjectWithId<number>) : UpdateTalkType => {
        return {
            ...values,
            slides : typeof values.slides === 'string' ? undefined : values.slides,
            city_id : values.city === null ? null : values.city.id,
            video_id : values.video === null ? null : values.video.id,
            date : DateConverter.dateToTextDate(values.date),
            msc_subject_codes : values.msc_subjects.map(subject => subject.code)
        };
    }
};

const TalkForm : React.FC<TalkFormProps> = ({ 
    value : talk, user,
    onButtonsChange,
    onCreate, 
    onUpdate,
    onSubmit,
    displayButtons=true}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const addToast = useContext(ToasterContext);
    const updateTalks = useUpdateTalks();

    const [activeTab, setActiveTab] = useState(0 as 0 | 1 | 2 | 3);

    const editedTalk = useMemo(() => (
        talk === undefined ? TalkConverter.userIdToFormType(user.id) : TalkConverter.talkToFormType(talk)
    ), [talk, user.id]);

    const handleCreate = useCallback(async (talk: NewTalkType) => {
        const newTalk =  await talkAPI.create(talk);
        onCreate && onCreate(newTalk);
        onSubmit && onSubmit(newTalk);
        addToast({
            title : 'Talk created',
            children : 'Talk successfully created',
            type : 'success'
        });
        updateTalks()
        return newTalk;
    }, [onCreate, onSubmit, addToast, updateTalks]);

    const handleUpdate  = useCallback(async (talk : UpdateTalkType) => {
        const updatedTalk =  await talkAPI.update(talk);
        onUpdate && onUpdate(updatedTalk);
        onSubmit && onSubmit(updatedTalk);
        addToast({
            title : 'Talk updated',
            children : 'Talk successfully updated',
            type : 'success'
        });
        updateTalks()
        return updatedTalk;
    }, [onUpdate, onSubmit, addToast, updateTalks]);

    const submitFunction :  () => void = () => submitAction.trigger();

    const formik = useFormik<FormTalkType>({
        enableReinitialize : true,
        initialValues : editedTalk,
        validationSchema : Yup.object({
            'title' : Yup.string().required('Required')
        }),
        onSubmit : submitFunction
    });


    const { values, isValid } = formik;

    const submitAction = useAction<Talk>(useCallback(async () => {
        try {
            if(values.id !== undefined) {
                return handleUpdate(TalkConverter.formTypeToUpdateTalk(values as FormTalkType & ObjectWithId<number>));
            }
            return handleCreate(TalkConverter.formTypeToNewTalk(values));

        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return undefined;
    }, [handleUpdate, handleCreate, values]));


    const buttons = useMemo(() => {
        return [<Button key="save" color="primary" {...submitAction.buttonProps}
            disabled={!isValid || submitAction.loading}>Save</Button>];
    }, [submitAction, isValid]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange])


    return <>
    <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

    <FormikProvider value={formik}>
        <Form tag={FkForm}>


        <Nav tabs>
            <NavItem><NavLink href="#" active={activeTab === 0} onClick={() => setActiveTab(0)}>Talk</NavLink></NavItem>
            <NavItem><NavLink href="#" active={activeTab === 1} onClick={() => setActiveTab(1)}>Event</NavLink></NavItem>
            <NavItem><NavLink href="#" active={activeTab === 2} onClick={() => setActiveTab(2)}>Slides and Video</NavLink></NavItem>
            <NavItem><NavLink href="#" active={activeTab === 3} onClick={() => setActiveTab(3)}>Metadata</NavLink></NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="pt-3">
            <TabPane tabId={0}>

                <InputGroup name="title" label="Title" placeholder="Talk title" />

                <Row><MarkdownGroup name="abstract" label="Abstract" /></Row>

            </TabPane>
            <TabPane tabId={1}>

                <InputGroup name="event_name" label="Event name" />

                <InputGroup name="event_url" label="Event webpage" />

                <Row>
                    <Col md={5}>
                        <FormGroup>
                            <Label for="date">Date</Label>
                            <DatePickerField id="date" name="date"
                                placeholder="DD/MM/YYYY" />
                        </FormGroup>
                        
                    </Col>
                    <Col md={7}>
                        <CitySelectGroup label="City" name="city" />
                        <CheckGroup name="online" label="Online or Hybrid event" />
                    </Col>
                </Row>

            </TabPane>

            <TabPane tabId={2}>

                <Row>
                    <Col md={6}>
                        <PdfFileGroup name="slides" label="Slides" thumbnail={talk === undefined ? '' : (talk.thumbnail === null ? '' : talk.thumbnail)} />
                    </Col>
                    <Col md={6}>
                        <ShortVideoSelectGroup label="Video" name="video" />
                    </Col>
                </Row>

            </TabPane>

            <TabPane tabId={3}>

                <Row><MscSubjectManyOneGroup name="msc_subjects" label="MSC Subjects" /></Row>
            </TabPane>
        </TabContent>

    
            { displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
            </Row> : null}
        </Form>
    </FormikProvider>
    </>;
};

export default TalkForm;