import { is } from "core/api/equality";
import { MscSubject } from "msc/api/models";

export class Serial {

    id : number;
    title : string;
    short_title : string;
    issn : string;
    publisher : string;
    websites : string[];
    msc_subjects : MscSubject[];

    constructor(json : any) {
        json.msc_subjects = json.msc_subjects.map((subject:any) => new MscSubject(subject));
        Object.assign(this, json);
    }

    is(serial : Serial) : boolean {
        return this.id === serial.id
            && this.title === serial.title
            && this.short_title === serial.short_title
            && this.issn === serial.issn
            && this.publisher === serial.publisher
            && is(this.websites, serial.websites)
            && is(this.msc_subjects, serial.msc_subjects);
    }
};

export class ShortSerial {

    id : number;
    title : string;
    short_title : string;
    issn : string;
    publisher : string;
    websites : string[];
    

    constructor(json : any) {
        Object.assign(this, json);
    }

    getURL() : string {
        if(this.websites.length === 0)
            return '';
        return this.websites[0];
    }

    is(serial : Serial) : boolean {
        return this.id === serial.id
            && this.title === serial.title
            && this.short_title === serial.short_title
            && this.issn === serial.issn
            && this.publisher === serial.publisher
            && is(this.websites, serial.websites);
    }
};
