import { BaseIdType, ObjectWithId, PaginatedData } from 'core/api/BaseAPI';
import ReadEntityAPI, { ReadEntityConfig } from 'core/api/ReadEntityAPI';


export type ReadViewEntityConfig<DataType, ShortDataType> = ReadEntityConfig<DataType> & {
    shortFactory : (new (json:any) => ShortDataType);
};



class ReadViewEntityAPI<IdType extends BaseIdType, 
    DataType extends ObjectWithId<IdType>, 
    ShortDataType extends ObjectWithId<IdType>> extends ReadEntityAPI<IdType, DataType> {

    
    shortFactory : (new (json:any) => ShortDataType)

    constructor(config : ReadViewEntityConfig<DataType, ShortDataType>) {
        super(config)
        this.shortFactory = config.shortFactory;
    }

    async getShortAll( params : object ) : Promise<PaginatedData<ShortDataType>> {
        const newParams = { ...params, 'view' : 'short' };
        const { data } = await this.axios.get('', { params : newParams } );
        return {
            count : data.count,
            results : data.results.map( (item : any) => new this.shortFactory(item) )
        };
    }

};

export default ReadViewEntityAPI;
