import { Tooltip, TooltipContent, TooltipTrigger } from "core/components/Tooltip";
import ShortUser from "directory/api/models/ShortUser";
import ShortUserCard from "directory/cards/ShortUserCard";
import React from 'react';
import { NavLink } from "react-router-dom";

class ShortUserRenderer {

    user : ShortUser;

    constructor(user: ShortUser) {
        this.user = user;
    }

    getFullNameWithTip() : React.ReactNode {
        return <Tooltip placement="bottom">
                <TooltipTrigger asChild={true}><NavLink to={this.user.getProfilePage()}>{this.user.getFullName()}</NavLink></TooltipTrigger>
                <TooltipContent className="p-0 m-0"><ShortUserCard value={this.user} /></TooltipContent>
            </Tooltip>;
    }
};

export default ShortUserRenderer;