import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { ImportAuthor } from "publications/api/PublicationImportAPI";
import NewPublicationItem from 'publications/items/NewPublicationItem';


export type ImportAuthorItemProps = 
    SelectableItemProps<ImportAuthor>;

const ImportAuthorItem : React.FC<ImportAuthorItemProps> = ({
        value : author, style, rootRef,
        selectable = false, onSelect,
        action = false, active = false}) => {

    const morePublications = author.num_entries - author.example_entries.length;

    return <BaseGroupItem action={action || selectable} 
        role="button"
        active={active} style={style} rootRef={rootRef} 
        onClick={selectable ? (() => onSelect && onSelect(author)) : undefined}>
        <a href={author.url}>{author.name}</a><br />
        <small>{author.num_entries} publications / {author.num_errors} errors</small>

        {author.num_entries === 0 ? <p>No recognized publications</p> : <>
            <ListGroup className="mt-3">
                {author.example_entries.map((pub, i) => <NewPublicationItem key={pub.title + i} value={pub} />)}
                {morePublications > 0 ? (
                <ListGroupItem className="small text-muted">
                    ... and {morePublications} more
                </ListGroupItem>) : null}
            </ListGroup>
        </>}
    
    </BaseGroupItem>;
};

export default ImportAuthorItem;