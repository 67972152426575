import { Alert, Button, Card, CardBody, CardText, CardTitle, Col, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import React, { useEffect, useMemo, useState } from 'react';
import Modal, { ModalState } from "core/components/Modal";
import ShortUser from "directory/api/models/ShortUser";
import { CloudUploadFill, InfoCircleFill, InputCursorText } from "react-bootstrap-icons";
import BibtexDirectStepper from "publications/forms/steppers/BibtexDirectStepper";
import BibtexFileStepper from "publications/forms/steppers/BibtexFileStepper";
import GenericStepper from "publications/forms/steppers/GenericStepper";

export type ImportFormModalProps = {
    onSubmit? : () => void;
    user : ShortUser;
    modal : ModalState
};

type BibtexSourceChoiceProps = {
    onChoiceChange : (choice : 'direct' | 'file' | 'dblp' | 'zbmath' ) => void;
}

const BibtexSourceChoice : React.FC<BibtexSourceChoiceProps> = ({
    onChoiceChange
}) => {

    return <div>
        <h4 className="ps-5 pt-4">Choose your import source</h4>

        <Alert isOpen={true} color="info" className="m-3">
        <Row>
            <Col md="1">
                <InfoCircleFill className="m-3" size={32} />
                </Col>
            <Col md="11">
                <ul>
                    <li>Preferably import your publications from ZbMath.</li>
                    <li>ZbMath articles already contain metadata such as MSC codes.</li>
                    <li>Use bibtex import only if your draft is not published yet.</li>
                </ul>
            </Col>
        </Row>
        </Alert>

        <Row className="m-3 g-3 mt-0">
            <Col md={4}>
                <Card role="button" style={{height: '250px'}} className="hover" onClick={() => onChoiceChange('direct')}>
                    <InputCursorText className="mt-2 m-auto" size={100} />
                    <CardBody>
                        <CardTitle tag="h5">Direct input</CardTitle>
                        <CardText className="small">
                            Import publications by submitting bibtex text
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
            <Col md={4}>
            <Card role="button" style={{height: '250px'}} className="hover" onClick={() => onChoiceChange('file')}>
                <CloudUploadFill className="mt-2 m-auto" size={100}/>
                <CardBody>
                    <CardTitle tag="h5">File upload</CardTitle>
                    <CardText className="small">
                        Import publications by uploading a bibtex file
                    </CardText>
                </CardBody>
            </Card>
            </Col>
            {/*<Col md={3}>
            <Card role="button" style={{height: '250px'}} className="hover" onClick={() => onChoiceChange('dblp')}>
                <img className="mx-2 my-3" alt="DBLP import" src="/images/publications/imports/dblp.png" />
                <CardBody>
                    <CardTitle tag="h5">DBLP import</CardTitle>
                    <CardText className="small">
                        Import publications from the DBLP database
                    </CardText>
                </CardBody>
            </Card>
            </Col>*/}
            <Col md={4}>
            <Card role="button" style={{height: '250px', backgroundColor: 'var(--bs-alert-bg)'}} className="hover alert-info" onClick={() => onChoiceChange('zbmath')}>
                <img className="mx-2 mt-2 mb-1" alt="DBLP import" src="/images/publications/imports/zbmath.png" />
                <CardBody>
                    <CardTitle tag="h5">zbMATH import</CardTitle>
                    <CardText className="small">
                        Import publications from the zbMATH database
                    </CardText>
                </CardBody>
            </Card>
            </Col>
        </Row>
    </div>;
}

const ImportFormModal : React.FC<ImportFormModalProps>= ({modal, user, onSubmit}) => {

    const [sourceChoice, setSourceChoice] = useState(undefined as undefined | 'direct' | 'file' | 'dblp' | 'zbmath');
    const [buttons, setButtons] = useState([] as React.ReactElement[]);

    useEffect(() => {
        if(modal.isOpen) {
            setSourceChoice(undefined);
            setButtons([]);
        }
    }, [modal.isOpen])

    const formChoice = useMemo(() => {
        switch(sourceChoice) {
            case undefined : 
                return <BibtexSourceChoice onChoiceChange={setSourceChoice} />
            case 'direct' :
                return <BibtexDirectStepper value={undefined} displayButtons={false} onSubmit={modal.close} onButtonsChange={setButtons} />
            case 'file' :
                return <BibtexFileStepper value={undefined} displayButtons={false} onSubmit={modal.close} onButtonsChange={setButtons} />
            case 'dblp' :
                return <GenericStepper source="dblp" value={undefined} displayButtons={false} onSubmit={modal.close} onButtonsChange={setButtons} />
            case 'zbmath' :
                return <GenericStepper source="zbmath" value={undefined} displayButtons={false} onSubmit={modal.close} onButtonsChange={setButtons} />

        }
    }, [sourceChoice, modal.close])

    return <Modal state={modal} size="lg">
        <ModalHeader className="bg-light" toggle={modal.close}>Import Bibtex</ModalHeader>

        <ModalBody className="p-0">
            {formChoice}
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default ImportFormModal;