import AutoDismissAlert from "core/components/AutoDismissAlert";
import { NewPublicationType } from "publications/api/types";
import { StepProps } from "core/forms/steps/types";
import { useEffect, useState } from "react";
import { Alert, Button, Input } from "reactstrap";
import React from 'react';
import { errorToText } from "core/helpers/error";
import { publicationImportAPI } from "publications/api/entities";
import { InfoCircleFill } from "react-bootstrap-icons";
import { useAction } from "core/hooks/action";

export type BibtexDirectTextStepProps = StepProps<undefined, NewPublicationType[]>;

const BibtexDirectTextStep : React.FC<BibtexDirectTextStepProps> = ({
    onSubmit,
    onButtonsChange
}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [bibtexText, setBibtexText] = useState('');
    const submitHandler = useAction<NewPublicationType[]>(async () => {
        try {
            const result = await publicationImportAPI.bibtex_check(bibtexText);
            return result.entries;
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return [];
    });
    submitHandler.addListener(onSubmit);

    useEffect(() => {
        onButtonsChange && onButtonsChange([
            <Button key="direct-step-1" color="primary" {...submitHandler.buttonProps}>Next</Button>
        ])
    }, [onButtonsChange, submitHandler.buttonProps])

    return <>

        <Alert isOpen={true} color="info" className="m-3">
        <InfoCircleFill size={32} /> &nbsp; Only the publications where you are identified as an author will be imported.
        </Alert>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

        <div className="p-3">
        <Input type="textarea" rows="10" placeholder="Enter your bibtex input here" onChange={e => setBibtexText(e.target.value)} />
        </div>
    </>;

};

export default BibtexDirectTextStep;