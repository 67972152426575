import AutoDismissAlert from "core/components/AutoDismissAlert";
import { NewPublicationType } from "publications/api/types";
import { StepProps } from "core/forms/steps/types";
import { useCallback, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import React from 'react';
import { errorToText } from "core/helpers/error";
import { publicationAPI } from "publications/api/entities";
import Publication from "publications/api/models/Publication";
import NewPublicationItem from "publications/items/NewPublicationItem";
import { useUpdatePublications } from "publications/hooks/publication";
import { useSelector } from "react-redux";
import { AuthState } from "store";
import { ToasterContext } from "core/hooks/toaster";
import { XOctagon } from "react-bootstrap-icons";
import { useAction } from "core/hooks/action";

export type BibtexEntryChoiceStepProps = StepProps<NewPublicationType[], Publication[]>;

const BibtexEntryChoiceStep : React.FC<BibtexEntryChoiceStepProps> = ({
    input,
    onSubmit,
    onButtonsChange
}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [values, setValues] = useState(input as NewPublicationType[]);
    const auth = useSelector( (state:AuthState) => state.auth);
    const updatePublications = useUpdatePublications();
    const addToast = useContext(ToasterContext);

    const submitHandler = useAction<Publication[]>(useCallback(async () => {
        try {
            const result = await publicationAPI.batch(values);
            if(auth.user !== undefined)
                updatePublications(auth.user, undefined);
            addToast({
                type : 'success',
                title : 'Publications imported',
                children : <>{result.length} publications successfully imported</>
            })
            return result;
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return [];
    }, [addToast, auth.user, updatePublications, values]));
    submitHandler.addListener(onSubmit);

    const handleGeneralCheckChange = useCallback(() => {
        setValues(values => 
            values.length === input.length ? [] : [...input])
    }, [input]);

    useEffect(() => {
        if(input.length === 0) {
            onButtonsChange && onButtonsChange([]);
            return;
        }
        onButtonsChange && onButtonsChange([
            <Button key="direct-step-1" color="primary" {...submitHandler.buttonProps}>Submit</Button>
        ])
    }, [onButtonsChange, input, submitHandler.buttonProps]);

    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

        <Alert isOpen={input.length === 0} color="danger" className="m-3">
            <XOctagon size={32} /> &nbsp; No publication found.
        </Alert>

        {input.length > 0 ? (
        <div className="p-3">
        

            <ListGroup className="mt-2">
                <ListGroupItem className="small" style={{backgroundColor : 'transparent'}}>
                    <Row>
                        <Col md={1}>
                            <Input type="checkbox" checked={values.length === input.length} onChange={handleGeneralCheckChange} />
                        </Col>
                        <Col md={11}>
                            {values.length === input.length ? 'Unselect all' : 'Select all'}
                        </Col>
                    </Row>
                </ListGroupItem>
            {input.map((entry, i) => <NewPublicationItem 
                key={entry.title + i} value={entry} 
                checkable={true} checked={values.filter(value => JSON.stringify(value) === JSON.stringify(entry)).length > 0}
                onCheckChange={(entry, checked) => checked ? setValues(values => [...values, entry]) : setValues(values => values.filter(v => v !== entry))} />)}
        </ListGroup>


        </div>
        ) : null}
    </>;

};

export default BibtexEntryChoiceStep;