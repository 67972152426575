import { useCallback } from 'core/api/equality';
import { AutocompleteContext } from 'core/components/forms/Autocomplete';
import { useModalState } from 'core/components/Modal';
import Institution from "institutions/api/models/Institution";
import ShortInstitutionCompletion from 'institutions/completions/ShortInstitutionCompletion';
import AutocompleteSelectGroup from 'core/forms/groups/AutocompleteSelectGroup';
import { AutocompleteSelectFormGroupProps } from 'core/forms/groups/types';
import InstitutionFormModal from 'institutions/modals/InstitutionFormModal';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import ShortInstitutionItem from 'institutions/items/ShortInstitutionItem';

export type InstitutionSelectGroupProps = AutocompleteSelectFormGroupProps;

const InstitutionSelectGroup : React.FC<InstitutionSelectGroupProps> = ({ 
    label = 'Institution', placeholder = 'Select an institution', name = 'institution',
    className, addable=false, bsSize
}) => {

    const { setFieldValue } = useFormikContext<object>();
    const institutionModal = useModalState();
    const [institutionName, setInstitutionName] = useState('');

    const handleAdd = useCallback((value : string, ctx : AutocompleteContext<string> ) => {
        setInstitutionName(value);
        ctx.setValue('');
        institutionModal.open();
    }, [institutionModal]);

    const handleSelectInstitution = React.useCallback((institution : Institution) => {
        setFieldValue(name, institution);
    }, [name, setFieldValue]);

    return <>
        <InstitutionFormModal modal={institutionModal} onSubmit={handleSelectInstitution} defaultName={institutionName} />
        <AutocompleteSelectGroup 
            bsSize={bsSize}
            className={className}
            label={label}
            placeholder={placeholder}
            name={name}
            addable={addable}
            onAdd={handleAdd}
            Item={ShortInstitutionItem}
            Completion={ShortInstitutionCompletion} />
        </>;
};

export default InstitutionSelectGroup;