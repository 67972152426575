import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { talkAPI } from "talks/api/entities";
import React, { useContext, useState } from 'react';
import Modal from "core/components/Modal";
import User from "directory/api/models/User";
import Talk from "talks/api/models/Talk";
import TalkForm from "talks/forms/TalkForm";
import { CUDFormModal } from "core/modals/types";
import { ButtonType } from "core/forms/types";
import { useAction } from "core/hooks/action";
import { useCallback } from "core/api/equality";
import { useUpdateTalks } from "talks/hooks/talk";
import { ToasterContext } from "core/hooks/toaster";
import ConfirmationButton from "core/components/forms/ConfirmationButton";

export type TalkFormModalProps = CUDFormModal<Talk> & {
    user : User;
};

const TalkFormModal : React.FC<TalkFormModalProps>= ({
    value, user,
    modal,
    onSubmit, onCreate, onUpdate, onDelete}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);
    const updateTalks = useUpdateTalks();
    const addToast = useContext(ToasterContext);

    const handleSubmit = React.useCallback(async (value : Talk) => {
        onSubmit && onSubmit(value)
        modal.close();
    }, [onSubmit, modal])


    const deleteAction = useAction(useCallback(async () => {
        if(!value) return;
        const data =  await talkAPI.delete(value);
        modal.close();
        onDelete && onDelete(value);
        updateTalks();
        addToast({
            type : 'danger',
            title : 'Talk deleted',
            children : 'Talk successfully deleted'
        })
        return data;
    }, [modal, onDelete, value, updateTalks, addToast]));

    return <Modal state={modal} size="lg">
        <ModalHeader className="bg-light" toggle={modal.close}>{value !== undefined  ? "Edit talk" : "New talk"}</ModalHeader>

        <ModalBody>

            <TalkForm user={user} value={value} displayButtons={false}
                onButtonsChange={setButtons}
                onSubmit={handleSubmit} onCreate={onCreate} onUpdate={onUpdate} />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            {value !== undefined ? <ConfirmationButton 
                color="danger" {...deleteAction.buttonProps}
                confirmQuestion={<>
                Are you sure you want to delete this talk?
                </>}
                >Delete</ConfirmationButton> : null}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default TalkFormModal;