import React, { useMemo, useState } from "react";
import { Modal as RSModal, ModalProps as RModalProps } from "reactstrap";


export type ModalState = {
    isOpen : boolean;
    setOpen : (open:boolean) => void;
    close : () => void;
    open : () => void;
    toggle : () => void;
};

export const useModalState = () : ModalState => {
    const [isOpen, setOpen] = useState(false);
    return useMemo(() => ({
        isOpen,
        setOpen,
        close : () => setOpen(false),
        open : () => setOpen(true),
        toggle : () => setOpen(prevState => !prevState)
    }), [isOpen, setOpen]);
};

export type ModalProps = RModalProps & {
    children : React.ReactNode;
    size? : string;
    state : ModalState;
    
};

const Modal : React.FC<ModalProps> = ({ size = "lg", state, children, ...modalProps}) => {

    return <RSModal {...modalProps} isOpen={state.isOpen} backdrop={true} fullscreen={false} size={size}>
        {children}
    </RSModal>;
};

export default Modal;