import { API_URL } from 'config';
import Axios, { AxiosInstance } from 'axios';
import store from 'store/index';

export type BaseConfig = {
    baseURL? : string;
};

export class BaseAPI {

    axios : AxiosInstance

    constructor(config : BaseConfig) {
        this.axios = Axios.create({
            baseURL: API_URL + config.baseURL,
        });
    }

    token() {
        return store.getState().auth.token;
    }

    _toFormData(obj : any) {
        if(!(obj instanceof Object)) {
            return obj;
        }
        const newObj = {};
        for(var key in obj) {
            if(obj[key] instanceof File) {
                newObj[key] = obj[key]
            }
            else if(obj[key] instanceof Array) {
                newObj[key] = JSON.stringify(obj[key])
            }
            else if(obj[key] instanceof Object) {
                newObj[key] = JSON.stringify(obj[key])
            }
            else {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    }

};

export type PaginatedData<T> = {
    count: number;
    results: T[];
};

export type BaseIdType = string | number;

export type ObjectWithId<T extends BaseIdType> = { id : T };

export type GConstructor<ConfigType, T = {}> = new (json: ConfigType) => T;