import React, {useState} from "react";
import {Formik, Field as FkField, Form as FkForm} from 'formik';
import {Form, FormGroup, Input, Label, FormFeedback} from 'reactstrap';
import * as Yup from 'yup';
import * as AuthAPI from 'auth/api';
import AutoDismissAlert from "core/components/AutoDismissAlert";
import { errorToText } from "core/helpers/error";

const ForgotPasswordForm = () => {

    const [loading, setLoading] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('');

    const handleEmail = async (email: string) => {
        await AuthAPI.sendResetEmail(email);
        setAlertColor('success');
        setAlertMessage('An email has been sent to ' + email);
        setAlertVisible(true);
    };

    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

        <Formik
    initialValues = {{
        email : '',
    }}
    validationSchema={Yup.object({
        email: Yup.string()
          .required('Required'),
    })}
    onSubmit={(values, { setSubmitting } ) => {
        setLoading(true);
        handleEmail(values.email).then(() => {
            setLoading(false);
        }).catch((err) => {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
            setLoading(false);
        });
        setSubmitting(false);
    }}>
    {({errors, touched, isValid }) => (
        <Form tag={FkForm}>
            <FormGroup className="form-floating">
                <Input id="email" tag={FkField} name="email" 
                    type="text" 
                    placeholder="user@mail.edu" 
                    invalid={touched.email && 'email' in errors} />
                <Label for="email">Email address</Label>
                <FormFeedback component="small" className="text-danger">{touched.email && errors.email}</FormFeedback>
            </FormGroup>
            <button type="submit" disabled={!isValid || loading} className="btn btn-primary w-100">Send email</button>
        </Form>
    )}
    </Formik>
    </>;

};

export default ForgotPasswordForm;