
export const extractJsonText = ( data : any ) => {
    switch(typeof data) {
        case 'string' : 
            return data;
        case 'object' :
            let message = '';
            for(let key in data) {
                message += extractJsonText(data[key]);
            }
            return message;
        default :
            return '';
    }
    
}

export const errorToText = (error : any) => {
    if(error && error.response && error.response.data) {
        return extractJsonText(error.response.data);
    }
    return error.message as string;
};