
export class Language {

    id : string;
    name : string;
    name_native : string;

    constructor(json : any) {
        Object.assign(this, json);
    }

    is(language : Language) : boolean {
        return this.name === language.name
            && this.name_native === language.name_native
            && this.id === language.id;
    }
};