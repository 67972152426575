import { MarkdownViewer } from 'core/components/Markdown';
import { userAPI } from "directory/api/entities";
import User from "directory/api/models/User";
import { useQuery } from "react-query";
import { fetchUserAboutKey } from "directory/queryKeys";
import * as React from 'react';

type TabProps = {
    user : User;
    name : string;
}

const AboutTab : React.FC<TabProps> = ({user, name}) => {

    const { data : about } = 
        useQuery([fetchUserAboutKey, user.id], () => userAPI.getAbout(user.id));

    if(!about) {
        return null;
    }

    return <>
        {about ? <MarkdownViewer value={about} /> : null}

        {user.msc_subjects.length > 0 ? (
            <>
                <h6 className="mt-3">Research interests</h6> 
                <ul className="p-0 small list-unstyled">
                {user.msc_subjects.map(subject => (
                    <li key={subject.code} className="p-0">{subject.code} ({subject.text})</li>
                ))}
                </ul>
            </>
        ) : null}
    </>;
};

export default AboutTab;