import CityCompletion from 'geonames/completions/CityCompletion';
import AutocompleteSelectGroup from 'core/forms/groups/AutocompleteSelectGroup';
import { AutocompleteSelectFormGroupProps } from 'core/forms/groups/types';
import CityItem from 'geonames/items/CityItem';
import React from 'react';

export type CitySelectGroupProps = AutocompleteSelectFormGroupProps;

const CitySelectGroup : React.FC<CitySelectGroupProps> = ({ 
    label = 'City', placeholder = 'Select a city', name = 'city',
    className, bsSize
}) => {

    return <AutocompleteSelectGroup 
            bsSize={bsSize}
            className={className}
            label={label}
            placeholder={placeholder}
            name={name}
            Item={CityItem}
            Completion={CityCompletion} />;
};

export default CitySelectGroup;