import { BaseAPI, BaseConfig, BaseIdType, GConstructor, ObjectWithId, PaginatedData } from 'core/api/BaseAPI';


export type ReadEntityConfig<DataType> = BaseConfig & {
    factory : (new (json:any) => DataType);
};

export type ReadableEntityAPI<ConfigType, IdType extends BaseIdType, DataType extends ObjectWithId<IdType>> = GConstructor<ConfigType, {
    
    getAll( params : object ) : Promise<PaginatedData<DataType>>;

    get(id : IdType) : Promise<DataType>;
}>;


class ReadEntityAPI<IdType extends BaseIdType, DataType extends ObjectWithId<IdType>> extends BaseAPI {
    
    factory : (new (json:any) => DataType)

    constructor(config : ReadEntityConfig<DataType>) {
        super(config)
        this.factory = config.factory;
    }

    async getAll( params : object ) : Promise<PaginatedData<DataType>> {
        const { data } = await this.axios.get('', { params } );
        return {
            count : data.count,
            results : data.results.map( (item : any) => new this.factory(item) )
        } as PaginatedData<DataType>;
    }

    async get(id : IdType) {
        const { data } = await this.axios.get(id + '/');
        return new this.factory(data);
    }

};




export default ReadEntityAPI;
