
import AppNavBar from "core/components/AppNavBar";
import { List } from "react-bootstrap-icons";
import React from 'react';

export interface SideMainLayoutProps {
    sideBarTitle: string;
    sideBarBody: React.ReactNode;
    children: React.ReactNode;
};

const SideMainLayout : React.FC<SideMainLayoutProps> = ({sideBarTitle, sideBarBody, children}) => {

    const sideToggle = <div className="bd-navbar-toggle">
    <button className="navbar-toggler p-2 d-flex d-lg-none order-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#bdSidebar" aria-controls="bdSidebar" aria-label="Toggle docs navigation">
        <List width="23" />
        <span className="d-none fs-6 pe-1">Browse</span>
    </button>
    </div>;

    return <>
        <AppNavBar nav={sideToggle} />
        <div className="container-xxl bd-gutter mt-3 my-md-4 bd-layout">
        <aside className="bd-sidebar flex-shrink-0 p-3">
            <div className="offcanvas-lg offcanvas-start" id="bdSidebar">
                <div className="offcanvas-header">
                    <h5 className="offcanva-title">{sideBarTitle}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" data-bs-target="#bdSidebar"></button>
                </div>
                <div className="offcanvas-body">
                    {sideBarBody}
                </div>
            </div>
        </aside>
        <main className="bd-main order-1">
            {children}
        </main>
        </div>
    </>;
}

export default SideMainLayout;