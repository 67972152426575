import { useCallback } from "core/api/equality";
import User from "directory/api/models/User";
import Publication from "publications/api/models/Publication";
import ShortUser from "directory/api/models/ShortUser";
import { useQueryClient } from "react-query";
import { fetchPublicationsKey } from "publications/queryKeys";

export const useUpdateSuggestedPublications = () => {
    const queryClient = useQueryClient();
    return useCallback((user: User | ShortUser, publications: Publication[] | undefined) => {
        if(publications === undefined) {
            queryClient.invalidateQueries({ queryKey : [fetchPublicationsKey, 'SUGGESTED', user.id] })
        }
        else {
            queryClient.setQueryData([fetchPublicationsKey, 'SUGGESTED', user.id], publications);
        }
    }, [queryClient]);
};


export const useUpdatePublications = () => {
    const queryClient = useQueryClient();
    return useCallback((user: User | ShortUser, publications: Publication[] | undefined) => {
        if(publications === undefined) {
            queryClient.invalidateQueries({ queryKey : [fetchPublicationsKey, user.id] })
        }
        else {
            queryClient.setQueryData([fetchPublicationsKey, user.id], publications);
        }
    }, [queryClient]);
};
