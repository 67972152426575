import React, { useEffect, useState } from 'react';
import GenericErrorPage from 'core/pages/errors/GenericErrorPage';
import { useLocation } from "react-router-dom";

const ERROR_CODES = {
    'ERR_NETWORK' : {
        title : 'Network error',
        message : 'Cannot access the server. Please, try again later'
    }
};

/*
    Helper class which exists only because there is no hook yet
    for componentDidCatch. Once React implements it, we will use only
    one non-class component.
*/

interface InnerCatchRouteProps {
  hasError : boolean;
  setHasError(error:boolean):void;
  children : React.ReactNode;
}

type InnerCatchRouteState = {
  hasError: boolean;
  title: string | undefined;
  message: string | undefined
};

class InnerCatchRoute extends React.Component {

    state : InnerCatchRouteState;
    props : InnerCatchRouteProps

    constructor(props: InnerCatchRouteProps) {
        super(props);
        this.state = {
            hasError : false,
            title : undefined,
            message : undefined
        }
      }
    
    static getDerivedStateFromError(error : { code : string, name : string, message : string }) {
        let err = ERROR_CODES[error.code] 
            || { title : error.name, message : error.message };
        
        return {
                hasError : true,
                ...err
            };
    }
    
    componentDidUpdate(prevProps : InnerCatchRouteProps) {
        if(!this.props.hasError && prevProps.hasError) {
          this.setState({ hasError: false });
        }
    }
    
    componentDidCatch() {
        this.props.setHasError(true);
    }
    
    render() {
        return this.state.hasError
          ? <GenericErrorPage title={this.state.title} message={this.state.message} />
          : this.props.children; 
    }

};

type CatchRouteProps = {
  children : React.ReactNode;
};

const CatchRoute : React.FC<CatchRouteProps> = ({children}) => {

    const [hasError, setHasError] = useState(false);
    const location = useLocation();
    useEffect(() => {
      if (hasError) {
        setHasError(false);
      }
    }, [location.key, hasError]);
    return (
      <InnerCatchRoute 
        hasError={hasError} 
        setHasError={setHasError}
      >
        {children}
      </InnerCatchRoute>
    );
};

export default CatchRoute;