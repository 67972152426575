import { useCallback, useMemo } from "core/api/equality";
import { Book } from "books/api/models";
import BookRenderer from "books/renderers/BookRenderer";
import * as React from 'react';
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import { BasicItemProps, SelectableItemProps } from "core/items/types";

type BookCardProps = BasicItemProps<Book> &
    SelectableItemProps<Book>;

const BookCard : React.FC<BookCardProps> = ({
        value : book, 
        selectable, onSelect
    }) => {

    const renderer = useMemo(() => new BookRenderer(book), [book]);
  
    const handleClick = useCallback(() => {
        onSelect && onSelect(book);
    }, [onSelect, book]);
  
    return <div className="col mb-3 btn" onClick={selectable ? handleClick : undefined}>
      <Card className="h-100">
        <div className="row g-0 align-items-center h-100" style={{minHeight: '7rem'}}>
         <div className="col text-start" style={{maxWidth:'100px'}}>
            {renderer.getThumbnail()}
         </div>
         <div className="col">
          <CardBody>
            <CardTitle tag="h5" className="">{book.title}</CardTitle>
            {<CardSubtitle className="small mt-2 text-muted">{book.subtitle}</CardSubtitle>}
            <CardText className="small mt-3">{renderer.getAuthors()}</CardText>
          </CardBody>
      </div>
      </div>
      </Card>
      </div>;
};

export default BookCard;