import { StepProps } from "core/forms/steps/types";
import { useCallback, useEffect, useState } from "react";
import { ListGroup } from "reactstrap";
import React from 'react';
import { publicationImportAPI } from "publications/api/entities";
import { ImportAuthor } from "publications/api/PublicationImportAPI";
import ImportAuthorItem from "publications/items/ImportAuthorItem";
import { NewPublicationType } from "publications/api/types";
import { useAction } from "core/hooks/action";
import AutoDismissAlert from "core/components/AutoDismissAlert";
import { errorToText } from "core/helpers/error";
import { useAuthUser } from "directory/hooks/user";

export type AuthorChoiceStepProps = StepProps<ImportAuthor[], NewPublicationType[]>;

const AuthorChoiceStep : React.FC<AuthorChoiceStepProps> = ({
    input,
    onSubmit,
    onButtonsChange
}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [author, setAuthor] = useState(undefined as undefined | ImportAuthor)
    const user = useAuthUser();

    const submitHandler = useAction<NewPublicationType[]>(useCallback(async () => {
        if(author === undefined) {
            return undefined;
        }
        try {
            const result = await publicationImportAPI.bibtex_check(new URL(author.bibtex_url));
            return result.entries;
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return [];
    }, [author]));
    submitHandler.addListener(onSubmit);
    

    useEffect(() => {
        if(author === undefined) {
            return;
        }
        submitHandler.trigger();
    }, [author, submitHandler]);

    useEffect(() => {
        onButtonsChange && onButtonsChange([]);
       
    }, [onButtonsChange])

    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>


        <div className="p-3">
        
        <h5 className="mb-4 text-center">Authors matching {user?.getFullName()}</h5>
        <ListGroup className="mt-2">
            {input.map(author => <ImportAuthorItem 
                key={author.bibtex_url} value={author} 
                selectable={true} onSelect={setAuthor} />)}
        </ListGroup>

        </div>
    </>;

};

export default AuthorChoiceStep;