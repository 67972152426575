
import React, { useState } from 'react';
import { fetchPublicationsKey } from "publications/queryKeys";
import { useCallback } from 'core/api/equality';
import PaginatedComponent, { PaginationFooter, PaginationHeader } from 'core/components/PaginatedComponent';
import { Button, Table } from 'reactstrap';
import { PublicationFilter } from 'publications/forms/searches/PublicationSearch';
import Publication from 'publications/api/models/Publication';
import PublicationItem from 'publications/items/PublicationItem';
import { publicationAPI } from 'publications/api/entities';
import { ListColumns, Newspaper } from 'react-bootstrap-icons';
import DetailedPublicationItem from 'publications/items/DetailedPublicationItem';


export type PublicationListProps  = {
    filter : PublicationFilter;
};

export const enum ViewStyle {
  LIST = 'LIST',
  PUBLISHABLE = 'PUBLISHABLE'
};


const PublicationList : React.FC<PublicationListProps> = ({filter}) => {

  const [viewStyle, setViewStyle] = useState(ViewStyle.LIST);

  const query = useCallback((params : object) => {
      return publicationAPI.getAll({
          ...params,
          search : filter.search,
          msc_code : filter.msc_subjects.map(subject => subject.code).join(','),
          user_id : filter.authors.map(author => author.id).join(','),
          serial_id : filter.serials.map(serial => serial.id).join(','),
      })
  }, [filter.authors, filter.msc_subjects, filter.serials, filter.search]);

  const content = useCallback((publications : Publication[]) => {
        return <Table striped hover className="border bg-white">
            <thead>
                  <tr>
                    <th>Publications</th>
                  </tr>
                </thead>
                <tbody>
                  {publications.length <= 0? (
                  <tr>
                    <td align="center">
                      <b>No publication found.</b>
                    </td>
                  </tr>
                  ) : (
                    publications.map(publication => (
                    <tr key={publication.id}><td>{viewStyle === ViewStyle.PUBLISHABLE ? <PublicationItem value={publication} /> :
                    <DetailedPublicationItem value={publication} />
                    }</td></tr>
                    ))
                  )}
                </tbody>
              </Table>;
  }, [viewStyle])

  return <PaginatedComponent queryKey={[fetchPublicationsKey, filter]} query={query} defaultLimit={20}>
    {(values, pagination) => <>
      <PaginationHeader pagination={pagination}>

            <Button outline className="border ms-2" onClick={() => setViewStyle(ViewStyle.PUBLISHABLE)} active={viewStyle === ViewStyle.PUBLISHABLE}>
                <Newspaper /></Button>
            <Button outline className="border ms-2"  onClick={() => setViewStyle(ViewStyle.LIST)} active={viewStyle === ViewStyle.LIST}>
                <ListColumns /></Button>
        
      </PaginationHeader>{content(values)}<PaginationFooter pagination={pagination} />
    </>}
    </PaginatedComponent>;
   
};

export default PublicationList;
  