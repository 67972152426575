import { useLayoutEffect, useState } from "react";
import useResizeObserver from '@react-hook/resize-observer';
import React from 'react';

export const useWidth = (target : React.MutableRefObject<any>) : number => {
    const [width, setWidth] = useState(0);
  
    useLayoutEffect(() => {
        if(target.current === null)
            return;
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);
  
    useResizeObserver(target, (entry) => setWidth(entry.contentRect.width));
    return width;
};

