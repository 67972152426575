import "core/components/forms/BasicForm.scss";
import React from 'react';

export interface Props {
    title? : string;
    children : React.ReactNode;
    style? : object
    className? : string;
};

const BasicForm : React.FC<Props> = ({title = '', children, className='', style={}}) => {

    return <div className={'border-sm bg-white rounded-lg shadow-sm pb-5 p-sm-5 m-sm-4 app-basic-form ' + className} style={style}>
    {title !== '' ? <h3 className="pb-sm-5 mb-sm-0 text-center">{title}</h3> : null}
    {children}
    </div>
};

export default BasicForm;