import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useState } from 'react';
import Modal, { ModalState } from "core/components/Modal";
import User from "directory/api/models/User";
import PublicationListForm from "publications/forms/PublicationListForm";
import SuggestedPublicationListForm from "publications/forms/SuggestedPublicationListForm";
import { ButtonType } from "core/forms/types";

export type PublicationListFormModalProps = {
    user : User;
    modal : ModalState
};

const PublicationListFormModal : React.FC<PublicationListFormModalProps>= ({
    user, modal}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);

    return <Modal state={modal} size="lg">
        <ModalHeader className="bg-light" toggle={modal.close}>Edit publications</ModalHeader>

        <ModalBody>
            <SuggestedPublicationListForm user={user} />
            <PublicationListForm value={user} onButtonsChange={setButtons} displayButtons={false}  />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="secondary" onClick={modal.close}>Close</Button>
        </ModalFooter>
    </Modal>
};

export default PublicationListFormModal;