import { CompletionProps } from 'core/completions/types';
import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import { ListGroup } from 'reactstrap';
import { BookCategory } from 'books/api/models';
import { fetchBookCategoryListKey } from 'books/queryKeys';
import { bookCategoryAPI } from 'books/api/entities';
import BookCategoryItem from 'books/items/BookCategoryItem';

export type BookCategoryCompletionProps = CompletionProps<BookCategory>;

const BookCategoryCompletion : React.FC<BookCategoryCompletionProps> = ({
    text, onSelect,
    exclude = [],
    selectedValue, limit = 5
}) => {

    const queryHandler = useCallback(() => {
        return bookCategoryAPI.getAll({
            search : text,
            limit: limit + exclude.length
        });
    }, [text, limit, exclude]);
    

    const { data, isError, isLoading } = useQuery(
        [fetchBookCategoryListKey, text], queryHandler, { keepPreviousData : true } );

    if(isError || isLoading) {
        return null;
    }

    if(!data) {
        return null;
    }

    const categories = data.results.filter(cat => exclude.filter(ex_cat => ex_cat.is(cat)).length === 0).slice(0, limit);

    return <ListGroup>
        {categories.map(category => (
            <BookCategoryItem key={category.id} value={category} 
                active={(selectedValue !== undefined) && selectedValue.is(category)}
                selectable={true}
                onSelect={onSelect} />
        ))}
    </ListGroup>;

};

export default BookCategoryCompletion;