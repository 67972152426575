import React, { useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import * as AuthAPI from "auth/api";
import * as URLHelper from "core/helpers/urls";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router';
import authSlice from "store/slices/auth";
import { useAuthUser } from "directory/hooks/user";
import { NavLink } from "react-router-dom";

const UserMenu = () => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const user = useAuthUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        AuthAPI.logout().then((event) => {
            dispatch(authSlice.actions.logout());
            navigate("/");
        }).catch((err) => {
            console.log("An error occurred");
        })
    };

    const login = () => {
        navigate("/auth/login");
    };

    // If not authenticated, return login button
    if(!user) {
        return <Button color="light" onClick={login} outline>Login</Button>;
    }

    // If authenticated, return user menu
    return <Row className="row-cols-lg-auto g-3 align-items-center">
        <Col className="d-none d-lg-block">Welcome {user.first_name}! </Col>
        <Col><Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
            <DropdownToggle caret color="dark"><img src={user.getAvatar()} width={32} alt="Avatar" className="rounded-circle" /></DropdownToggle>
            <DropdownMenu end className="border-0 p-0" style={{width: '400px'}}>
                <Card className="shadow-sm">
                    <Row className="g-0">
                        <Col md={7}>
                        <CardBody>
                            <CardTitle className="fw-bold">{user.first_name} {user.last_name}</CardTitle>
                            <ul className="list-unstyled small p-2">
                                <li><NavLink to={user.getProfilePage()}>Public Profile</NavLink></li>
                                <li><NavLink to={URLHelper.panelUrl()}>My Panel</NavLink></li>
                                <li className="a" onClick={logout}>Logout</li>
                            </ul>
                        </CardBody>
                        </Col>
                        <Col md={5} className="d-none d-md-block">
                            <img src={user.getAvatar()} alt="Avatar" className="img-fluid rounded-end" />
                        </Col>
                    </Row>
                </Card>
            </DropdownMenu>
        </Dropdown></Col>
    </Row>;
}


export default UserMenu;