
import { Button, Input, InputGroup } from "reactstrap";
import React, { useCallback, useMemo } from 'react';
import "core/components/forms/Autocomplete.scss";
import FocusableComponent from "core/components/FocusableComponent";
import { useElementSize } from "usehooks-ts";

export type AutocompleteContext<ValueType> = {
    value : ValueType;
    setValue : (value:ValueType) => void;
};

export type Props<ValueType> = {
    className? : string;
    children? : (ctx:AutocompleteContext<ValueType>) => React.ReactNode;
    invalid? : boolean;
    inputRef? : (node: HTMLInputElement | null) => void
    autoFocus? : boolean;
    id : string;
    bsSize? : 'lg' | 'sm';
    value : ValueType;
    name : string;
    placeholder? : string;
    addable? : boolean;
    onAdd? : (value:ValueType, ctx:AutocompleteContext<ValueType>) => void;
    onChange : (value:ValueType) => void;
    inputStyle? : object;
    buttonStyle? : object;
};

const Autocomplete = <ValueType extends string | number | readonly string[] | undefined, >(props:Props<ValueType>) => {

    const {
        className, children,
        invalid,
        autoFocus = false,
        inputStyle, buttonStyle, bsSize,
        id, value, name, placeholder, onChange, onAdd, addable = false,
        } = props;

    const [inputRef, { height : inputHeight } ] = useElementSize<HTMLInputElement>();
    const setValue = useCallback((value : any) => onChange && onChange(value), [onChange]);

    const ctx = useMemo(() => ({
        value, setValue
    }), [value, setValue]);

    const handleAdd = useCallback(() => {
        if(onAdd === undefined) return;
        onAdd && onAdd(value, ctx);
    }, [value, ctx, onAdd]);

    return <FocusableComponent>
        { ({ hasFocus, setFocus }) => <div className={'app-input-autocomplete app-autocomplete-root ' + className}>
            <InputGroup size={bsSize}>
                <Input id={id} value={value} invalid={invalid} innerRef={inputRef}
                    style={inputStyle}
                    bsSize={bsSize}
                    autoFocus={autoFocus}
                    onKeyDown={(e:any) => {
                        if(e.key === 'Enter') {
                            e.preventDefault();
                            handleAdd()
                        }}}
                    onChange={e => setValue(e.target.value)} name={name} type="text" placeholder={placeholder} />
                    {addable ? <Button onClick={handleAdd} style={buttonStyle}>Add</Button> : null}
            </InputGroup>
            {children === undefined ? null : (
                <div className={'app-autocomplete-results app-autocomplete-focus rounded' + (hasFocus ? ' ' : ' d-none')} 
                    onClick={() => setFocus(false)}>
                    <div className="app-autocomplete-content rounded" style={{marginTop: (inputHeight)+ 'px'}}>
                    {children(ctx)}
                    </div>
                </div>
            )}
        </div> }
    </FocusableComponent>;

};
           
export default Autocomplete;