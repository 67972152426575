import Talk from "talks/api/models/Talk";
import { format } from 'date-fns';
import React from 'react';

class TalkRenderer {

    talk : Talk;

    constructor(talk: Talk) {
        this.talk = talk;
    }

    getEventName() : React.ReactNode {
        const name = this.talk.event_name || this.talk.event_url
        if(this.talk.event_url) {
            return <a href={this.talk.event_url}>{name}</a>
        }
        return name;
    }

    getThumbnail() : React.ReactNode {

        const talk = this.talk;

        const downloadSlides = () => {
            if(talk.slides)
            window.location.href = talk.slides;
        };

        if(talk.thumbnail) {
            return <img role="button" src={talk.thumbnail} onClick={downloadSlides} className="w-100" alt="Slides" />
        }

        if(talk.slides) {
            return <img role="button" src="/images/talk.png" onClick={downloadSlides} className="w-100" style={{maxWidth: '128px', margin: 'auto'}} alt="Slides" />
        }

        return <img src="/images/unknown.png" className="w-100" style={{maxWidth: '128px'}} alt="Slides" />
    }

    getEventHumanDate() : string {
        if(this.talk.date === null) {
            return '';
        }
        return format(this.talk.date, 'PP')
    }


}

export default TalkRenderer;