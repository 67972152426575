
import BasicForm from "core/components/forms/BasicForm";
import MainLayout from "core/components/layouts/MainLayout";
import ForgotPasswordForm from "auth/forms/ForgotPasswordForm";
import React from 'react';
import { NavLink } from "react-router-dom";
import { Col, Row } from "reactstrap";

const ForgotPasswordPage = () => {

    return  <MainLayout>
        <section className="py-5 row justify-content-center">
        <BasicForm title="Forgot your password?" style={{maxWidth: '500px'}}>
            <p>Enter your email to receive a password reset link</p>
            <ForgotPasswordForm />
            <Row className="mt-2">
                <Col><small><NavLink to="/auth/register">Register</NavLink></small></Col>
                <Col className="text-end"><small><NavLink to="/auth/login">Log in</NavLink></small></Col>
            </Row>
        </BasicForm>
        </section>
        </MainLayout>;
};

export default ForgotPasswordPage;