import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useState } from 'react';
import Modal from "core/components/Modal";
import Author from "publications/api/models/Author";
import AuthorPickerForm from "publications/forms/AuthorPickerForm";
import { BasicFormModal } from "core/modals/types";
import { ButtonType } from "core/forms/types";
import { useCallback } from "core/api/equality";

export type AuthorPickerModalProps = BasicFormModal<Author> & {
    value : Author;
};

const AuthorPickerModal : React.FC<AuthorPickerModalProps>= ({
    value, modal, onSubmit}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);

    const handlePick = useCallback(async (author : Author) => {
        modal.close();
        return onSubmit && onSubmit(author);
    }, [modal, onSubmit]);

    const handleNew = useCallback(async () => {
        return handlePick(new Author({ name : value.name, user : null }) );
    }, [handlePick, value.name]);

    return <Modal state={modal} size="md">
        <ModalHeader className="bg-light" toggle={modal.close}>Pick a user</ModalHeader>

        <ModalBody>

            <AuthorPickerForm value={value} displayButtons={false} onButtonsChange={setButtons} onSubmit={handlePick} />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="danger" onClick={handleNew}>Unknown author</Button>
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default AuthorPickerModal;