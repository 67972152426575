import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { DeletableItemProps } from 'core/items/types';
import bibtexParse from '@orcid/bibtex-parse-js';
import { useCallback } from 'core/api/equality';
import { X } from 'react-bootstrap-icons';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { NewPublicationType } from "publications/api/types";


export type BibtexFileItemProps = DeletableItemProps<File> & {
    onChange? : (values : NewPublicationType[]) => void;
};

const BibtexFileItem : React.FC<BibtexFileItemProps> = ({
    value : file, style, rootRef, 
    deletable = false, onDelete}) => {

    const [bibtexText, setBibtexTex] = useState('');

    const jsonContent = React.useMemo(() => bibtexParse.toJSON(bibtexText), [bibtexText]) as any[];

    file.text().then(setBibtexTex)

    const handleRemove = useCallback(() => {
        onDelete && onDelete(file);
    }, [file, onDelete])

    return <BaseGroupItem style={style} rootRef={rootRef} className="bg-light">
        <Row className="small">
            <Col 
                lg={1} md={2} className="d-none d-md-block p-0">
                <img src="/images/bibtex.png" className="w-100" alt="Avatar" />
            </Col>
            <Col lg={11 - (deletable ? 2 : 0)} md={10 - (deletable ? 2 : 0)}><div>{file.name}</div>
                {jsonContent.length} entries
            </Col>
            {deletable ? <Col md={2} className="text-end" role="button" onClick={handleRemove}><X width={32} /></Col> : null}
        </Row>
        
    </BaseGroupItem>;
};

export default BibtexFileItem;