import MainLayout from 'core/components/layouts/MainLayout';
import React from 'react';
import { Row } from 'reactstrap';

export type DetailPageProps<ValueType> = {
    value : ValueType;
};


export type ChildrenProps = React.HTMLAttributes<HTMLElement> & {
    children? : React.ReactNode;
};

export const DetailLayout : React.FC<ChildrenProps> = ({
    children
}) => {
    return <MainLayout>
    <div className="container app-detail-page shadow-sm">
            <Row>
                {children}
            </Row>    
    </div>
    </MainLayout>;
};

export const DetailLeftColumn : React.FC<ChildrenProps> = ({
    children
}) => {

    return <div className="col-md-4">
            {children}
        </div>;
};

export const DetailMainColumn : React.FC<ChildrenProps> = ({
    children, className, ...props
}) => {
    return <div {...props} className={'app-detail-page-main col-md-8 pe-md-5 ' + className}>{children}</div>
}