

import ViewEntityAPI from 'core/api/ViewEntityAPI';
import UserAPI from 'directory/api/UserAPI';
import { NewAffiliationType, UpdateAffiliationType } from 'directory/api/types';
import User from "directory/api/models/User";
import Affiliation from "directory/api/models/Affiliation";
import ShortUser from 'directory/api/models/ShortUser';


export const affiliationAPI = new ViewEntityAPI<number, Affiliation, Affiliation, NewAffiliationType, UpdateAffiliationType>({
   baseURL : 'directory/affiliations/',
   factory : Affiliation,
   shortFactory : Affiliation
});

export const userAPI = new UserAPI({
    baseURL : 'directory/users/',
    factory : User,
    shortFactory : ShortUser
});


