import { format } from 'date-fns';

export const textDateToYearMonth = (textDate : string) => {
    return textDate.substr(0, 7);
};

export const textDateToHumanYearMonth = (textDate : string) => {
    return (new Date(textDate)).toLocaleString('en-us', { month: 'short', year: 'numeric' } );
};

export const isTextDateDefined = (textDate : string) => {
    return textDate && (textDate !== '0001-01-01');
};

export const dateToTextDate = (date : Date | null) => {
    if(!date || !isValidDate(date)) return '0001-01-01';
    return date.getFullYear() === 1 ? '0001-01-01'
        : format(date, 'yyyy-MM-dd');
};

export const isValidDate = (date:any) =>
    date && date instanceof Date && !isNaN(date as unknown as number);



export const yearMonthToDate = (textDate : string) => 
    new Date(parseInt(textDate.split("/")[1]), parseInt(textDate.split("/")[0])-1); 

