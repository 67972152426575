
import { useState } from 'react';
import {ListGroup} from 'reactstrap';
import Autocomplete, { AutocompleteContext } from 'core/components/forms/Autocomplete';
import {useFormikContext} from 'formik';
import "core/components/forms/AutocompleteSelect.scss";
import FocusableComponent from 'core/components/FocusableComponent';
import React from 'react';
import { SelectableItemProps } from 'core/items/types';
import { useElementSize } from "usehooks-ts";

/**
    Formik-controlled autocomplete select
 */

export type AutocompleteSelectContext<ValueType> = {
    autocompleteValue : string;
    setValue : (value:ValueType) => void;
    setAutocompleteValue : (value:string) => void
};



export type Props<ValueType> = {
    id: string;
    name: string;
    bsSize? : 'lg' | 'sm';
    invalid? : boolean;
    placeholder? : string;
    valueToString? : (value:ValueType) => string;
    Item : React.FC<SelectableItemProps<ValueType>>;
    choiceList? : ValueType[],
    children : (ctx:AutocompleteSelectContext<ValueType>) => React.ReactNode;
    addable : boolean;
    onAdd? : (value : string, ctx:AutocompleteContext<string>) => void;
};


export type ChoiceListProps<ValueType> = {
    choiceList : ValueType[],
    Item :  React.FC<SelectableItemProps<ValueType>>;
    setValue : (value:ValueType) => void
}

const ChoiceList = <ValueType, > (props: ChoiceListProps<ValueType>) => {

    const { choiceList, Item, setValue } = props;

    return <ListGroup>
        {choiceList.map((choice, i) => <Item key={i} value={choice} onSelect={setValue} />)}
    </ListGroup>;
}


const AutocompleteSelect = <ValueType, > (props: Props<ValueType>) => {


    const {
        id, name, invalid=false, 
        placeholder, 
        choiceList,
        children,
        bsSize,
        onAdd, addable = false,
        Item
    } = props;
    const {values, setFieldValue } = useFormikContext<object>();
    const [autocompleteValue, setAutocompleteValue] = useState('');
    const setValue = (value:ValueType) => setFieldValue(name, value);
    const [divRef, { height  } ] = useElementSize<HTMLDivElement>();

    return <div className={invalid ? 'is-invalid' : ''}>
        <FocusableComponent onFocus={() => setTimeout(() => {
            //const current = inputRef.current;
            //if(current !== null) {
            //    current.focus();
            //}
        }, 3000)}>
            {({ hasFocus, setFocus }) => <div className="app-autocomplete-select app-autocomplete-root">

                <div className="app-autocomplete-focus">
                <div className="app-autocomplete-content" style={{'marginTop' : height + 'px'}}>
                <Autocomplete 
                    id={id + '_autocomplete'} 
                    bsSize={bsSize}
                    autoFocus={true}
                    name={name + '_autocomplete'} value={autocompleteValue} 
                    onChange={value => setAutocompleteValue(value)}
                    addable={addable}
                    onAdd={onAdd}
                    >
                    {({value : autocompleteValue, setValue : setAutocompleteValue}) => 
                        choiceList === undefined ? 
                            children({ autocompleteValue, setAutocompleteValue, setValue : (v) => {setFocus(false); setAutocompleteValue(''); setValue(v)}}) :
                            <ChoiceList choiceList={choiceList} setValue={(v) => {setFocus(false); setValue(v)}} 
                                Item={Item} />}
                </Autocomplete>
                </div>
                </div>

                <div className={'form-control p-0' + (invalid ? ' is-invalid' : '')} ref={divRef}>
                    {values[name] ? <ListGroup className="p-0 m-0">
                        <Item value={values[name]} className="m-0 border-0" />
                    </ListGroup> : <div className="p-2 text-muted" >{placeholder}</div>}</div>
            </div>}
        </FocusableComponent>
        </div>;

};

export default AutocompleteSelect;