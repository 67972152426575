import React, {useContext, useEffect, useMemo, useState} from "react";
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import {Form, Row, Col, Button} from 'reactstrap';
import * as Yup from 'yup';
import AutoDismissAlert from "core/components/AutoDismissAlert";
import { userAPI } from 'directory/api/entities';
import { errorToText } from "core/helpers/error";
import User from "directory/api/models/User";
import { useUpdateUser } from "directory/hooks/user";
import { UForm } from "core/forms/types";
import InputGroup from "core/forms/groups/InputGroup";
import { useAction } from "core/hooks/action";
import { useCallback } from "core/api/equality";
import { ToasterContext } from "core/hooks/toaster";

export type BasicInformationFormProps = UForm<User>;

type FormikValues = {
    first_name : string;
    last_name : string;
    email : string;
    webpage : string;
    id : number;
};

const BasicInformationForm : React.FC<BasicInformationFormProps> = ({
    value : user, 
    onButtonsChange, 
    onSubmit, onUpdate,
    displayButtons = true}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const updateUser = useUpdateUser();
    const addToast = useContext(ToasterContext);

    const submitFunction :  () => void = () => submitAction.trigger();

    const formik = useFormik<FormikValues>({
        initialValues : {
            first_name : user.first_name,
            last_name : user.last_name,
            email : user.email,
            webpage : user.webpage,
            id : user.id
        },
        validationSchema : Yup.object({
            first_name: Yup.string()
            .required('Required'),
            last_name: Yup.string()
            .required('Required'),
            email: Yup.string().email()
                .required('Required'),
            webpage: Yup.string()
                .url()
        }),
        onSubmit : submitFunction
    });

    const {values, isValid, setSubmitting } = formik;

    const submitAction = useAction(useCallback(async () => {
        setSubmitting(false);
        try {
            const data : User =  await userAPI.update(values);
            addToast({
                type : 'success',
                title : 'User information updated',
                children : 'User information successfully updated'
            })
            updateUser(data);
            onSubmit && onSubmit(data);
            onUpdate && onUpdate(data);
            return data;
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return;
    }, [setSubmitting, values, onSubmit, onUpdate, updateUser, addToast]));

    const buttons = useMemo(() => {
        return [<Button key="save" color="primary" {...submitAction.buttonProps} disabled={!isValid || submitAction.loading}>Save</Button>];
    }, [submitAction, isValid]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange]);

    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

        
        <FormikProvider value={formik}>
        <Form tag={FkForm}>
            <Row>
                <Col md={6}>
                    <InputGroup label="First name" name="first_name" placeholder="John" />
                </Col>
                <Col md={6}>
                    <InputGroup label="Last name" name="last_name" placeholder="Doe" />
                </Col>
            </Row>
            <InputGroup label="Email address" name="email" placeholder="user@mail.edu" />
            <InputGroup label="Webpage" name="webpage" placeholder="http://mywebpage.com" />

            {displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
            </Row> : null}
        </Form>
    </FormikProvider>
    </>;

};

export default BasicInformationForm;