import React from 'react';


export type BaseGroupItemProps = {

    // Style of the item
    style? : object;

    // Reference to the parent node
    rootRef? : React.Ref<HTMLDivElement>;

    action? : boolean;

    active? : boolean;

    className? : string;

    children? : React.ReactNode;

} & React.HTMLAttributes<HTMLElement>;

const BaseGroupItem : React.FC<BaseGroupItemProps> = ({ style, rootRef, action = false, active = false, className = '', children, ...props }) => {

    return <div className={'list-group-item ' + className + (active ? ' active' : '') + (action ? ' list-group-item-action' : '')}
        style={style} ref={rootRef} {...props}>
        {children}
        </div>;
};

export default BaseGroupItem;