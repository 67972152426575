import { is } from "core/api/equality";
import Author from "publications/api/models/Author";
import { Language } from "languages/api/models";
import { MscSubject } from "msc/api/models";

export class BookCategory {

    id : number;
    name : string;
    description : string;

    constructor(json : any) {
        Object.assign(this, json);
    }

    is(cat : BookCategory) : boolean {
        return this.id === cat.id
            && this.name === cat.name
            && this.description === cat.description;
    }
};

export class Book {

    id : number;
    title : string;
    subtitle : string;
    user_authors: Author[];
    isbn : string;
    google_books_id : string;
    zbmath_id : number | null;
    categories : BookCategory[];
    description : string;
    publisher : string;
    year: number | null;
    month: number | null;
    thumbnail : string | null;
    page_count : number;
    language : Language | null;
    msc_subjects : MscSubject[];

    
    constructor(json : any) {
        json.user_authors = json.user_authors.map((author: any) => new Author(author));
        json.language = json.language === null ? null : new Language(json.language);
        json.categories = json.categories.map((cat : any) => new BookCategory(cat));
        json.msc_subjects = json.msc_subjects.map((subject : any) => new MscSubject(subject));
        Object.assign(this, json);
    }

    getPage() {
        return '/books/' + this.id;
    }

    is(book : Book) : boolean {
        return this.id === book.id
            && this.title === book.title
            && this.subtitle === book.subtitle
            && this.isbn === book.isbn
            && this.google_books_id === book.google_books_id
            && this.zbmath_id === book.zbmath_id
            && is(this.categories, book.categories)
            && this.description === book.description
            && this.publisher === book.publisher
            && this.year === book.year
            && this.month === book.month
            && this.thumbnail === book.thumbnail
            && this.page_count === book.page_count
            && this.language === book.language
            && is(this.msc_subjects, book.msc_subjects);
    }
};

