import UserList from "directory/lists/UserList";
import SideMainLayout from "core/components/layouts/SideMainLayout";
import React, { useState } from "react";
import UserSearch, { UserFilter } from "directory/forms/searches/UserSearch";

const UserListPage : React.FC<{}> = () => {

    const [filter, setFilter] = useState({
        search : '',
        msc_subjects : [],
        countries : []
    } as UserFilter);

    return <SideMainLayout sideBarTitle="Filter" sideBarBody={
        <nav>
            <UserSearch onChange={setFilter} />
        </nav>}>
            <UserList filter={filter} />
        </SideMainLayout>;
};

export default UserListPage;