import {Form, FormText, FormGroup, Label, Row, Col, Button} from 'reactstrap';
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import {affiliationAPI} from 'directory/api/entities';
import React, { useContext, useEffect, useState } from "react";
import * as Yup from 'yup';
import { yearMonthToDate } from 'core/helpers/date';
import DatePickerField from 'core/components/forms/DatePickerField';
import { DEFAULT_POSITION_LIST } from 'directory/config';
import { NewAffiliationType, UpdateAffiliationType } from 'directory/api/types';
import AutoDismissAlert from 'core/components/AutoDismissAlert';
import { errorToText } from 'core/helpers/error';
import User from "directory/api/models/User";
import Affiliation from "directory/api/models/Affiliation";
import { format as format_date } from 'date-fns';
import DepartmentCompletion from 'directory/completions/DepartmentCompletion';
import StringCompletion from 'core/completions/StringCompletion';
import { CUForm } from 'core/forms/types';
import { useCallback, useMemo } from 'core/api/equality';
import InstitutionSelectGroup from 'institutions/forms/groups/InstitutionSelectGroup';
import AutocompleteGroup from 'core/forms/groups/AutocompleteGroup';
import { useAction } from 'core/hooks/action';
import { useUpdateUser } from 'directory/hooks/user';
import { ToasterContext } from 'core/hooks/toaster';
import { listAppend, listUpdate } from 'core/helpers/list';
import ShortInstitution from 'institutions/api/models/ShortInstitution';
import { ObjectWithId } from 'core/api/BaseAPI';

export type AffiliationFormProps = CUForm<Affiliation> & {
    user : User;
};

type FormAffiliationType = {
    id? : number,
    institution : ShortInstitution | null,
    user_id : number,
    start_date : Date | null,
    end_date : Date | null,
    position : string,
    department : string
};

const DateConverter = {

    dateToTextDate : (date : Date | null) : string | null => {
        if(date === null) {
            return null;
        }
        return isNaN(date as unknown as number) ? null : format_date(date, 'yyyy-MM-dd');
    }

};

const AffiliationConverter = {

    affiliationToFormType : (affiliation:Affiliation) : FormAffiliationType => {
        return {
            ...affiliation,
            user_id : affiliation.getUserId()
        }
    },
    
    userIdToFormType : (userId : number) : FormAffiliationType => {
        return {
            id : undefined,
            institution : null,
            user_id : userId,
            start_date : null,
            end_date : null,
            position : '',
            department : ''
        }
    },
    
    formTypeToNewAffiliation : (values: FormAffiliationType) : NewAffiliationType => {
        return {
            ...values,
            institution_id : values.institution?.id || 0,
            start_date : DateConverter.dateToTextDate(values.start_date),
            end_date : DateConverter.dateToTextDate(values.end_date)
        };
    },
    
    formTypeToUpdateAffiliation : (values : FormAffiliationType & ObjectWithId<number>) : UpdateAffiliationType => {
        return {
            ...values,
            institution_id : values.institution?.id || 0,
            start_date : DateConverter.dateToTextDate(values.start_date),
            end_date : DateConverter.dateToTextDate(values.end_date)
        };
    }
};

const AffiliationForm : React.FC<AffiliationFormProps> = ({ 
    value : affiliation, user,
    onButtonsChange,
    onCreate, 
    onUpdate,
    onSubmit,
    displayButtons=true}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const updateUser = useUpdateUser();
    const addToast = useContext(ToasterContext);

    const editedAffiliation = useMemo(() => (affiliation === undefined ? 
        AffiliationConverter.userIdToFormType(user.id) : 
        AffiliationConverter.affiliationToFormType(affiliation)
    ), [user.id, affiliation]);

    const submitFunction :  () => void = () => submitAction.trigger();

    const formik = useFormik({
        initialValues : editedAffiliation,
        validationSchema : Yup.object({
            institution: Yup.object({
                id: Yup.number().required('Required')
            })
        }),
        onSubmit : submitFunction
    });

    const {values, setSubmitting } = formik;


    const handleCreate = useCallback(async (affiliation: NewAffiliationType) => {
        const newAffiliation = await affiliationAPI.create(affiliation);
        onCreate && onCreate(newAffiliation);
        onSubmit && onSubmit(newAffiliation);
        const newUser = user.clone();
        newUser.affiliations = listAppend(user.affiliations, newAffiliation);
        updateUser(newUser);
        addToast({
            type : 'success',
            title : 'Affiliation created',
            children : 'Affiliation successfully created'
        });
        return newAffiliation;
    }, [onCreate, onSubmit, updateUser, addToast, user]);

    const handleUpdate  = useCallback(async (affiliation : UpdateAffiliationType) => {
        const updatedAffiliation =  await affiliationAPI.update(affiliation);
        onUpdate && onUpdate(updatedAffiliation);
        onSubmit && onSubmit(updatedAffiliation);
        const newUser = user.clone();
        newUser.affiliations = listUpdate(user.affiliations, updatedAffiliation);
        updateUser(newUser);
        addToast({
            type : 'success',
            title : 'Affiliation updated',
            children : 'Affiliation successfully updated'
        });
        return updatedAffiliation;
    }, [onSubmit, onUpdate, updateUser, addToast, user]);

    const submitAction = useAction(useCallback(async () => {
        setSubmitting(false);
        try {
            if(values.id !== undefined) {
                return await handleUpdate(AffiliationConverter.formTypeToUpdateAffiliation(values as FormAffiliationType & ObjectWithId<number>));
            }
            return await handleCreate(AffiliationConverter.formTypeToNewAffiliation(values));
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return;
    }, [handleCreate, handleUpdate, values, setSubmitting]));

    const buttons = useMemo(() => {
        return [<Button key="save" color="primary" {...submitAction.buttonProps}>Save</Button>];
    }, [submitAction]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange]);

    const format = {
        year: 'numeric',
        month: '2-digit'
    };

    return <>
    <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

    <FormikProvider value={formik}>
        <Form tag={FkForm}>
            <InstitutionSelectGroup label="Institution" name="institution" />

            <AutocompleteGroup label="Department" name="department" 
                Completion={DepartmentCompletion}
                placeholder="Select a department" />

            <AutocompleteGroup label="Position" name="position"
                Completion={StringCompletion}
                emptyList={DEFAULT_POSITION_LIST}
                placeholder="Select a position" />

            <Row>
                <Col>
                    <FormGroup>
                    <Label for="start_date">Start date</Label>
                    <DatePickerField id="start_date" name="start_date" defaultValue={ new Date(1, 1, 1)}
                        valueFormat={{ month: 'short', year: 'numeric'}}
                        parse={yearMonthToDate}
                        valueEditFormat={format}
                        placeholder="MM/YYYY"
                        calendarProps={{ views: ["year", "decade", "century"] }} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                    <Label for="end">End date</Label>
                    <DatePickerField id="end_date" name="end_date" defaultValue={ new Date(1, 1, 1)}
                        valueFormat={{ month: 'short', year: 'numeric'}}
                        parse={yearMonthToDate}
                        valueEditFormat={format}
                        placeholder="MM/YYYY"
                        calendarProps={{ views: ["year", "decade", "century"] }} />
                        <FormText>Leave empty if current</FormText>
                    </FormGroup>
                </Col>
            </Row>

            { displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
            </Row> : null}
        </Form>
    </FormikProvider>
    </>;
};

export default AffiliationForm;