import SideMainLayout from "core/components/layouts/SideMainLayout";
import React, { useState } from "react";
import PublicationSearch, { PublicationFilter } from "publications/forms/searches/PublicationSearch";
import PublicationList from "publications/lists/PublicationList";

const PublicationListPage : React.FC<{}> = () => {

    const [filter, setFilter] = useState({
        search : '',
        msc_subjects : [],
        authors : [],
        serials : []
    } as PublicationFilter);

    return <SideMainLayout sideBarTitle="Filter" sideBarBody={
        <nav>
            <PublicationSearch onChange={setFilter} />
        </nav>}>
            <PublicationList filter={filter} />
        </SideMainLayout>;
};

export default PublicationListPage;