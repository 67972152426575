import { useCallback } from "core/api/equality";
import { useQueryClient } from "react-query";
import { fetchBookListKey } from "books/queryKeys";


export const useUpdateBooks = () => {
    const queryClient = useQueryClient();
    return useCallback(() => {
        queryClient.invalidateQueries({ queryKey : [fetchBookListKey] })
    }, [queryClient]);
};
