import React, { useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { X } from 'react-bootstrap-icons';
import { DeletableItemProps, SelectableItemProps } from 'core/items/types';
import { useCallback } from 'core/api/equality';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { useWidth } from 'core/hooks/width';
import { ShortSerial } from 'serials/api/models';


export type ShortSerialItemProps = 
    SelectableItemProps<ShortSerial> & 
    DeletableItemProps<ShortSerial>;

const ShortSerialItem : React.FC<ShortSerialItemProps> = ({
        value : serial, style, rootRef,
        selectable = false, onSelect,
        deletable = false, onDelete,
        action = false, active = false}) => {

    // Hack to be responsive
    const row = useRef(null);
    const width = useWidth(row);
    const small = width < 500;

    const handleRemove = useCallback(() => {
        onDelete && onDelete(serial);
    }, [onDelete, serial]);

    return <BaseGroupItem action={action || selectable} 
        active={active} style={style} rootRef={rootRef} 
        role={selectable ? 'button' : undefined}
        onClick={selectable ? (() => onSelect && onSelect(serial)) : undefined}>
        <div ref={row}>
        <Row className="small">
            <Col md={12 - (deletable ? (small ? 2 : 1) : 0)}>{serial.title}
            </Col>
            {deletable ? <Col md={small ? 2 : 1} className={'text-end' + small? 'p-0' : ''} role="button" onClick={handleRemove}><X width={32} /></Col> : null}
        </Row>
        </div>
    </BaseGroupItem>;
};

export default ShortSerialItem;