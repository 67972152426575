import React from 'react';
import { PanelProps } from 'core/pages/PanelPage';
import BasicForm from "core/components/forms/BasicForm";
import BookListForm from 'books/forms/lists/BookListForm';


const BooksPanel : React.FC<PanelProps> = ({user}) => {

    return <div>

        <BasicForm title="Books">
            <BookListForm value={undefined} />
        </BasicForm>          

    </div>;
}

export default BooksPanel;