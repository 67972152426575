import ViewEntityAPI from 'core/api/ViewEntityAPI';
import User from "directory/api/models/User";
import ShortUser from "directory/api/models/ShortUser";
import { NewUserType, UpdateUserType } from 'directory/api/types';

class UserAPI extends ViewEntityAPI<number, User, ShortUser, NewUserType, UpdateUserType> {
    
    
    async updateMscSubjects(id : number, subjectCodes : Array<string>) {
        const myToken = this.token();
        const headers = {
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.put(id + '/msc-subjects/', 
            {codes : subjectCodes}, { headers });
        return data;
    }

    async updateAvatar(id : number, file : File | null) : Promise<User> {
        const myToken = this.token();
        const form = this._toFormData({
            'avatar' : file
        });
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.put(id + '/avatar/', 
            form, { headers });
        return new User(data) as User;
    }

    async updatePassword(id : number, oldPassword : string, newPassword : string) {
        const myToken = this.token();
        const headers = {
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.put(id + '/update-password/', 
            { old_password: oldPassword, new_password: newPassword }, { headers });
        return data;
    }

    async getAbout(id: number) {
        const { data } = await this.axios.get(id + '/about/');
        return data;
    };

    async updateAbout(id: number, about : string) : Promise<string> {
        const myToken = this.token();
        const headers = {
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.put(id + '/about/', 
            { about }, { headers });
        return data;
    }
    
};

export default UserAPI;
