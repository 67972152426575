import { Routes, Route } from "react-router-dom";
import React from 'react';
import PublicationListPage from "publications/pages/PublicationListPage";

const PublicationsRoute : React.FC<{}> = () => {

    return <Routes>
        {/*<Route path="/:publicationId" element={<PublicationPage />} />*/}
        <Route path="/" element={<PublicationListPage />} />
    </Routes>;

};

export default PublicationsRoute;