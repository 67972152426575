import BookCategoryCompletion from 'books/completions/BookCategoryCompletion';
import BookCategoryItem from 'books/items/BookCategoryItem';
import ManyOneGroup from 'core/forms/groups/ManyOneGroup';
import { ManyOneFormGroupProps } from 'core/forms/groups/types';
import React from 'react';

export type BookCategoryManyOneGroupProps = ManyOneFormGroupProps;

const BookCategoryManyOneGroup : React.FC<BookCategoryManyOneGroupProps> = ({ 
    label='Categories', placeholder = 'Search for a category', name='categories',
    ...props
}) => {

    return <ManyOneGroup 
            {...props}
            label={label}
            placeholder={placeholder}
            name={name}
            Item={BookCategoryItem}
            Completion={BookCategoryCompletion} />;
};

export default BookCategoryManyOneGroup;