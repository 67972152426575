import React from 'react';
import { SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';


export type StringItemProps = 
    SelectableItemProps<string>;

const StringItem : React.FC<StringItemProps> = ({
    value, style, rootRef, 
    selectable = false, onSelect, 
    action = false, active = false}) => {

    return <BaseGroupItem active={active} action={action || selectable} 
        className="small" role="button" onClick={selectable ? () => onSelect && onSelect(value) : undefined} style={style} rootRef={rootRef}>
        {value}
    </BaseGroupItem>;
};

export default StringItem;