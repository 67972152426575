
import { useCallback } from 'core/api/equality';
import PaginatedComponent, { PaginationFooter, PaginationHeader } from 'core/components/PaginatedComponent';
import React from 'react';
import { playlistAPI } from 'videos/api/entities';
import { VideoFilter } from 'videos/forms/searches/VideoSearch';
import PlaylistItem from 'videos/items/PlaylistItem';
import { fetchPlaylistsKey } from 'videos/queryKeys';

export type VideoListProps  = {
    filter : VideoFilter;
};

const PlaylistList : React.FC<VideoListProps> = ({filter}) => {

    const query = useCallback((params : object) => {
        return playlistAPI.getAll(params)
    }, []);

    return <PaginatedComponent queryKey={[fetchPlaylistsKey]} query={query} defaultLimit={20}>
    {(values, pagination) => <>
      <PaginationHeader pagination={pagination} />{values.map(value => <PlaylistItem key={value.id} value={value} />)}
      <PaginationFooter pagination={pagination} />
    </>}
    </PaginatedComponent>;
}

export default PlaylistList;