import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useState } from 'react';
import Modal, { ModalState } from "core/components/Modal";
import AffiliationListForm from "directory/forms/lists/AffiliationListForm";
import User from "directory/api/models/User";
import { ButtonType } from "core/forms/types";

export type AffiliationListFormModalProps = {
    user : User;
    modal : ModalState
};

const AffiliationListFormModal : React.FC<AffiliationListFormModalProps>= ({
    user, modal}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);

    return <Modal state={modal} size="lg">
        <ModalHeader className="bg-light" toggle={modal.close}>Edit affiliations</ModalHeader>

        <ModalBody>
            <AffiliationListForm value={user} onButtonsChange={setButtons} displayButtons={false}  />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="secondary" onClick={modal.close}>Close</Button>
        </ModalFooter>
    </Modal>
};

export default AffiliationListFormModal;