import { Form } from "reactstrap";
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import React, { useEffect } from "react";
import { BaseSearchProps } from "core/forms/searches/types";
import InputGroup from "core/forms/groups/InputGroup";
import { Playlist } from "videos/api/models/Playlist";

export type VideoFilter = {
    search : string;
    playlists : Playlist[];
};

export type VideoSearchProps = BaseSearchProps<VideoFilter>;

const VideoSearch : React.FC<VideoSearchProps> = ({
    onChange
}) => {

    const formik = useFormik<VideoFilter>({
        initialValues : {
            search : '',
            playlists : []
        },
        onSubmit : () => {}
    });

    const { values } = formik;

    useEffect(() => {
        onChange && onChange(values)
    }, [values, onChange]);


    return <FormikProvider value={formik}>
        <Form tag={FkForm} className="mt-3">

            <InputGroup className="small" bsSize="sm" label="Search" name="search" placeholder="Filter..." />

        </Form>

    </FormikProvider>;
};

export default VideoSearch;