import React, {useState} from "react";
import {Formik, Field as FkField, Form as FkForm} from 'formik';
import {Form, FormGroup, Input, Label, FormFeedback} from 'reactstrap';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authSlice from "store/slices/auth";
import * as AuthAPI from 'auth/api';
import AutoDismissAlert from "core/components/AutoDismissAlert";
import { errorToText } from "core/helpers/error";

const LoginForm = () => {

    const [loading, setLoading] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authenticate = async (login: string, password: string) => {
        const data = await AuthAPI.login(login, password);
        dispatch(
            authSlice.actions.setAuthTokens({
                token: data.token
            })
        );
        dispatch(
            authSlice.actions.setUser(data.user)
        );
        navigate('/');
    };

    const handleLogin = (login: string, password: string) => {
        setLoading(true);
        authenticate(login, password).then(() => {
            setLoading(false);
        }).catch((err) => {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
            setLoading(false);
        });
    };

    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

        <Formik
    initialValues = {{
        login : '',
        password : ''
    }}
    validationSchema={Yup.object({
        login: Yup.string()
          .required('Required'),
        password: Yup.string()
            .required('Required'),
    })}
    onSubmit={(values, { setSubmitting } ) => {
        handleLogin(values.login, values.password);
        setSubmitting(false);
    }}>
    {({errors, touched, isValid }) => (
        <Form tag={FkForm}>
            <FormGroup className="form-floating">
                <Input id="login" tag={FkField} name="login" 
                    type="text" 
                    placeholder="user@mail.edu" 
                    invalid={touched.login && 'login' in errors} />
                <Label for="login">Email address</Label>
                <FormFeedback component="small" className="text-danger">{touched.login && errors.login}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-floating">
                <Input id="password" tag={FkField} name="password" 
                    type="password" 
                    placeholder="password" 
                    invalid={touched.password && 'password' in errors} />
                <Label for="password">Password</Label>
                <FormFeedback component="small" className="text-danger">{touched.password && errors.password}</FormFeedback>
            </FormGroup>
            <button type="submit" disabled={!isValid || loading} className="btn btn-primary w-100">Log In</button>
        </Form>
    )}
    </Formik>
    </>;

};

export default LoginForm;