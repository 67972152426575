
export class MscSubject {

    id : string;
    code : string;
    text : string;
    description : string;

    constructor(json : any) {
        Object.assign(this, json);
    }

    is(subject : MscSubject) : boolean {
        return this.id === subject.id
            && this.code === subject.code
            && this.text === subject.text
            && this.description === subject.description;
    }
};