import GenericErrorPage from "core/pages/errors/GenericErrorPage";
import React from 'react';

const NotFoundErrorPage = () => {

    const title = 'Not found !';
    const message = 'The page you tried to access does not exist';

    return <GenericErrorPage title={title} message={message} />;
        
};

export default NotFoundErrorPage;