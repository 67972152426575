import { CompletionProps } from 'core/completions/types';
import React from 'react';
import DefaultCompletion from "core/completions/DefaultCompletion";
import { ShortSerial } from "serials/api/models";
import ShortSerialItem from "serials/items/ShortSerialItem";
import { fetchSerialListKey } from 'serials/queryKeys';
import { serialAPI } from 'serials/api/entities';

export type ShortSerialCompletionProps = CompletionProps<ShortSerial>;

const ShortSerialCompletion : React.FC<ShortSerialCompletionProps> = (props) => {
    return <DefaultCompletion 
        Item={ShortSerialItem} 
        queryKey={fetchSerialListKey}
        query={serialAPI.getShortAll.bind(serialAPI)}
        {...props} />
};


export default ShortSerialCompletion;