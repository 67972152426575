
/*
    Set first character uppercase
*/
export const ucfirst = (s : string) : string => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const withEndDot = (s : string) : string => {
    if(!s)
        return '';
    if(s.charAt(-1) in ['.', '!', '?', ';', ':'])
        return s;
    return s  + '.';
}