import { FormGroupProps } from 'core/forms/groups/types';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { useElementSize } from "usehooks-ts";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import { X } from 'react-bootstrap-icons';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

export type PdfFileGroupProps = FormGroupProps & {
    thumbnail : string;
    maxWidth? : string;
    validate? : (values : any, props : any) => string;
}

/*
Shorthand for FormGroup, Label, Input, FormFeedback
*/

const PdfFileGroup : React.FC<PdfFileGroupProps> = ({
    label, placeholder = "Drag 'n' drop a PDF here, or click to select files", 
    validate, maxWidth,
    thumbnail,
    name, className='', bsSize
}) => {


    
    const { touched, errors, values, setFieldValue } = useFormikContext<object>();
    const [divRef, { width  } ] = useElementSize<HTMLDivElement>();
    const file = values[name] as File | null | string;

    const {getRootProps, getInputProps} = useDropzone({
        onDrop : (acceptedFiles : File[]) => {
            setFieldValue(name, acceptedFiles[0])
        },
        accept: {
            'application/pdf': ['.pdf' ]
          }
    });

    const removeLink = useMemo( () => {
        return <p><span className="a small" onClick={() => setFieldValue(name, null)}><X color="red" /> Remove</span></p>;
    }, [setFieldValue, name]);

    const content = useMemo( () => {
        if(file instanceof File) {
            return <><Document file={file}>
                <Page width={width} pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
            </Document>
            {removeLink}
            </>;
            
        }
        if(file === null) {
            return <div {...getRootProps({className:'dropzone mt-3', role: 'button', style:{ minHeight: '100px' }})}>
                <input {...getInputProps()} />
                <p><img src="/images/upload.png" className="w-100 opacity-50" style={{maxWidth: '100px'}} alt="Upload" /></p>
                <p>{placeholder}</p>
            </div>;
        }
        else {
            return <>
                <img src={thumbnail} className="w-100" style={{maxWidth: maxWidth}} alt="Slides" />
                {removeLink}
            </>;
        }
        
    }, [width, file, getInputProps, getRootProps, placeholder, thumbnail, removeLink, maxWidth]);

    return <FormGroup className={className}>
        <Label for={name}>{label}</Label>
        <div ref={divRef} className="w-100"></div>
        <div className="text-center">{content}</div>        
        <FormFeedback component="small" className="text-danger">{touched[name] && errors[name]}</FormFeedback>
    </FormGroup>
}

export default PdfFileGroup;