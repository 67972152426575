import React from 'react';
import { PanelProps } from 'core/pages/PanelPage';
import BasicForm from "core/components/forms/BasicForm";
import PublicationListForm from 'publications/forms/PublicationListForm';
import SuggestedPublicationListForm from 'publications/forms/SuggestedPublicationListForm';


const PublicationsPanel : React.FC<PanelProps> = ({user}) => {

    return <div>

        <BasicForm title="Suggested Publications">
            <SuggestedPublicationListForm user={user} />
        </BasicForm>       

        <BasicForm title="Publications">
            <PublicationListForm value={user} />
        </BasicForm>          

    </div>;
}

export default PublicationsPanel;