import User from "directory/api/models/User";
import ShortUser from "directory/api/models/ShortUser";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "store";
import authSlice from "store/slices/auth";
import { fetchUserKey } from "directory/queryKeys";
import { useCallback } from "core/api/equality";

export const useAuthUser = () : ShortUser | undefined => {
    const auth = useSelector( (state: AuthState) => state.auth)
    return auth.user ? new ShortUser(auth.user) : undefined;
}

/*
    Hook to update user
*/
export const useUpdateUser = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    return useCallback((user:User) => {
        dispatch(
            authSlice.actions.setUser(user.toShortUser().toJSON())
        );
        queryClient.setQueryData([fetchUserKey, user.id], user);
    }, [queryClient, dispatch])
};