import { userAPI } from 'directory/api/entities';
import ShortUser from "directory/api/models/ShortUser";
import { CompletionProps } from 'core/completions/types';
import ShortUserItem from 'directory/items/ShortUserItem';
import React from 'react';
import { useQuery } from 'react-query';
import { ListGroup } from 'reactstrap';
import { fetchShortUsersKey } from 'directory/queryKeys';

export type ShortUserCompletionProps = CompletionProps<ShortUser>;

const ShortUserCompletion : React.FC<ShortUserCompletionProps> = ({text, onSelect, emptyList, selectedValue, limit = 5}) => {

    const { data : users, error, isError, isLoading } = 
        useQuery([fetchShortUsersKey, text], () => userAPI.getShortAll({
            'search' : text,
            'limit' : limit
        }), {
            keepPreviousData : true
        } );

    if(text === '') {

        if(emptyList === undefined) {
            return <p className="p-2 m-0 small text-muted">Type a name to autocomplete...</p>;
        }

        return <ListGroup>
            {emptyList.map(user => <ShortUserItem key={user.id} value={user} active={(selectedValue !== undefined) && selectedValue.id === user.id} onSelect={onSelect} />)}
        </ListGroup>
    }

    if(isError)
        throw error;

    if(isLoading || !users) {
        return <p className="p-2 m-0 small text-muted">Loading...</p>;
    }

    if(users.count === 0) {
        return <p className="p-2 m-0 small text-muted">No matching user</p>
    }

    return <ListGroup>
        {users.results.map(user => <ShortUserItem 
            key={user.id} value={user} 
            active={(selectedValue !== undefined) && selectedValue.id === user.id} 
            selectable={true}
            onSelect={onSelect} />)}
    </ListGroup>
};

export default ShortUserCompletion;