import MscSubjectCompletion from 'msc/completions/MscSubjectCompletion';
import ManyOneGroup from 'core/forms/groups/ManyOneGroup';
import { ManyOneFormGroupProps } from 'core/forms/groups/types';
import MscSubjectItem from 'msc/items/MscSubjectItem';
import React from 'react';

export type MscSubjectManyOneGroupProps = ManyOneFormGroupProps;

const MscSubjectManyOneGroup : React.FC<MscSubjectManyOneGroupProps> = ({ 
    label='MSC Subjects', placeholder = 'Search for a subject', name='msc_subjects',
    ...props
}) => {

    return <ManyOneGroup 
            {...props}
            label={label}
            placeholder={placeholder}
            name={name}
            Item={MscSubjectItem}
            Completion={MscSubjectCompletion} />;
};

export default MscSubjectManyOneGroup;