import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useContext, useState } from 'react';
import Modal from "core/components/Modal";
import { MemoFC, useCallback } from "core/api/equality";
import { CUDFormModal } from "core/modals/types";
import { ButtonType } from "core/forms/types";
import { ToasterContext } from "core/hooks/toaster";
import ConfirmationButton from "core/components/forms/ConfirmationButton";
import { Book } from "books/api/models";
import BookForm from "books/forms/BookForm";
import { bookAPI } from "books/api/entities";
import { useUpdateBooks } from "books/hooks/book";

export type BookFormModalProps = CUDFormModal<Book>;

const BookFormModal : React.FC<BookFormModalProps> = MemoFC(({
    value,
    modal,
    onSubmit, onCreate, onUpdate, onDelete}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);
    const addToast = useContext(ToasterContext);
    const updateBooks = useUpdateBooks();


    const handleDelete = useCallback(async () => {
        if(value=== undefined) {
            return;
        }
        const data =  await bookAPI.delete(value.id);
        modal.close();
        onDelete && onDelete(value);
        updateBooks();
        addToast({
            type : 'danger',
            title : 'Book deleted',
            children : 'You deleted the book ' + value.title
        })
        return data;
    }, [onDelete, modal, addToast, value, updateBooks]);

    const handleSubmit = useCallback(async (book : Book) => {
        modal.close();
        return onSubmit && onSubmit(book);
    }, [onSubmit, modal]);

    return <Modal state={modal} size="lg">
        <ModalHeader className="bg-light" toggle={modal.close}>{value !== undefined  ? "Edit book" : "New book"}</ModalHeader>

        <ModalBody>

            <BookForm onButtonsChange={setButtons} value={value} displayButtons={false}
                onSubmit={handleSubmit} onCreate={onCreate} onUpdate={onUpdate} />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            {value !== undefined ? <ConfirmationButton size="md" color="danger" onClick={handleDelete}
                confirmQuestion={<>
                    <p>Are you sure you you want to delete this book?</p>
                </>}
            >Delete</ConfirmationButton> : null}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
});

export default BookFormModal;