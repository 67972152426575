import { is } from "core/api/equality";
import { City } from "geonames/api/models";
import { MscSubject } from "msc/api/models";
import { ShortVideo } from "videos/api/models/ShortVideo";
import { _hyperlinkToId } from "../../../core/api/models";


export default class Talk {
    id: number;
    user: string;
    date: Date | null;
    title: string;
    abstract: string;
    event_name: string;
    event_url: string;
    city: City | null;
    video : ShortVideo | null;
    online: boolean;
    slides: string | null;
    thumbnail: string | null;
    msc_subjects: MscSubject[];

    constructor(json: any) {
        json.date = json.date === null ? null : new Date(json.date);
        json.video = json.video === null ? null : new ShortVideo(json.video);
        json.msc_subjects = json.msc_subjects.map((subject : any) => new MscSubject(subject));
        Object.assign(this, json);
    }

    is(talk: Talk): boolean {
        return this.id === talk.id
            && this.user === talk.user
            && ((this.date === null && talk.date === null)
                || (this.date !== null
                    && talk.date !== null
                    && this.date.getTime() === talk.date.getTime()))
            && this.title === talk.title
            && this.abstract === talk.abstract
            && this.event_name === talk.event_name
            && this.event_url === talk.event_url
            && is(this.city, talk.city)
            && is(this.video, talk.video)
            && this.online === talk.online
            && this.slides === talk.slides
            && this.thumbnail === talk.thumbnail
            && is(this.msc_subjects, talk.msc_subjects);
    }

    getUserId(): number {
        return _hyperlinkToId(this.user) || 0;
    }

}
