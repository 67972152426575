import { Language } from "languages/api/models";


export class ShortVideo {

    id : string;
    title : string;
    description : string;
    publication_time : Date;
    thumbnail : string;
    language : Language;
    
    constructor(json : any) {
        json.publication_time = new Date(json.publication_time);
        json.language = new Language(json.language);
        Object.assign(this, json);
    }

    getPage() {
        return '/videos/' + this.id;
    }

    is(video : ShortVideo) : boolean {
        return this.id === video.id
            && this.title === video.title
            && this.description === video.description
            && this.thumbnail === video.thumbnail
            && this.language.is(video.language)
            && this.publication_time.getTime() === video.publication_time.getTime();
    }
};
