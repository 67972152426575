import { CompletionProps } from 'core/completions/types';
import React from 'react';
import { useQuery } from 'react-query';
import { ListGroup } from 'reactstrap';
import { Language } from 'languages/api/models';
import LanguageItem from 'languages/items/LanguageItem';
import { languagesAPI } from 'languages/api/entities';
import { fetchLanguageListKey } from 'languages/queryKeys';

export type LanguageCompletionProps = CompletionProps<Language>;

const LanguageCompletion : React.FC<LanguageCompletionProps> = ({
    text, onSelect, 
    emptyList,
    selectedValue, limit = 5
}) => {

    const queryHandler = () => {
        return languagesAPI.getAll({
            search : text,
            limit: limit
        });
    };
    

    const { data, isError, isLoading } = useQuery(
        [fetchLanguageListKey, text], queryHandler, { keepPreviousData : true } );

    if(text === '') {

        if(emptyList === undefined) {
            return <p className="p-2 m-0 small text-muted">Type a name to autocomplete...</p>;
        }

        return <ListGroup>
            {emptyList.map(language => <LanguageItem 
                key={language.id} value={language} 
                active={(selectedValue !== undefined) && selectedValue.is(language)} 
                selectable={true}
                onSelect={onSelect} />)}
        </ListGroup>
    }

    if(text === '' || isError || isLoading) {
        return null;
    }

    if(!data) {
        return null;
    }

    const languages = data.results;

    return <ListGroup>
        {languages.map(language => (
            <LanguageItem key={language.id} value={language} 
                active={(selectedValue !== undefined) && selectedValue.is(language)}
                selectable={true}
                onSelect={onSelect} />
        ))}
    </ListGroup>;

};

export default LanguageCompletion;