import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useState } from 'react';
import Modal, { ModalState } from "core/components/Modal";
import ResearchInterestListForm from "directory/forms/lists/ResearchInterestListForm";
import User from "directory/api/models/User";
import { ButtonType } from "core/forms/types";

export type ResearchInterestListFormModalProps = {
    user : User;
    onSubmit?(user:User):void;
    modal : ModalState
};

const ResearchInterestListFormModal : React.FC<ResearchInterestListFormModalProps>= ({
    user, modal, onSubmit}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);

    return <Modal state={modal} size="md">
        <ModalHeader className="bg-light" toggle={modal.close}>Edit research interests</ModalHeader>

        <ModalBody>
            <ResearchInterestListForm value={user} displayButtons={false} onButtonsChange={setButtons}  />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default ResearchInterestListFormModal;