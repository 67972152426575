import { NewPublicationType } from "publications/api/types";
import BaseGroupItem from 'core/items/BaseGroupItem';
import { CheckableItemProps } from 'core/items/types';
import React, { useCallback } from 'react';
import Latex from 'react-latex';
import { Col, Input, Row } from 'reactstrap';


export type NewPublicationItemProps = CheckableItemProps<NewPublicationType>;

const NewPublicationItem : React.FC<NewPublicationItemProps> = ({
    value,
    checkable = false,
    checked = false,
    onCheckChange
}) => {

    const handleCheckChange = useCallback(() => {
        onCheckChange && onCheckChange(value, !checked)
    }, [value, checked, onCheckChange]);

    return <BaseGroupItem className="small">
        <Row>
            {checkable ? <Col md={1}>
                <Input type="checkbox" checked={checked} onChange={handleCheckChange} />
            </Col> : null}
            <Col md={12 - (checkable ? 1 : 0)}>
                <Latex>{value.title || value.book_title}</Latex>
            </Col>
        </Row>
    </BaseGroupItem>
};

export default NewPublicationItem;