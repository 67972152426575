import { institutionAPI } from "institutions/api/entities";
import { CompletionProps } from 'core/completions/types';
import React from 'react';
import { fetchInstitutionListKey } from "institutions/queryKeys";
import ShortInstitution from "institutions/api/models/ShortInstitution";
import ShortInstitutionItem from "institutions/items/ShortInstitutionItem";
import DefaultCompletion from "core/completions/DefaultCompletion";

export type ShortInstitutionCompletionProps = CompletionProps<ShortInstitution>;

const ShortInstitutionCompletion : React.FC<ShortInstitutionCompletionProps> = (props) => {
    return <DefaultCompletion 
        Item={ShortInstitutionItem} 
        queryKey={fetchInstitutionListKey}
        query={institutionAPI.getShortAll.bind(institutionAPI)}
        {...props} />
};

export default ShortInstitutionCompletion;
