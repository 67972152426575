import CountryCompletion from 'geonames/completions/CountryCompletion';
import ManyOneGroup from 'core/forms/groups/ManyOneGroup';
import { ManyOneFormGroupProps } from 'core/forms/groups/types';
import CountryItem from 'geonames/items/CountryItem';
import React from 'react';

export type CountryManyOneGroupProps = ManyOneFormGroupProps;

const CountryManyOneGroup : React.FC<CountryManyOneGroupProps> = ({ 
    label='Countries', placeholder = 'Search for a country', name='countries',
    ...props
}) => {

    return <ManyOneGroup 
            {...props}
            label={label}
            placeholder={placeholder}
            name={name}
            Item={CountryItem}
            Completion={CountryCompletion} />;
};

export default CountryManyOneGroup;