import { Tooltip, TooltipContent, TooltipTrigger } from "core/components/Tooltip";
import ShortInstitution from "institutions/api/models/ShortInstitution";
import ShortInstitutionCard from "institutions/cards/ShortInstitutionCard";
import React from 'react';

class ShortInstitutionRenderer {

    institution : ShortInstitution;

    constructor(institution: ShortInstitution) {
        this.institution = institution;
    }

    getNameWithTip() : React.ReactNode {


        const institution = this.institution;
        const handleClick = () => {
            window.location.href = institution.website 
        }

        return <Tooltip placement="bottom">
                <TooltipTrigger asChild={true}><span className="a" onClick={handleClick}>{this.institution.getName()}</span></TooltipTrigger>
                <TooltipContent className="p-0 m-0"><ShortInstitutionCard value={this.institution} /></TooltipContent>
            </Tooltip>;
    }
};

export default ShortInstitutionRenderer;