
import React, { useState } from 'react';
import { useCallback } from 'core/api/equality';
import { useNavigate } from 'react-router-dom';
import PaginatedComponent, { PaginationFooter, PaginationHeader } from 'core/components/PaginatedComponent';
import { Button, Col, Table } from 'reactstrap';
import { Grid, Grid3x3Gap, ListColumns } from 'react-bootstrap-icons';
import { VideoFilter } from 'videos/forms/searches/VideoSearch';
import { videoAPI } from 'videos/api/entities';
import { fetchVideosKey } from 'videos/queryKeys';
import VideoItem from 'videos/items/VideoItem';
import VideoCard from 'videos/cards/VideoCard';
import { Video } from 'videos/api/models/Video';



export type VideoListProps  = {
    filter : VideoFilter;
};

export const enum ViewStyle {
  TABLE = 'TABLE',
  CARD = 'CARD',
  BIG_CARD = 'BIG_CARD'
};

const VideoList : React.FC<VideoListProps> = ({filter}) => {

    const navigate = useNavigate();
    const [viewStyle, setViewStyle] = useState(ViewStyle.CARD);
    const query = useCallback((params : object) => {
        return videoAPI.getAll({
            ...params,
            search : filter.search,
            playlist_id : filter.playlists.map(playlist => playlist.id).join(',')
        })
    }, [filter.search, filter.playlists]);

    const handleSelect = useCallback((video:Video) => {
        navigate(video.getPage());
    }, [navigate]);

    const content = useCallback((values : Video[]) => {
      switch(viewStyle) {
        case ViewStyle.TABLE :
          return <Table striped hover className="border bg-white">
            <thead>
                    <tr>
                      <th>Videos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.length <= 0? (
                    <tr>
                      <td align="center">
                        <b>No video found.</b>
                      </td>
                    </tr>
                    ) : (
                      values.map(value => (
                      <tr key={value.id}><td><VideoItem value={value}  selectable={true} onSelect={handleSelect}/></td></tr>
                      ))
                    )}
                  </tbody>
              </Table>;
        case ViewStyle.CARD :
              return <div className="row row-cols-2 row-cols-lg-4">
              {values.map(value => (
                <Col key={value.id} className="mb-3 btn"><VideoCard value={value} selectable={true} onSelect={handleSelect} /></Col>
               ))}
               </div>;
        case ViewStyle.BIG_CARD :
              return <div className="row row-cols-2 row-cols-lg-3">
              {values.map(value => (
                <Col key={value.id} className="mb-3 btn"><VideoCard value={value} selectable={true} onSelect={handleSelect} /></Col>
              ))}
              </div>;
      }
    }, [handleSelect, viewStyle])

  return <PaginatedComponent queryKey={[fetchVideosKey, filter]} query={query} defaultLimit={20}>
  {(values, pagination) => <>
    <PaginationHeader pagination={pagination}>
      <Button outline className="border ms-2" onClick={() => setViewStyle(ViewStyle.CARD)} active={viewStyle === ViewStyle.CARD}>
              <Grid3x3Gap /></Button>
          <Button outline className="border ms-2" onClick={() => setViewStyle(ViewStyle.BIG_CARD)} active={viewStyle === ViewStyle.BIG_CARD}>
              <Grid /></Button>
          <Button outline className="border ms-2"  onClick={() => setViewStyle(ViewStyle.TABLE)} active={viewStyle === ViewStyle.TABLE}>
              <ListColumns /></Button>
    </PaginationHeader>{content(values)}<PaginationFooter pagination={pagination} />
  </>}
  </PaginatedComponent>;


   
};

export default VideoList;
  