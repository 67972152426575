import { useCallback } from 'core/api/equality';
import { AutocompleteContext } from 'core/components/forms/Autocomplete';
import { useModalState } from 'core/components/Modal';
import Institution from "institutions/api/models/Institution";
import AutocompleteSelectGroup from 'core/forms/groups/AutocompleteSelectGroup';
import { AutocompleteSelectFormGroupProps } from 'core/forms/groups/types';
import InstitutionFormModal from 'institutions/modals/InstitutionFormModal';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import ShortVideoItem from 'videos/items/ShortVideoItem';
import ShortVideoCompletion from 'videos/completions/ShortVideoCompletion';

export type ShortVideoSelectGroupProps = AutocompleteSelectFormGroupProps;

const ShortVideoSelectGroup : React.FC<ShortVideoSelectGroupProps> = ({ 
    label = 'Video', placeholder = 'Select a video', name = 'video',
    className, addable=false, bsSize
}) => {

    const { setFieldValue } = useFormikContext<object>();
    const institutionModal = useModalState();
    const [institutionName, setInstitutionName] = useState('');

    const handleAdd = useCallback((value : string, ctx : AutocompleteContext<string> ) => {
        setInstitutionName(value);
        ctx.setValue('');
        institutionModal.open();
    }, [institutionModal]);

    const handleSelectInstitution = React.useCallback((institution : Institution) => {
        setFieldValue(name, institution);
    }, [name, setFieldValue]);

    return <>
        <InstitutionFormModal modal={institutionModal} onSubmit={handleSelectInstitution} defaultName={institutionName} />
        <AutocompleteSelectGroup 
            bsSize={bsSize}
            className={className}
            label={label}
            placeholder={placeholder}
            name={name}
            addable={addable}
            onAdd={handleAdd}
            Item={ShortVideoItem}
            Completion={ShortVideoCompletion} />
        </>;
};

export default ShortVideoSelectGroup;