import React from 'react';
import ManyOneGroup from "core/forms/groups/ManyOneGroup";
import ShortUserCompletion from 'directory/completions/ShortUserCompletion';
import ShortUserItem from 'directory/items/ShortUserItem';
import { ManyOneFormGroupProps } from 'core/forms/groups/types';


export type ShortUserManyOneGroupProps = ManyOneFormGroupProps;

const ShortUserManyOneGroup : React.FC<ShortUserManyOneGroupProps> = ({ 
    label='Useres', placeholder = 'Search for a user', name='users',
    ...props
}) => {

    return <ManyOneGroup 
            {...props}
            label={label}
            placeholder={placeholder}
            name={name}
            Item={ShortUserItem}
            Completion={ShortUserCompletion} />;
};

export default ShortUserManyOneGroup;