import React, { useEffect, useMemo, useState } from "react";
import {Button, Col, Row, Table} from 'reactstrap';
import { useModalState } from "core/components/Modal";
import { useAction } from "core/hooks/action";
import User from "directory/api/models/User";
import Talk from "talks/api/models/Talk";
import { useQuery } from "react-query";
import { talkAPI } from "talks/api/entities";
import TalkItem from "talks/items/TalkItem";
import TalkFormModal from "talks/modals/TalkFormModal";
import { useCallback } from "core/api/equality";
import { ZeroFormProps } from "core/forms/types";
import { fetchTalksKey } from "talks/queryKeys";

export type TalkListFormProps = ZeroFormProps<User>;

const TalkListForm : React.FC<TalkListFormProps> = ({
    value : user,
    displayButtons=true,
    onButtonsChange
}) => {

    const [refetchInterval, setRefetchInterval] = useState(1000 as number | undefined);
    const { data : talks, isLoading, isError } = useQuery([fetchTalksKey, user.id], () => talkAPI.getAll({
        user_id : user.id,
        limit : 50
        }), {
        refetchInterval
    });
    const [editedTalk, setEditedTalk] = useState(undefined as Talk | undefined);
    const modal = useModalState();

    useEffect(() => {
        if(!talks) return;
        const allThumbs =  talks.results.reduce((allThumbs, talk) => allThumbs && (talk.slides === null || talk.thumbnail !== null), true);
        if(allThumbs) {
            setRefetchInterval(undefined)
        }
    }, [talks])
    
    const createHandler = useAction(async () => {
        setEditedTalk(undefined);
        modal.open();
        return null;
    });


    const editPublication = useCallback((talk : Talk) => {
        setEditedTalk(talk);
        modal.open();
    }, [modal]);

    const handleCreateOrUpdate = useCallback((talk : Talk) => {
        setRefetchInterval(1000);
    }, []);

    const buttons = useMemo(() => {
        return [
            <Button key="new" color="primary" {...createHandler.buttonProps}>New talk</Button>
        ];
    }, [createHandler]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [onButtonsChange, buttons]);

    if(isLoading || isError || talks === undefined)
        return null;
  
    return <>
        <TalkFormModal modal={modal}
            user={user} value={editedTalk} onCreate={handleCreateOrUpdate} onUpdate={handleCreateOrUpdate} />

        {displayButtons ? <Row className="row-cols-lg-auto  mb-3">
            <Col className="ms-auto">
                {buttons}
            </Col>
        </Row> : null}

        <Table striped hover className="border bg-white">
            <thead>
            <tr>
                <th>Talks</th>
            </tr>
            </thead>
            <tbody>
            {talks.count === 0 ? (<tr><td className="text-center small">You have no talk yet</td></tr>) :
            talks.results.map(talk => (
                <tr key={talk.id} role="button" onClick={() => editPublication(talk)}>
                    <td><TalkItem value={talk} /></td>
                </tr>
            ))}
            </tbody>
        </Table>
    </>;

};

export default TalkListForm;