import {Form, FormGroup, Label, FormFeedback, Row, Col, Button, Nav, NavItem, TabContent, TabPane, NavLink, Alert} from 'reactstrap';
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import { publicationAPI } from "publications/api/entities";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as Yup from 'yup';
import { NewPublicationType, PublicationTypeField, UpdatePublicationType } from "publications/api/types";
import AutoDismissAlert from 'core/components/AutoDismissAlert';
import { errorToText } from 'core/helpers/error';
import User from "directory/api/models/User";
import Publication from "publications/api/models/Publication";
import Author from "publications/api/models/Author";
import ShortUser from "directory/api/models/ShortUser";
import InputGroup from 'core/forms/groups/InputGroup';
import { BibtexSchema, FieldListType } from 'publications/helpers/bibtex';
import ObjectManyOneField from 'core/components/forms/ObjectManyOneField';
import ShortUserCompletion from 'directory/completions/ShortUserCompletion';
import AuthorItem from 'publications/items/AuthorItem';
import { CUForm } from 'core/forms/types';
import { useAction } from 'core/hooks/action';
import { useUpdatePublications } from 'publications/hooks/publication';
import { ToasterContext } from 'core/hooks/toaster';
import { MscSubject } from 'msc/api/models';
import MscSubjectManyOneGroup from 'msc/forms/groups/MscSubjectManyOneGroup';
import PdfFileGroup from 'core/forms/groups/PdfFileGroup';
import { ObjectWithId } from 'core/api/BaseAPI';
import { InfoCircleFill } from 'react-bootstrap-icons';
import CheckGroup from 'core/forms/groups/CheckGroup';

export type PublicationFormProps = CUForm<Publication> & {
    user : User;
}


// In this type, number | string is a subtitute for number | null
// Since input fields don't accept null values
// We therefore convert null into the empty string
// And we convert back every string to the null value 
type PublicationFormType = {
    id? : number,
    authors : Author[];
    keywords : string[];
    type : PublicationTypeField;
    title : string;
    year : number | string;
    month : number | string;
    journal : string;
    book_title : string;
    publisher : string;
    institution : string;
    volume : number | string;
    number : number | string;
    pages : string;
    note : string;
    url : string;
    doi : string;
    abstract : string;
    isbn : string;
    pdf : File | null | string;
    msc_subjects : MscSubject[];
    zbmath_id : string | null;
    arxiv_id : string | null;
    arxiv_loaded : boolean;
    zbmath_loaded : boolean;
};

const PublicationConverter = {

    undefinedToFormType : () : PublicationFormType => {
        return {
            authors : [],
            keywords : [],
            type : 'ARTICLE',
            title : '',
            year : '',
            month : '',
            journal : '',
            book_title : '',
            publisher : '',
            institution : '',
            volume :  '',
            number : '',
            pages : '',
            note : '',
            url : '',
            doi : '',
            abstract : '',
            isbn : '',
            pdf : null,
            msc_subjects : [],
            zbmath_id : null,
            arxiv_id : null,
            arxiv_loaded : false,
            zbmath_loaded : false
       }; 
    },

    publicationToFormType : (publication:Publication) : PublicationFormType => {
        return {
            ...publication,
            authors : publication.user_authors,
            year : publication.year === null ? '' : publication.year,
            month : publication.month === null ? '' : publication.month,
            volume : publication.volume === null ? '' : publication.volume,
            number : publication.number === null ? '' : publication.number
        };
    },

    formTypeToNewPublication : (values:PublicationFormType) : NewPublicationType => {
        return {
            ...values,
            authors : values.authors.map(author => ({
                name : author.name,
                user_id : author.user === null ? null : author.user.id
            })),
            pdf : typeof values.pdf === 'string' ? undefined : values.pdf,
            serial_id : undefined,
            msc_subject_codes : values.msc_subjects.map(subject => subject.code),
            year : typeof values.year === 'string' ? null : values.year,
            month : typeof values.month === 'string' ? null : values.month,
            volume : typeof values.volume === 'string' ? null : values.volume,
            number : typeof values.number === 'string' ? null : values.number
        };
    },

    formTypeToUpdateublication : (values:PublicationFormType & ObjectWithId<number>) : UpdatePublicationType => {
        return {
            ...values,
            authors : values.authors.map(author => ({
                name : author.name,
                user_id : author.user === null ? null : author.user.id
            })),
            pdf : typeof values.pdf === 'string' ? undefined : values.pdf,
            serial_id : undefined,
            msc_subject_codes : values.msc_subjects.map(subject => subject.code),
            user_authors : undefined,
            msc_subjects : undefined,
            year : typeof values.year === 'string' ? null : values.year,
            month : typeof values.month === 'string' ? null : values.month,
            volume : typeof values.volume === 'string' ? null : values.volume,
            number : typeof values.number === 'string' ? null : values.number
        } as UpdatePublicationType;
    }
};

const displayByType = (type: PublicationTypeField, name : FieldListType) : boolean => {
    return BibtexSchema[type].required.includes(name) || BibtexSchema[type].optional.includes(name);
};


const classNameByType = (type: PublicationTypeField, name : FieldListType) => {
    return displayByType(type, name) ? 'd-block' : 'd-none';
};

const requiredByType = (type: PublicationTypeField, name : FieldListType) => {
    if(BibtexSchema[type].required.includes(name)) {
        return 'required';
    }
    return 'optional';
};



const PublicationForm : React.FC<PublicationFormProps> = ({ 
    value : publication, user,
    onButtonsChange,
    onCreate, 
    onUpdate,
    onSubmit,
    displayButtons=true}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const updatePublications = useUpdatePublications();
    const addToast = useContext(ToasterContext);

    const initialValues = useMemo(() => (
        publication === undefined ? 
            PublicationConverter.undefinedToFormType() :
            PublicationConverter.publicationToFormType(publication)
    ), [publication]);

    const [activeTab, setActiveTab] = useState(0 as 0 | 1 | 2);

    const submitFunction :  () => void = () => submitAction.trigger();

    const formik = useFormik({

        initialValues,
        validationSchema : Yup.lazy(values => Yup.object({
            type : Yup.string().required(),
            title : Yup.string()[requiredByType(values.type, 'title')]('Required'),
            authors : Yup.array(),
            year : Yup.number()[requiredByType(values.type, 'year')]('Required'),
            month : Yup.number()[requiredByType(values.type, 'month')]('Required'),
            journal : Yup.string()[requiredByType(values.type, 'journal')]('Required'),
            volume : Yup.number()[requiredByType(values.type, 'volume')]('Required'),
            number : Yup.number()[requiredByType(values.type, 'number')]('Required'),
            pages : Yup.string()[requiredByType(values.type, 'pages')]('Required'),
            book_title : Yup.string()[requiredByType(values.type, 'book_title')]('Required'),
            publisher : Yup.string()[requiredByType(values.type, 'publisher')]('Required'),
            institution : Yup.string()[requiredByType(values.type, 'institution')]('Required'),
            doi : Yup.string()[requiredByType(values.type, 'doi')]('Required'),
            isbn : Yup.string()[requiredByType(values.type, 'isbn')]('Required'),
            note : Yup.string()[requiredByType(values.type, 'note')]('Required')
        })),
        onSubmit : submitFunction
    });

    const {values, errors, setFieldValue, isValid, setSubmitting } = formik;

    const handleCreate = useCallback(async (publication: NewPublicationType) => {
        const newPublication =  await publicationAPI.create(publication);
        onCreate && onCreate(newPublication);
        onSubmit && onSubmit(newPublication);
        updatePublications(user, undefined);
        addToast({
            type : 'success',
            title : 'Publication created',
            children : 'Publication successfully created'
        })
        return newPublication;
    }, [onCreate, onSubmit, updatePublications, addToast, user]);

    const handleUpdate  = useCallback(async (publication : UpdatePublicationType) => {
        const updatedPublication =  await publicationAPI.update(publication);
        onUpdate && onUpdate(updatedPublication);
        onSubmit && onSubmit(updatedPublication);
        updatePublications(user, undefined);
        addToast({
            type : 'success',
            title : 'Publication updated',
            children : 'Publication successfully updated'
        })
        return updatedPublication;
    }, [onUpdate, onSubmit, updatePublications, addToast, user]);

    const submitAction = useAction<Publication>(useCallback(async () => {

        setSubmitting(false);
        try {
            if(values.id !== undefined) {
                return handleUpdate(
                    PublicationConverter.formTypeToUpdateublication(
                        values as PublicationFormType & ObjectWithId<number>)
                );
            }
            return handleCreate(
                PublicationConverter.formTypeToNewPublication(values)
            );
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return undefined;
    }, [handleCreate, handleUpdate, setSubmitting, values]));

    const buttons = useMemo(() => {
        return [<Button key="save" color="primary" {...submitAction.buttonProps} disabled={!isValid || submitAction.loading}>Save</Button>];
    }, [submitAction, isValid]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange])


    const newAuthor = useCallback((value:string) : Author => {
        return new Author({
            name : value,
            user : null,
            publication
        });
    }, [publication]);

    const newUserAuthor = useCallback((user:ShortUser) : Author => {
        return new Author({
            name : user.last_name + ', ' + user.first_name,
            user,
            publication : publication
        });
    }, [publication]);

    const handleAuthorChange = useCallback((author:Author) : void => {
        const newAuthors = values.authors.map(auth => auth.name === author.name ? author : auth );
        setFieldValue('authors', newAuthors);
    }, [setFieldValue, values.authors]);


    // Small hack to put inputs in one row instead of two if only 3 of them are displayed
    let yearMonth = [<InputGroup key="year" name="year" type="number" label="Year" placeholder="Year" />, 
        <InputGroup key="month" name="month" type="number" label="Month" placeholder="Month"  />
        ].filter(node => displayByType(values.type, node.props.name));
    let volNumPages = [
        <InputGroup key="volume" name="volume" type="number" label="Volume" />,
        <InputGroup key="number" name="number" type="number" label="Number" />,
        <InputGroup key="pages" name="pages" label="Pages" />
    ].filter(node => displayByType(values.type, node.props.name));

    if(yearMonth.length + volNumPages.length <= 3) {
        yearMonth = [...yearMonth, ...volNumPages];
        volNumPages = [];
    }


    // enableReinitialize={true}
    return <>
    <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

    <FormikProvider value={formik}>
        <Form tag={FkForm}>

            <Nav tabs>
                <NavItem><NavLink href="#" active={activeTab === 0} onClick={() => setActiveTab(0)}>Publication</NavLink></NavItem>
                <NavItem><NavLink href="#" active={activeTab === 1} onClick={() => setActiveTab(1)}>File</NavLink></NavItem>
                <NavItem><NavLink href="#" active={activeTab === 2} onClick={() => setActiveTab(2)}>Metadata</NavLink></NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="pt-3">
                <TabPane tabId={0}>

                    <InputGroup name="type" type="select" label="Type" placeholder="Select a type" as="select">
                        {Publication.typeList.map(type => (
                            <option key={type} value={type}>{Publication.typeToString(type)}</option>
                        ))}
                    </InputGroup>

                    <InputGroup name="title" label="Title" placeholder="Title" className={classNameByType(values.type, 'title')} />

                    <Row>
                        <Col md={6}>
                            <FormGroup className={classNameByType(values.type, 'authors')}>
                                <Label for="title">Authors</Label>
                                <div className="form-control p-3 bg-light">
                                <ObjectManyOneField id="authors" name="authors" 
                                    toValue={newAuthor} toItem={AuthorItem} 
                                    sortable={true} deletable={true} mutable={true}
                                    onItemChange={(author:Author) => handleAuthorChange(author)}
                                    placeholder="Last name, First name" invalid={'authors' in errors}>
                                    {ctx => <ShortUserCompletion text={ctx.autocompleteContext.value} onSelect={user => {
                                        setFieldValue('authors', [...values.authors, newUserAuthor(user) ] )
                                    }}/>}
                                </ObjectManyOneField>
                                <FormFeedback component="small" className="text-danger">{typeof errors.authors === 'string' ? errors.authors : ''}</FormFeedback>
                                </div>
                            </FormGroup>

                        </Col>
                        <Col md={6}>
                            <Row>
                                {yearMonth.map(group => <Col key={group.key} md={12/yearMonth.length}>{group}</Col>)}
                            </Row>

                            <InputGroup name="journal" label="Journal" className={classNameByType(values.type, 'journal')} />

                            <Row>
                                {volNumPages.map(group => <Col key={group.key} md={12/volNumPages.length}>{group}</Col>)}
                            </Row>

                            <InputGroup name="book_title" label="Book title" className={classNameByType(values.type, 'book_title')} />

                            <InputGroup name="editor" label="Editor" className={classNameByType(values.type, 'editor')} />

                            <InputGroup name="publisher" label="Publisher" className={classNameByType(values.type, 'publisher')} />

                            <InputGroup name="institution" label="Institution" className={classNameByType(values.type, 'institution')} />

                            <InputGroup name="organization" label="Organization" className={classNameByType(values.type, 'organization')} />

                            <InputGroup name="series" label="Series" className={classNameByType(values.type, 'series')} />

                            <InputGroup name="doi" label="DOI" className={classNameByType(values.type, 'doi')} />

                            <InputGroup name="isbn" label="ISBN" className={classNameByType(values.type, 'isbn')} />

                            <InputGroup name="howpublished" type="text" as="textarea" label="How published" className={classNameByType(values.type, 'howpublished')} />

                            <InputGroup name="note" type="text" as="textarea" label="Note" className={classNameByType(values.type, 'note')} />
                            
                        </Col>
                    </Row>

            
                </TabPane>
                <TabPane tabId={1}>

                    <PdfFileGroup name="pdf" label="File" maxWidth="250px" thumbnail={publication === undefined ? '' : (publication.thumbnail === null ? '' : publication.thumbnail)} />

                </TabPane>
                <TabPane tabId={2}>

                    <Alert isOpen={true} color="info" className="m-3">
                        <InfoCircleFill size={32} /> &nbsp; Be sure you know what you do before changing metadata !<br /><br />
                        <ul>
                            <li>The Arxiv and ZbMath ids are automatically learned based on the title and authors.</li>
                            <li>They are used to extract publication informations, including MSC subjects.</li>
                            <li>If they are not learned properly, then you should manually add the ZbMath ID.</li>
                        </ul>
                    </Alert>


                    <Row>
                        <Col>
                            <InputGroup name="arxiv_id" label="ArXiv ID"  />
                            <CheckGroup name="arxiv_loaded" label="Metadata loaded from ArXiv" />
                        </Col>
                        <Col>
                            <InputGroup name="zbmath_id" label="ZbMath ID"  />
                            <CheckGroup name="zbmath_loaded" label="Metadata loaded from ZbMath" />
                        </Col>
                    </Row>
                    <Row><MscSubjectManyOneGroup name="msc_subjects" label="MSC Subjects" /></Row>

                </TabPane>
            </TabContent>

            

            { displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
            </Row> : null}
        </Form>
    </FormikProvider>
    </>;
};

// className={['ARTICLE', 'BOOK', 'INCOLLECTION', 'INPROCEEDINGS'].includes(values.type) ? 'd-display' : 'd-none'}

export default PublicationForm;