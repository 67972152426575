
export class Playlist {

    id : string;
    title : string;
    description : string;
    channel_id : string;
    num_videos : number;
    publication_time : Date;
    
    constructor(json : any) {
        json.publication_time = new Date(json.publication_time);
        Object.assign(this, json);
    }

    getPage() {
        return '/videos/playlists/' + this.id;
    }

    is(playlist : Playlist) : boolean {
        return this.id === playlist.id
            && this.title === playlist.title
            && this.description === playlist.description
            && this.num_videos === playlist.num_videos
            && this.channel_id === playlist.channel_id
            && this.publication_time.getTime() === playlist.publication_time.getTime();
    }
};
