
import React, {useContext, useEffect, useMemo, useState} from "react";
import { userAPI } from 'directory/api/entities';
import {Form, FormGroup, Row, Col, Button, FormFeedback} from 'reactstrap';
import AutoDismissAlert from "core/components/AutoDismissAlert";
import "directory/forms/lists/ResearchInterestListForm.scss";
import { errorToText } from "core/helpers/error";
import { useAction } from "core/hooks/action";
import User from "directory/api/models/User";
import { useUpdateUser } from "directory/hooks/user";
import { ZeroFormProps } from "core/forms/types";
import { useCallback } from "core/api/equality";
import { ToasterContext } from "core/hooks/toaster";
import ObjectManyOneField from "core/components/forms/ObjectManyOneField";
import MscSubjectCompletion from "msc/completions/MscSubjectCompletion";
import { Form as FkForm, FormikProvider, useFormik } from "formik";
import MscSubjectItem from "msc/items/MscSubjectItem";
import { MscSubject } from "msc/api/models";


export type ResearchInterestsFormProps = ZeroFormProps<User>;

type FormikValues = {
    msc_subjects : MscSubject[]
};

const ResearchInterestListForm : React.FC<ResearchInterestsFormProps> = ({
    value : user,
    onButtonsChange, 
    displayButtons = true
}) => {


    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const updateUser = useUpdateUser();
    const addToast = useContext(ToasterContext);

    const submitFunction :  () => void = () => submitAction.trigger();

    const formik = useFormik<FormikValues>({
        initialValues : {
            msc_subjects : user.msc_subjects
        },
        onSubmit : submitFunction
    });

    const {values, errors, setFieldValue } = formik;

    const submitAction = useAction(useCallback(async () => {
        const subjectCodes = values.msc_subjects.map(subject => subject.code);
        try {
            const data = await userAPI.updateMscSubjects(user.id, subjectCodes);
            const newUser = user.clone();
            newUser.msc_subjects = values.msc_subjects;
            updateUser(newUser as User);
            addToast({
                type : 'success',
                title : 'Research interests updated',
                children : 'Research interests successfully updated'
            })
            return data;
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
            return undefined
        }
    }, [values.msc_subjects, updateUser, user, addToast]));

    const buttons = useMemo(() => {
        return [
            <Button key="save" color="primary" {...submitAction.buttonProps}>Save</Button>
        ];
    }, [submitAction]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [onButtonsChange, buttons]);

    const setValue = useCallback((subject:MscSubject) => {
        setFieldValue('msc_subjects', [...values.msc_subjects, subject ] );
    }, [setFieldValue, values.msc_subjects]);


    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

        <FormikProvider value={formik}>
            <Form tag={FkForm}>
                <FormGroup>
                    <div className="form-control p-3 bg-light">
                    <ObjectManyOneField id="msc_subjects" name="msc_subjects" 
                        toItem={MscSubjectItem}
                        unique={true}
                        deletable={true}
                        disjoint={true}
                        placeholder="Enter an MSC Code or a description" invalid={'msc_subjects' in errors}>
                        {ctx => <MscSubjectCompletion text={ctx.autocompleteContext.value} onSelect={setValue} exclude={ctx.exclude} />}
                    </ObjectManyOneField>
                    <FormFeedback component="small" className="text-danger">{typeof errors.msc_subjects === 'string' ? errors.msc_subjects : ''}</FormFeedback>
                    </div>
                </FormGroup>

            {displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
            </Row> : null}

            </Form>
        </FormikProvider>



    </>;

};

export default ResearchInterestListForm;