import { Book } from 'books/api/models';
import { NewBookType, UpdateBookType } from 'books/api/types';
import EntityAPI from 'core/api/EntityAPI';

class BookAPI extends EntityAPI<number, Book, NewBookType, UpdateBookType> {
    
    
    async batch(entries : NewBookType[]) : Promise<Book[]> {
        const myToken = this.token();
        const headers = {
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.post('/batch/', 
            {entries}, { headers });
        return data;
    }
    
};

export default BookAPI;
