import React from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { BasicItemProps, SelectableItemProps } from "core/items/types";
import { useCallback } from "core/api/equality";
import { Video } from 'videos/api/models/Video';

export type VideoCardProps = BasicItemProps<Video>
    & SelectableItemProps<Video>;


const VideoCard : React.FC<VideoCardProps> = ({ 
    value : video,
    selectable = false, onSelect
}) => {

    const handleClick = useCallback(() => {
        onSelect && onSelect(video);
    }, [video, onSelect]);

    return <Card className="h-100" onClick={selectable ? handleClick : undefined}>
            <img role={selectable ? 'button' : ''} src={video.thumbnail} className="w-100" alt="Video" />
            <CardBody>
            <CardTitle tag="h5" className="small text-center">{video.title}</CardTitle>
            <CardText className="text-muted small text-center">{video.publication_time.toLocaleString()}</CardText>
            </CardBody>
        </Card>;

};


export default VideoCard;