import Publication from 'publications/api/models/Publication';
import { NewPublicationType } from "publications/api/types";
import BibtexEntryChoiceStep from 'publications/forms/steps/BibtexEntryChoiceStep';
import { BaseFormProps } from 'core/forms/types';
import React, { useCallback, useMemo, useState } from 'react';
import Stepper from 'core/components/Stepper';
import { Col, Row, Spinner } from 'reactstrap';
import { publicationImportAPI } from "publications/api/entities";
import { useQuery } from 'react-query';
import AuthorChoiceStep from 'publications/forms/steps/AuthorChoiceStep';
import { fetchDBLPAuthorsKey } from 'publications/queryKeys';



export type GenericStepperProps = BaseFormProps<undefined, Publication[]> & {
    source : 'dblp' | 'zbmath'
};

const GenericStepper : React.FC<GenericStepperProps> = ({
    displayButtons: buttons = true,
    source,
    onSubmit,
    onButtonsChange
}) => {

    const [step, setStep] = useState(0 as 0 | 1);
    const [entries, setEntries] = useState([] as NewPublicationType[]);
    const { data : authors } = useQuery([fetchDBLPAuthorsKey], () => publicationImportAPI.authors(source));
    const [buttonsState, setButtonsState] = useState([] as React.ReactElement[]);

    const toStep1 = useCallback((entries : NewPublicationType[]) => {
        setEntries(entries);
        setStep(1);
    }, []);

    const handleButtonsChange = useCallback((buttons : React.ReactElement[]) => {
        setButtonsState(buttons);
        onButtonsChange && onButtonsChange(buttons);
    }, [onButtonsChange])

    const currentForm = useMemo(() => {
        switch(step) {
            case 0:
                if(authors === undefined) {
                    return <div className="text-center p-3"><Spinner size="sm" /> &nbsp; Loading authors list...</div>;
                }
                return <AuthorChoiceStep input={authors} onButtonsChange={handleButtonsChange} onSubmit={toStep1}/>
            case 1:
                return <BibtexEntryChoiceStep input={entries} onButtonsChange={handleButtonsChange} onSubmit={onSubmit} />;
        }
    }, [step, entries, onSubmit, handleButtonsChange, authors, toStep1])

    return <div>
        <Stepper 
            steps={[
                { label : 'Author choice' },
                { label : 'Publications choice'}
            ]} 
            activeStep={step} />

        {currentForm}

        {buttons ? (
            <Row className="row-cols-lg-auto">
                <Col className="ms-auto">
                    {buttonsState}
                </Col>
            </Row>
        ) : null}

    </div>
}

export default GenericStepper;