import * as React from 'react';
import MDEditor from '@uiw/react-md-editor';
//import { getCodeString } from 'rehype-rewrite';
//import katex from 'katex';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.css';

export type MarkdownViewerProps = {
    value: string;
    className? : string;
};

export type MarkdownEditorProps = {
    onChange: (value:string) => void;
} & MarkdownViewerProps;


const mdOptions : any = {
    remarkPlugins: [remarkMath],
    rehypePlugins: [rehypeKatex]
}

export const MarkdownEditor : React.FC<MarkdownEditorProps> = ({value, onChange, className}) => {

    return <div data-color-mode="light" className={className}>
        <MDEditor
                value={value}
                onChange={onChange}
                previewOptions={mdOptions}
                 />
        </div>;
};

export const MarkdownViewer : React.FC<MarkdownViewerProps> = ({value, className}) => {

    return <div data-color-mode="light" className={className}>
        <MDEditor.Markdown
                source={value}
                {...mdOptions}
                
                 />
        </div>;

};
