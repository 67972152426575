
export const fetchUserKey = "FETCH_USER";

export const fetchShortUsersKey = "FETCH_SHORT_USER_LIST";

export const fetchUsersKey = "FETCH_USERS";

export const fetchUserAboutKey = "FETCH_USER_ABOUT";

export const fetchGravatarKey = "FETCH_GRAVATAR";


