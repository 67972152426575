import { CompletionProps } from 'core/completions/types';
import React from 'react';
import { ShortVideo } from 'videos/api/models/ShortVideo';
import { videoAPI } from 'videos/api/entities';
import { fetchShortVideosKey } from 'videos/queryKeys';
import ShortVideoItem from 'videos/items/ShortVideoItem';
import DefaultCompletion from 'core/completions/DefaultCompletion';

export type ShortVideoCompletionProps = CompletionProps<ShortVideo>;

const ShortVideoCompletion : React.FC<ShortVideoCompletionProps> = (props) => {
    return <DefaultCompletion 
        Item={ShortVideoItem} 
        queryKey={fetchShortVideosKey}
        query={videoAPI.getShortAll.bind(videoAPI)}
        {...props} />
};


export default ShortVideoCompletion;
