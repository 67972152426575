import React, {useContext, useEffect, useMemo, useState} from "react";
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import {Form, Row, Col, Button} from 'reactstrap';
import * as Yup from 'yup';
import AutoDismissAlert from "core/components/AutoDismissAlert";
import { userAPI } from 'directory/api/entities';
import PasswordChecklist from "react-password-checklist";
import { Check, X } from "react-bootstrap-icons";
import { errorToText } from "core/helpers/error";
import User from "directory/api/models/User";
import { useUpdateUser } from "directory/hooks/user";
import { UForm } from "core/forms/types";
import InputGroup from "core/forms/groups/InputGroup";
import { useAction } from "core/hooks/action";
import { useCallback } from "core/api/equality";
import { ToasterContext } from "core/hooks/toaster";

export type ChangePasswordFormProps = UForm<User>;

type PasswordFormType = {
    old_password : string;
    new_password : string;
    confirmation : string;
};

const ChangePasswordForm : React.FC<ChangePasswordFormProps> = ({
    value : user, 
    onButtonsChange,
    onSubmit, onUpdate, 
    displayButtons=true}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const updateUser = useUpdateUser();
    const addToast = useContext(ToasterContext);

    const initialValues = useMemo(() => ({
        old_password : '',
        new_password : '',
        confirmation : '',
    }), []);

    const submitFunction :  () => void = () => submitAction.trigger();

    const formik = useFormik({
        initialValues,
        validateOnMount : true,
        validationSchema : Yup.object({
            old_password: Yup.string()
            .required('Required'),
            new_password: Yup.string()
            .required('Required'),
            confirmation: Yup.string()
            .required('Required').oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
        }),
        onSubmit : submitFunction
    });

    const newPasswordValidation = useCallback((values : PasswordFormType, props : any) => {
        let error = '';
        if (!passwordValid) {
            error = 'Not complex enough'
        }
        return error;
    }, [passwordValid]);

    const { values, touched, isValid, setSubmitting, resetForm } = formik;

    const submitAction = useAction(useCallback(async () => {
        setSubmitting(false);
        try {
            await userAPI.updatePassword(user.id, values.old_password, values.new_password);
            addToast({
                type : 'success',
                title : 'Password changed',
                children : 'Password successfully changed'
            })
            resetForm({})
            updateUser(user);
            onSubmit && onSubmit(user);
            onUpdate && onUpdate(user);
            return user;
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return;
    }, [setSubmitting, values, onSubmit, onUpdate, updateUser, resetForm, user, addToast]));


    const buttons = useMemo(() => {
        return [<Button key="change" color="primary" {...submitAction.buttonProps} disabled={!isValid || submitAction.loading}>Change</Button>];
    }, [submitAction, isValid]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange]);

    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>
        
        <FormikProvider value={formik}>
        <Form tag={FkForm}>
            <Row>
                <Col md={4}>
                    <InputGroup label="Old password" name="old_password" type="password" />
                </Col>
            <Col md={4}>
                <InputGroup label="New password" name="new_password" type="password" validate={newPasswordValidation} />
            </Col>
            <Col md={4}>
                <InputGroup label="Confirmation" name="confirmation" type="password" />
            </Col>
            </Row>

            <Row>
                <Col md={4}></Col>
                <Col md={8} className="ps-1">
                <small><PasswordChecklist
                    rules={["minLength","specialChar","number","capital"]}
                    minLength={8}
                    iconComponents={{
                        ValidIcon : <Check color="green" size="26" />,
                        InvalidIcon : <X color="red" size="26" />
                    }}
                    className={touched.new_password ? 'pb-3' : 'd-none'}
                    onChange={setPasswordValid}
                    value={values.new_password} valueAgain={values.confirmation} />
                </small>
                </Col>
            </Row>
           

            {displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
            </Row> : null }
        </Form>
    </FormikProvider>
    </>;

};

export default ChangePasswordForm;
