import Modal from 'core/components/Modal';
import { BaseConfirmationModalProps } from 'core/modals/confirmations/types';
import React from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export type ConfirmationModalProps = BaseConfirmationModalProps & {
    children? : React.ReactNode;
};

const ConfirmationModal : React.FC<ConfirmationModalProps> = ({
    cancelLabel = 'Cancel',
    confirmLabel = 'Confirm',
    children,
    modal,
    size = 'sm',
    onConfirm
    }) => {

        const handleConfirm = React.useCallback(() => {
            modal.close();
            onConfirm && onConfirm();
        }, [modal, onConfirm]);

        return <Modal size={size} state={modal}>
            <ModalHeader className="bg-light" toggle={modal.close}>Confirmation</ModalHeader>

            <ModalBody>
                {children}
            </ModalBody>

            <ModalFooter className="bg-light">
                <Button color="danger" onClick={handleConfirm}>{confirmLabel}</Button>
                <Button color="secondary" onClick={modal.close}>{cancelLabel}</Button>
            </ModalFooter>
            
        </Modal>
};

export default ConfirmationModal;