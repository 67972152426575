import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';

const IndexPanel = () => {

    const navigate = useNavigate();

    useEffect(() => navigate('profile/user'), [navigate]);

    return <div>Welcome to the administration panel</div>
}

export default IndexPanel;