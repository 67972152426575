import { is } from "core/api/equality";
import { City } from "geonames/api/models";
import ShortInstitution from "institutions/api/models/ShortInstitution";


export default class Institution {
    id: number;
    name_en: string;
    name_local: string;
    logo : string | null;
    website: string;
    city: City | null;
    description : string;
    address : string;

    constructor(json: any) {
        json.city = json.city === null ? null : new City(json.city);
        Object.assign(this, json);
    }

    is(inst: Institution): boolean {
        return this.id === inst.id
            && this.name_en === inst.name_en
            && this.name_local === inst.name_local
            && is(this.city, inst.city)
            && this.website === inst.website
            && this.logo === inst.logo
            && this.description === inst.description
            && this.address === inst.address;
    }

    toShortInstitution(): ShortInstitution {
        return new ShortInstitution({
            id : this.id,
            name_en : this.name_en,
            name_locale : this.name_local,
            website : this.website,
            city : this.city,
            logo : this.logo
        });
    }

    getName(): string {
        return this.toShortInstitution().getName();
    }

    getLocationList(): string[] {
        return this.toShortInstitution().getLocationList();
    }
}