import React, { useEffect, useMemo, useState } from "react";
import _uniqueId from 'lodash/uniqueId';

type FocusContext = {
    hasFocus : boolean;
    setFocus : (focus:boolean) => void;
};

type Props = {
    children : (ctx:FocusContext) => React.ReactNode;
    focus?: boolean
    onFocus? : () => void;
};

const isDescendant = function (parent : any, child : any) {
    let node = child.parentNode;
    while (node) {
        if (node === parent) {
            return true;
        }

        // Traverse up to the parent
        node = node.parentNode;
    }

    // Go up until the root but couldn't find the `parent`
    return false;
};

const FocusableComponent : React.FC<Props> = ({children, focus=false, onFocus}) => {

    const [visible, setVisible] = useState(focus);
    const [uniqueId] = useState(_uniqueId('app-focusable-component'));
    
    const context = useMemo(() => ({ hasFocus : visible, setFocus : setVisible }),
        [visible, setVisible])

    useEffect(() => {
        if(visible === true)
            onFocus && onFocus();
    }, [visible, onFocus])

    useEffect(() => {
        window.addEventListener('mousedown', e => {
            setVisible(isDescendant(document.getElementById(uniqueId), e.target));
        })
    }, [uniqueId]);

    return <div id={uniqueId} className={"app-focusable-component" + (visible ? ' focus' : '')}>
        {children(context)}
    </div>;
};

export default FocusableComponent;