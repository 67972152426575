import AutocompleteSelectGroup from 'core/forms/groups/AutocompleteSelectGroup';
import { AutocompleteSelectFormGroupProps } from 'core/forms/groups/types';
import React from 'react';
import LanguageItem from 'languages/items/LanguageItem';
import LanguageCompletion from 'languages/completions/LanguageCompletion';

export type LanguageSelectGroupProps = AutocompleteSelectFormGroupProps;

const LanguageSelectGroup : React.FC<LanguageSelectGroupProps> = ({ 
    label = 'Language', placeholder = 'Select a language', name = 'language',
    className, bsSize
}) => {

    return <AutocompleteSelectGroup 
            bsSize={bsSize}
            className={className}
            label={label}
            placeholder={placeholder}
            name={name}
            Item={LanguageItem}
            Completion={LanguageCompletion} />;
};

export default LanguageSelectGroup;