import React from "react";
import {Table} from 'reactstrap';
//import { useUpdatePublications } from "directory/hooks/publication";
import User from "directory/api/models/User";
import { useQuery } from "react-query";
import { fetchPublicationsKey } from "publications/queryKeys";
import { publicationAPI } from "publications/api/entities";
import SuggestedPublicationItem from "publications/items/SuggestedPublicationItem";


export type PublicationListFormProps = {
    user : User;
};

const SuggestedPublicationListForm : React.FC<PublicationListFormProps> = ({user}) => {

    const { data : publications, isLoading, isError } = useQuery([fetchPublicationsKey, "SUGGESTED", user.id], 
        () => publicationAPI.getAll({
            user_id : user.id,
            limit : 50,
            validation : 'SUGGESTED'
    }));
    //const updatePublications = useUpdatePublications();
    

    if(isLoading || isError || publications === undefined)
        return null;
  
    return <>
        <Table striped className="border bg-white">
            <thead>
            <tr>
                <th>Suggested Publications</th>
            </tr>
            </thead>
            <tbody>
            {publications.count === 0 ? (<tr><td className="text-center small">You have no suggested publication yet</td></tr>)
            : (publications.results.map(publication => (
                <tr key={publication.id}>
                    <td><SuggestedPublicationItem value={publication} user={user} /></td>
                </tr>
            )))}
            </tbody>
        </Table>
    </>;

};

export default SuggestedPublicationListForm;