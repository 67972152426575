import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DetailLayout, DetailLeftColumn, DetailMainColumn } from 'core/pages/DetailPage';
import Latex from 'react-latex';
import { fetchBookListKey } from 'books/queryKeys';
import { bookAPI } from 'books/api/entities';
import BookRenderer from 'books/renderers/BookRenderer';


const BookPage : React.FC<{}> = () => {


    const {bookId} = useParams();

    if(bookId === undefined) {
        throw Error('Invalid book ID');
    }

    const { data : book, error, isError, isLoading } = 
        useQuery([fetchBookListKey, bookId], () => bookAPI.get(parseInt(bookId)) );

    const renderer = useMemo(() => {
        if(book === undefined) {
            return undefined;
        }
        
        return new BookRenderer(book);
    }, [book])

    if (isError)
        throw error;

    if (isLoading || !book ) {
        return null;
    }

    return <DetailLayout>
        <DetailLeftColumn>

            <div className="text-center">
                {renderer?.getThumbnail()}
            </div>
            
        </DetailLeftColumn>
        <DetailMainColumn>
            <h3><Latex>{book.title}</Latex></h3>
            <h6><Latex>{book.subtitle}</Latex></h6>
            <p>{renderer?.getAuthors()}</p>

            {book.description}
        </DetailMainColumn>
    </DetailLayout>;
}
    
    
export default BookPage;
