import React, { useCallback, useEffect, useState } from 'react';
import {Toast as RToast, ToastBody, ToastHeader} from 'reactstrap';

export type ToastProps = {
    children? : React.ReactNode;
    title : string;
    type? : 'primary' | 'success' | 'danger';
    keepAlive? : boolean;
};

const Toast : React.FC<ToastProps> = ({
    children,
    title,
    type = 'success',
    keepAlive = false
}) => {

    const [open, setOpen] = useState(true);

    const toggle = useCallback(() => setOpen(open => !open), []);

    useEffect(() => {
        if(!keepAlive) {
            setTimeout(() => setOpen(false), 3000)
        }
    }, [keepAlive])

    return <div className={'bg-' + type + ' m-1 rounded'}>
        <RToast isOpen={open} className="m-0">
        <ToastHeader style={{backgroundColor: 'transparent'}} toggle={toggle}>{title}</ToastHeader>
        <ToastBody>{children}</ToastBody>
    </RToast>
    </div>;
};

export default Toast;