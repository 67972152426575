import ManyOneGroup from 'core/forms/groups/ManyOneGroup';
import { ManyOneFormGroupProps } from 'core/forms/groups/types';
import React from 'react';
import ShortSerialItem from 'serials/items/ShortSerialItem';
import ShortSerialCompletion from 'serials/completions/ShortSerialCompletion';

export type ShortSerialManyOneGroupProps = ManyOneFormGroupProps;

const ShortSerialManyOneGroup : React.FC<ShortSerialManyOneGroupProps> = ({ 
    label='Journal', placeholder = 'Search for a journal', name='serials',
    ...props
}) => {

    return <ManyOneGroup 
            {...props}
            label={label}
            placeholder={placeholder}
            name={name}
            Item={ShortSerialItem}
            Completion={ShortSerialCompletion} />;
};

export default ShortSerialManyOneGroup;