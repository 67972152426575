import { Col, Row } from 'reactstrap';
import React from 'react';
import { DeletableItemProps, SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { useCallback } from "core/api/equality";
import { X } from "react-bootstrap-icons";
import ShortInstitution from "institutions/api/models/ShortInstitution";

export type ShortInstitutionItemProps = SelectableItemProps<ShortInstitution>
    & DeletableItemProps<ShortInstitution>;

const ShortInstitutionItem : React.FC<ShortInstitutionItemProps> = ({
    value : institution, 
    selectable = false, onSelect, 
    deletable = false, onDelete,
    action = false, active = false, style, rootRef}) => {

    
    const handleDelete = useCallback(() => {
        onDelete && onDelete(institution);
    }, [onDelete, institution]);

    return <BaseGroupItem action={action || selectable} active={active} role={onSelect === undefined ? '' : 'button'} 
        onClick={selectable ? () => onSelect && onSelect(institution) : undefined} style={style} rootRef={rootRef}>
        <Row className="small">
            <Col md={2} className="d-none d-md-block p-0 text-start" style={{maxWidth: '50px'}}><img src={institution.getLogo()} className="w-100" alt="Logo" /></Col>
            <Col md={10 - (deletable ? 2 : 0)}>{institution.getName()}<br />
                <small>{institution.getLocationList()}</small>
            </Col>
            {deletable ? <Col md={2} className="text-end" role="button" onClick={handleDelete}><X width={32} /></Col> : null}
        </Row>
    </BaseGroupItem>;
};

export default ShortInstitutionItem;