import { useCallback } from "core/api/equality";
import User from "directory/api/models/User";
import * as React from 'react';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { BasicItemProps, SelectableItemProps } from "core/items/types";

type UserProps = BasicItemProps<User>
  & SelectableItemProps<User>;

const UserCard : React.FC<UserProps> = ({
    value : user,
    selectable, onSelect
  }) => {

    const affiliation = user.getPrimaryOrFirstCurrentAffiliation();
  
    const handleSelect = useCallback(() => {
      onSelect && onSelect(user);
    }, [onSelect, user]);
  
    return <div className="col mb-3 btn" onClick={selectable ? handleSelect : undefined}>
      <Card className="h-100">
        <div className="row g-0 align-items-center h-100" style={{minHeight: '7rem'}}>
         <div className="col-md-4">
            <img src={user.getAvatar()} width={80} className="img-fluid" alt="Avatar" />
         </div>
         <div className="col-md-8">
          <CardBody>
            <CardTitle tag="h5" className="small">{user.first_name} {user.last_name}</CardTitle>
            {<CardSubtitle className="small">{affiliation ? affiliation.institution.getName() : 'No institution'}</CardSubtitle>}
          </CardBody>
      </div>
      </div>
      </Card>
      </div>;
};

export default UserCard;