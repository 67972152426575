import AutoDismissAlert from "core/components/AutoDismissAlert";
import { NewPublicationType } from "publications/api/types";
import { StepProps } from "core/forms/steps/types";
import { useCallback, useEffect, useState } from "react";
import { Alert, Button, ListGroup } from "reactstrap";
import React from 'react';
import { errorToText } from "core/helpers/error";
import { publicationImportAPI } from "publications/api/entities";
import { InfoCircleFill } from "react-bootstrap-icons";
import BibtexFileItem from "publications/items/BibtexFileItem";
import { useDropzone } from "react-dropzone";
import { useAction } from "core/hooks/action";

export type BibtexFileUploadStepProps = StepProps<undefined, NewPublicationType[]>;

const BibtexFileUploadStep : React.FC<BibtexFileUploadStepProps> = ({
    onSubmit,
    onButtonsChange
}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [bibtexFiles, setBibtexFiles] = useState([] as File[]);
    const submitHandler = useAction<NewPublicationType[]>(useCallback(async () => {
        try {
            const result = await publicationImportAPI.bibtex_check(bibtexFiles);
            return result.entries;
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return [];
    }, [bibtexFiles]));
    submitHandler.addListener(onSubmit);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop : (acceptedFiles : File[]) => {
            setBibtexFiles(files => [...files, ...acceptedFiles]);
        },
        accept: {
            'application/x-bibtex': ['.bib' ]
          }
    });

    const handleDelete = React.useCallback((file:File) => {
        setBibtexFiles(files => files.filter(f => f !== file));
    }, []);

    useEffect(() => {
        onButtonsChange && onButtonsChange([
            <Button key="direct-step-1" color="primary" {...submitHandler.buttonProps}>Next</Button>
        ])
    }, [onButtonsChange, submitHandler.buttonProps])

    return <>

        <Alert isOpen={true} color="info" className="m-3">
        <InfoCircleFill size={32} /> &nbsp; Only the publications where you are identified as an author will be imported.
        </Alert>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

        <div className="p-3">
            <ListGroup className="mb-2">
                {bibtexFiles.map(file => <BibtexFileItem key={file.name} value={file} deletable={true} onDelete={handleDelete} /> )}
            </ListGroup>

            <div {...getRootProps({className:'dropzone mt-0', style:{ height: '130px' }})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a Bibtex file here, or click to select files</p>
            </div>
        </div>
    </>;

};

export default BibtexFileUploadStep;