import Institution from "institutions/api/models/Institution";
import ShortInstitution from "institutions/api/models/ShortInstitution";
import { NewInstitutionType, UpdateInstitutionType } from "institutions/api/types";
import ViewEntityAPI from "core/api/ViewEntityAPI";


export const institutionAPI = new ViewEntityAPI<number, Institution, ShortInstitution, NewInstitutionType, UpdateInstitutionType>({
    baseURL: 'institutions/',
    factory: Institution,
    shortFactory: ShortInstitution
});
