
export class City {

    id : number;
    name : string;
    name_ascii : string;
    display_name : string;
    timezone : string;
    population : number;
    geoname_id : number;
    region : Region;

    constructor(json : any) {
        json['region'] = new Region(json['region']);
        Object.assign(this, json);
    }

    is(city : City) : boolean {
        return this.id === city.id
            && this.name === city.name
            && this.region.is(city.region);
    }
};


export class Region {

    id : number;
    name : string;
    name_ascii : string;
    display_name : string;
    geoname_id : number;
    country : Country;

    constructor(json : any) {
        json['country'] = new Country(json['country']);
        Object.assign(this, json);
    }

    is(region : Region) : boolean {
        return this.id === region.id;
    }
};

export class Country {

    id : number;
    name : string;
    name_ascii : string;
    geoname_id : number;

    constructor(json : any) {
        Object.assign(this, json);
    }

    is(country : Country) : boolean {
        return this.id === country.id;
    }
};