import { publicationAPI } from "publications/api/entities";
import User from "directory/api/models/User";
import Publication from "publications/api/models/Publication";
import { useUpdatePublications, useUpdateSuggestedPublications } from 'publications/hooks/publication';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { BasicItemProps } from 'core/items/types';
import PublicationRenderer from 'publications/renderers/PublicationRenderer';
import React, { useCallback, useMemo } from 'react';
import { Check, X } from 'react-bootstrap-icons';
import { Col, Row } from 'reactstrap';

export type PublicationProps = BasicItemProps<Publication> & {
    user : User
};


const SuggestedPublicationItem : React.FC<PublicationProps> = ({value : publication, user, style, rootRef }) => {

    const renderer = useMemo(() => new PublicationRenderer(publication), [publication]);
    const updatePublications = useUpdatePublications();
    const updateSuggestedPublications = useUpdateSuggestedPublications();

    const handleDenial = useCallback(async () => {
        await publicationAPI.updateAuthorship(publication.id, 'DENIED')
        updateSuggestedPublications(user, undefined);
    }, [user, publication, updateSuggestedPublications])

    const handleValidation = useCallback(async () => {
        await publicationAPI.updateAuthorship(publication.id, 'VALIDATED');
        updatePublications(user, undefined);
        updateSuggestedPublications(user, undefined);
    }, [user, publication, updatePublications, updateSuggestedPublications])

    return <BaseGroupItem className="app-publication border-0" style={style} rootRef={rootRef}>
        <Row>
            <Col md={1} className="pb-0 pe-0 d-none d-md-block">{renderer.getThumbnail()}</Col>
            <Col>{renderer.toElement()}</Col>
            <Col md={1} className="p-0">
                <X role="button" width="32" height="32" color="red" onClick={handleDenial} />
                <Check role="button" width="32" height="32" color="green" onClick={handleValidation}/>
            </Col>
        </Row>
    </BaseGroupItem>;

};


export default SuggestedPublicationItem;