//import Autocomplete from "components/forms/Autocomplete";
import { useFormikContext } from "formik";
import Autocomplete, {AutocompleteContext} from "core/components/forms/Autocomplete";
import React from 'react';

/**
    Formik-controlled autocomplete field
 */

type Props = {
    name : string;
    children : (ctx:AutocompleteContext<string>) => React.ReactNode;
    invalid? : boolean;
    id : string;
    bsSize? : 'lg' | 'sm';
    placeholder? : string;
};

const AutocompleteField : React.FC<Props> = (props) => {

    const { name, children, invalid } = props;
    const { values, setFieldValue } = useFormikContext<object>();

    return <div className={invalid ? 'is-invalid' : ''}>
        <Autocomplete {...props} value={values[name]} onChange={value => setFieldValue(name, value)}>
        {children}
        </Autocomplete>
    </div>;
};

export default AutocompleteField;