import React, {useState} from "react";
import {Formik, Field as FkField, Form as FkForm} from 'formik';
import {Form, FormGroup, Input, Label, FormFeedback, Row, Col} from 'reactstrap';
import * as Yup from 'yup';
import * as AuthAPI from 'auth/api';
import AutoDismissAlert from "core/components/AutoDismissAlert";
import PasswordChecklist from "react-password-checklist";
import { Check, X } from "react-bootstrap-icons";
import { errorToText } from "core/helpers/error";

export type ResetPasswordFormProps = {
    token : string;
    onSubmit? : () => void;
    buttons? : boolean;
};

type PasswordFormType = {
    new_password : string;
};

const ResetPasswordForm : React.FC<ResetPasswordFormProps> = ({token, onSubmit, buttons=true}) => {

    const [loading, setLoading] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);

    const handleSubmit = async (values : PasswordFormType) => {
        const data =  await AuthAPI.resetPassword(token, values.new_password);
        setAlertColor('success');
        setAlertMessage('Password changed successfully')
        setAlertVisible(true);
        onSubmit && onSubmit();
        return data;
    }

    const newPasswordValidation = (values : PasswordFormType, props : any) => {
        let error = '';
        if (!passwordValid) {
            error = 'Not complex enough'
        }
        return error;
    };

    const initialValues = {
        new_password : '',
        confirmation : '',
    };

    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>
        
        <Formik
    initialValues = {initialValues}
    validateOnMount={true}
    validationSchema={Yup.object({
          new_password: Yup.string()
          .required('Required'),
          confirmation: Yup.string()
        .required('Required').oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    })}
    onSubmit={(values, { setSubmitting, resetForm } ) => {
        setLoading(true);
        setSubmitting(false);
        handleSubmit(values).then(() => {
            setLoading(false);
            resetForm({});
        }).catch((err) => {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
            setLoading(false);
        });
    }}>
    {({errors, values, touched, isValid }) => (
        <Form tag={FkForm}>
            <Row>
            <Col md={6}>
            <FormGroup>
                    <Label for="new_password">New password</Label>
                    <Input id="new_password" tag={FkField} validate={newPasswordValidation} name="new_password" type="password" invalid={touched.new_password && 'new_password' in errors} />
                    <FormFeedback component="small" className="text-danger">{touched.new_password && errors.new_password}</FormFeedback>
            </FormGroup>
            </Col>
            <Col md={6}>
            <FormGroup>
                    <Label for="confirmation">Confirmation </Label>
                    <Input id="confirmation" tag={FkField} name="confirmation" type="password" invalid={touched.confirmation && 'confirmation' in errors} />
                    <FormFeedback component="small" className="text-danger">{touched.confirmation && errors.confirmation}</FormFeedback>
            </FormGroup>
            </Col>
            </Row>

            <Row className="ps-1">
                <small><PasswordChecklist
                    rules={["minLength","specialChar","number","capital"]}
                    minLength={8}
                    iconComponents={{
                        ValidIcon : <Check color="green" size="26" />,
                        InvalidIcon : <X color="red" size="26" />
                    }}
                    className={touched.new_password ? 'pb-3' : 'd-none'}
                    onChange={setPasswordValid}
                    value={values.new_password} valueAgain={values.confirmation} />
                </small>
            </Row>
           

            {buttons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto"><button type="submit" disabled={!isValid || loading} className="btn btn-primary">Reset password</button></Col>
            </Row> : null }
        </Form>
    )}
    </Formik>
    </>;

};

export default ResetPasswordForm;
