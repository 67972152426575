import { ShortUserType } from "directory/api/types";


export default class ShortUser {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    webpage: string;
    avatar: string | null;

    constructor(json: any) {
        Object.assign(this, json);
    }

    is(user: ShortUser): boolean {
        return this.id === user.id
            && this.first_name === user.first_name
            && this.last_name === user.last_name
            && this.email === user.email
            && this.webpage === user.webpage
            && this.avatar === user.avatar;
    }

    toJSON(): ShortUserType {
        return Object.assign({}, this);
    }

    getAvatar(): string {
        if (this.avatar === null) {
            return '/images/avatars/default.jpg';
        }
        return this.avatar;
    }

    getFullName(): string {
        return this.first_name + ' ' + this.last_name;
    }

    /*
        Get the User Profile Page
    */
    getProfilePage(): string {
        return '/directory/' + this.id;
    }
}
