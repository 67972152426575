import { CompletionProps } from 'core/completions/types';
import { DEFAULT_DEPARTMENT_LIST } from 'directory/config';
import StringItem from 'core/items/StringItem';
import React from 'react';
import { ListGroup } from 'reactstrap';

export type DepartmentCompletionProps = CompletionProps<string>;

const DepartmentCompletion : React.FC<DepartmentCompletionProps> = ({
    text, onSelect, 
    emptyList, selectedValue, limit = 5
}) => {

    const departments = DEFAULT_DEPARTMENT_LIST.filter(value => value.toLowerCase().includes(text.toLowerCase()));

    return <ListGroup>
        {departments.map(department => (
            <StringItem key={department} value={department} 
                active={(selectedValue !== undefined) && selectedValue === department}
                selectable={true}
                onSelect={onSelect} />
        ))}
    </ListGroup>;

};

export default DepartmentCompletion;