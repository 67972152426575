import { useMemo, useState } from "react";

export type AllowedTypes = object | null | boolean | string;

export type ActionType<T extends AllowedTypes> = {

    // Triggers the action
    trigger : () => Promise<void>;

    // True while the action is triggered
    loading : boolean;

    // List of listeners which act when receiving value T
    listeners : Array<((obj:T) => void)>;

    // Default button properties
    buttonProps : {
        onClick : () => void;
        disabled : boolean;
    },

    // Add a listener
    addListener : (listener? : (obj:T) => void) => void;

    // Action which produces the value T
    actionProvider : () => Promise<T | undefined>;
};

export const useAction = <T extends AllowedTypes>(actionProvider : () => Promise<T | undefined>) => {

    const [loading, setLoading] = useState(false);

    return useMemo(() => {

        let trigger = () => {
            setLoading(true);
            const promise = action.actionProvider();
            promise.then(obj => {
                setLoading(false);
                if(obj !== undefined) {
                    action.listeners.map(listener => listener(obj));
                };
            })
            return promise;              
        };

        const action = {
            listeners : [],
            loading,
            actionProvider,
            buttonProps : {
                onClick : trigger,
                disabled : loading
            },
            addListener : (listener? : (obj:T) => void) => {
                if(listener !== undefined) {
                    action.listeners.push(listener)
                }
            },
            trigger : trigger
        } as ActionType<T>;
        return action;
    }, [actionProvider, loading]) as ActionType<T>;
};
