import Talk from "talks/api/models/Talk";
import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import TalkRenderer from 'talks/renderers/TalkRenderer';
import { BasicItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';

export type TalkProps = BasicItemProps<Talk>;


const TalkItem : React.FC<TalkProps> = ({ value : talk, style, rootRef}) => {

    const renderer = useMemo(() => new TalkRenderer(talk), [talk]);

    const toElement = (talk : Talk) => {

        
        return <BaseGroupItem style={style} rootRef={rootRef}>
            <Row>
                <Col md={1} className="pb-0 pe-0 d-none d-md-block">
                    <div className="app-slides-thumbnail border bg-light small text-center">
                    {renderer.getThumbnail()}</div></Col>
                <Col md={9}>
                    <h5 className="small">{talk.title}</h5>
                    <h4 className="small mute">{renderer.getEventName()}</h4>
                </Col>
                <Col md={2} className="small">
                    {renderer.getEventHumanDate()}
                </Col>
            </Row>
        </BaseGroupItem>
    };

    return <div className="app-talk">
        {toElement(talk)}
    </div>;

};


export default TalkItem;