import Author from "publications/api/models/Author";
import React from 'react';
import { Col, Row } from 'reactstrap';
import Obfuscate from 'react-obfuscate';
import { X } from 'react-bootstrap-icons';
import AuthorPickerModal from 'publications/modals/AuthorPickerModal';
import { useModalState } from 'core/components/Modal';
import { DeletableItemProps, DraggableItemProps, MutableItemProps, SelectableItemProps } from 'core/items/types';
import { useCallback } from 'core/api/equality';
import BaseGroupItem from 'core/items/BaseGroupItem';


export type AuthorItemProps = 
    SelectableItemProps<Author> & 
    MutableItemProps<Author> &
    DraggableItemProps<Author> &
    DeletableItemProps<Author>;

const AuthorItem : React.FC<AuthorItemProps> = ({
        value : author, style, rootRef,
        draggable = false, draggableProps, 
        selectable = false, onSelect,
        mutable = false, onChange,
        deletable = false, onDelete,
        action = false, active = false}) => {

    const user = author.user;
    const authorPickerModal = useModalState();

    const handleRemove = useCallback(() => {
        onDelete && onDelete(author);
    }, [onDelete, author]);

    return <BaseGroupItem action={action || draggable || selectable} 
        active={active} style={style} rootRef={rootRef} 
        onClick={selectable ? (() => onSelect && onSelect(author)) : undefined}>
        <AuthorPickerModal modal={authorPickerModal} value={author} onSubmit={onChange} />
        <Row className="small">
            <Col md={2} role={mutable ? 'button' : undefined} onClick={mutable ? authorPickerModal.open : undefined} className="d-none d-md-block p-0">
                <img src={user === null ? '/images/avatars/unknown.jpg' : user.getAvatar()} className="w-100 rounded-circle" alt="Avatar" />
            </Col>
            <Col md={10 - (deletable ? 2 : 0)}><div {...(draggable ? draggableProps : {})}>{author.name}</div>
                {user === null ?  <small onClick={authorPickerModal.open} className="a">Know this author?</small> : <Obfuscate className="small" email={user.email} />}
            </Col>
            {deletable ? <Col md={2} className="text-end" role="button" onClick={handleRemove}><X width={32} /></Col> : null}
        </Row>
    </BaseGroupItem>;
};

export default AuthorItem;