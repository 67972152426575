import { Alert, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { publicationAPI } from "publications/api/entities";
import React, { useContext, useState } from 'react';
import Modal from "core/components/Modal";
import User from "directory/api/models/User";
import Publication from "publications/api/models/Publication";
import PublicationForm from "publications/forms/PublicationForm";
import { MemoFC, useCallback } from "core/api/equality";
import { CUDFormModal } from "core/modals/types";
import { ButtonType } from "core/forms/types";
import { useUpdatePublications } from "publications/hooks/publication";
import { ToasterContext } from "core/hooks/toaster";
import ConfirmationButton from "core/components/forms/ConfirmationButton";

export type PublicationFormModalProps = CUDFormModal<Publication> & {
    user : User;

    onDeny?(publication:Publication):void
};

const PublicationFormModal : React.FC<PublicationFormModalProps> = MemoFC(({
    value, user,
    modal,
    onSubmit, onCreate, onUpdate, onDelete, onDeny}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);
    const updatePublications = useUpdatePublications();
    const addToast = useContext(ToasterContext);

    const handleDeny = useCallback(async () => {
        if(value=== undefined) {
            return;
        }
        const data =  await publicationAPI.updateAuthorship(value.id, 'DENIED');
        modal.close();
        onDeny && onDeny(value);
        updatePublications(user, undefined);
        addToast({
            type : 'danger',
            title : 'Authorship removed',
            children : 'You indicated that you were not an author of ' + value.title
        })
        return data;
    }, [onDeny, modal, updatePublications, user, addToast, value]);


    const handleDelete = useCallback(async () => {
        if(value=== undefined) {
            return;
        }
        const data =  await publicationAPI.delete(value.id);
        modal.close();
        onDelete && onDelete(value);
        updatePublications(user, undefined);
        addToast({
            type : 'danger',
            title : 'Publication deleted',
            children : 'You deleted the publication ' + value.title + ' for your and all your coauthors'
        })
        return data;
    }, [onDelete, modal, updatePublications, user, addToast, value]);

    const handleSubmit = useCallback(async (publication : Publication) => {
        modal.close();
        return onSubmit && onSubmit(publication);
    }, [onSubmit, modal]);

    return <Modal state={modal} size="lg">
        <ModalHeader className="bg-light" toggle={modal.close}>{value !== undefined  ? "Edit publication" : "New publication"}</ModalHeader>

        <ModalBody>

            <PublicationForm onButtonsChange={setButtons} user={user} value={value} displayButtons={false}
                onSubmit={handleSubmit} onCreate={onCreate} onUpdate={onUpdate} />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            {value !== undefined ? <ConfirmationButton size="md" color="danger" onClick={handleDeny}
                confirmQuestion={<>
                    <p>Are you sure you this is not your publication?</p>
                    <Alert color="info">
                        <p>By clicking on "I am not an author", you will indicate that the author "{value.user_authors.filter(author => author.user && author.user.id === user.id )[0].name}" of this publication is not you.</p>
                        <p>This publication will not be deleted, and the author list will be kept unchanged, but the author will not point to you, and this publication will be removed from your list.</p>
                    </Alert>
                </>}
                >I am not an author</ConfirmationButton> : null}
            {value !== undefined ? <ConfirmationButton size="md" color="danger" onClick={handleDelete}
                confirmQuestion={<>
                    <p>Are you sure you you want to delete this publication?</p>
                    <Alert color="info">
                        <p>By clicking on "Delete", you will remove this publication from the database. In particular, it will be removed from the publication list of all your co-authors. Please, make sure that you have their agreement.</p>
                        <p>If this publication is valid, but you are not an author of it, please, click on "I am not an author". The publication will not be deleted, and the author list will be kept unchanged, but
                        "{value.user_authors.filter(author => author.user && author.user.id === user.id )[0].name}" will not point to you, and this publication will be removed from your list.</p>
                    </Alert>
                </>}
            >Delete</ConfirmationButton> : null}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
});

export default PublicationFormModal;