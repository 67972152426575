import React from 'react';
import { Stepper as RStepper } from 'react-form-stepper';
import { StepperProps as RStepperProps } from 'react-form-stepper/dist/components/Stepper/StepperTypes';


type StepperProps = RStepperProps;

export const stepStyleDefaults = {
    activeBgColor: '#4284f3', 
    activeTextColor: '#ffffff',
    completedBgColor: '#bbd1f5',
    completedTextColor: '#ffffff',
    inactiveBgColor: '#e0e0e0',
    inactiveTextColor: '#ffffff',
    size: '2em',
    circleFontSize: '1rem',
    borderRadius: '50%',
    fontWeight : '500',
    labelFontSize : '0.875rem'
};


const Stepper : React.FC<StepperProps> = (props) => {

    return <RStepper styleConfig={stepStyleDefaults} {...props} />
};

export default Stepper;