
import { BaseIdType, ObjectWithId } from "core/api/BaseAPI";
import ReadViewEntityAPI from "core/api/ReadViewEntityAPI";


class ViewEntityAPI<IdType extends BaseIdType, 
    DataType extends ObjectWithId<IdType>, 
    ShortDataType extends ObjectWithId<IdType>, 
    NewDataType, 
    UpdateDataType extends ObjectWithId<IdType>> extends ReadViewEntityAPI<IdType, DataType, ShortDataType> {

    async create(obj : NewDataType) {
        const myToken = this.token();
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.post('', 
            this._toFormData(obj), { headers });
        return new this.factory(data);
    }

    async update(obj : UpdateDataType ) {

        const myToken = this.token();
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.put( obj.id + '/', 
            this._toFormData(obj), { headers });
        return new this.factory(data);
    }

    async delete(id : IdType | ObjectWithId<IdType>) {
        if(typeof id === 'object') {
            id = id.id;
        }
        const myToken = this.token();
        const headers = {
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.delete(id + '/', { headers });
        return data;
    }

};

export default ViewEntityAPI;