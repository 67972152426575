import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from "reactstrap";
import AutoDismissAlert from "core/components/AutoDismissAlert";
import { errorToText } from "core/helpers/error";
import { userAPI } from "directory/api/entities";
import User from "directory/api/models/User";
import { useUpdateUser } from 'directory/hooks/user';
import { UForm } from 'core/forms/types';
import { useCallback } from 'core/api/equality';
import { useAction } from 'core/hooks/action';
import { ToasterContext } from 'core/hooks/toaster';
import AdjustableImageFileGroup from 'core/forms/groups/AdjustableImageFileGroup';
import {Form as FkForm, useFormik, FormikProvider} from 'formik';



export type ProfilePictureFormProps = UForm<User>;


const ProfilePictureForm : React.FC<ProfilePictureFormProps> = ({
    value : user, 
    onButtonsChange, 
    onUpdate, onSubmit, 
    displayButtons=true}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');

    const updateUser = useUpdateUser();
    const addToast = useContext(ToasterContext);

    const submitFunction :  () => void = () => submitAction.trigger();

    const formik = useFormik({
        initialValues : {
            avatar : user.avatar as string | null | File
        },
        onSubmit : submitFunction
    });

    const { values } = formik;

    const submitAction = useAction(useCallback(async () => {
        if(typeof values.avatar === 'string') {
            return;
        }
        try {
            const data = await userAPI.updateAvatar(user.id, values.avatar);
            updateUser(data);
            onUpdate && onUpdate(data);
            onSubmit && onSubmit(data);
            addToast({
                type : 'success',
                title : 'Profile picture updated',
                children : 'Profile picture successfully updated'
            })
            return data as User;
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return undefined;
    }, [values.avatar, onSubmit, onUpdate, updateUser, user.id, addToast]));


    const buttons = useMemo(() => {
        return [<Button key="save" color="primary" {...submitAction.buttonProps} disabled={submitAction.loading}>Save</Button>];
    }, [submitAction]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange]);


    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

        <FormikProvider value={formik}>
        <Form tag={FkForm}>

            <AdjustableImageFileGroup name="avatar" label="" />


            {displayButtons ? <Row className="row-cols-lg-auto">
                    <Col className="ms-auto">{buttons}</Col>
                </Row> : null }

        </Form>
        </FormikProvider>
    </>;
};

export default ProfilePictureForm;