import { Form } from "reactstrap";
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import React, { useEffect } from "react";
import { MscSubject } from "msc/api/models";
import { BaseSearchProps } from "core/forms/searches/types";
import InputGroup from "core/forms/groups/InputGroup";
import MscSubjectManyOneGroup from "msc/forms/groups/MscSubjectManyOneGroup";
import { BookCategory } from "books/api/models";
import BookCategoryManyOneGroup from "books/forms/groups/BookCategoryManyOneGroup";

export type BookFilter = {
    search : string;
    msc_subjects : MscSubject[];
    categories : BookCategory[];
};

export type BookSearchProps = BaseSearchProps<BookFilter>;

const BookSearch : React.FC<BookSearchProps> = ({
    onChange
}) => {

    const formik = useFormik<BookFilter>({
        initialValues : {
            search : '',
            msc_subjects : [],
            categories : []
        },
        onSubmit : () => {}
    });

    const { values } = formik;

    useEffect(() => {
        onChange && onChange(values)
    }, [values, onChange]);


    return <FormikProvider value={formik}>
        <Form tag={FkForm} className="mt-3">

            <InputGroup className="small" bsSize="sm" label="Search" name="search" placeholder="Filter..." />

            <MscSubjectManyOneGroup className="small" bsSize="sm" label="Research subjects" name="msc_subjects" />

            <BookCategoryManyOneGroup className="small" bsSize="sm" label="Categories" name="categories" />
        </Form>

        

    </FormikProvider>;
};

export default BookSearch;