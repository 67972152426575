import { FormGroupProps } from 'core/forms/groups/types';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { X } from 'react-bootstrap-icons';
import AvatarEditor from 'react-avatar-editor';

export type AdjustableImageFileGroupProps = FormGroupProps & {
    maxWidth? : string;
    validate? : (values : any, props : any) => string;
}

/*
Shorthand for FormGroup, Label, Input, FormFeedback
*/

const AdjustableImageFileGroup : React.FC<AdjustableImageFileGroupProps> = ({
    label, placeholder = "Drag 'n' drop an image here, or click to select images", 
    validate, maxWidth,
    name, className='', bsSize
}) => {


    const URL = window.URL || window.webkitURL;
    const canvasWidth = 286;
    const editor = useRef(null);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x : 0, y : 0});
    const { touched, errors, values, setFieldValue } = useFormikContext<object>();
    const [loaded, setLoaded] = useState(false as boolean);

    // The previous image under a string representation, or the resized file or null
    const file = values[name] as File | null | string;

    // The new flle to be uploaded
    const [newFile, setNewFile] = useState(null as File | null);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop : (acceptedFiles : File[]) => {
            setNewFile(acceptedFiles[0]);
        },
        accept: {
            'image/png': ['.png' ],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/gif' : ['.gif']
          }
    });

    useEffect(() => {
        if(newFile === null || editor === null) {
            return;
        }
        const canvas = (editor as any).current.getImageScaledToCanvas() as HTMLCanvasElement
        fetch(canvas.toDataURL()).then(result => {
            result.blob().then(blob => setFieldValue(name, new File([blob], newFile.name)));
        });    
    }, [newFile, name, setFieldValue, position, scale, loaded]);

    const removeLink = useMemo( () => {
        return <p><span className="a small" onClick={() => {setNewFile(null); setFieldValue(name, null); setLoaded(false);} }><X color="red" /> Remove</span></p>;
    }, [setNewFile, setFieldValue, name]);

    const content = useMemo( () => {

        // If no file is uploaded
        if(newFile === null) {

            // This case cannot happen
            if(file instanceof File) {
                return null;
            }
               
            // If there is no file
            if(file === null) {
                return <div {...getRootProps({className:'dropzone mt-3', role: 'button', style:{ minHeight: '100px' }})}>
                    <input {...getInputProps()} />
                    <p><img src="/images/upload.png" className="w-100 opacity-50" style={{maxWidth: '100px'}} alt="Upload" /></p>
                    <p>{placeholder}</p>
                </div>;
            }

            // If there was the previous file
            return <Row>
                <Col md={8} className="text-center">
                    <p><img style={{width : canvasWidth, height: canvasWidth}} src={file} alt="Previous" /></p>
                    {removeLink}
                </Col>
                <Col md={4}>
                    <p><img src={file} width={90} className="border mb-4" alt="Min Profile" /></p>
                    <p><img src={file} width={90} className="rounded-circle border" alt="Min Profile" /></p>
                </Col>
            </Row>;

        }
        else {

            return <Row>
                <Col md={8} className="text-center">
                    <AvatarEditor ref={editor} width={canvasWidth} height={canvasWidth} image={newFile} scale={scale} rotate={0} 
                        onLoadSuccess={() => setLoaded(true)}
                        onPositionChange={setPosition}
                        crossOrigin="anonymous" />
                    <Input id="zoom" name="zoom" type="range" style={{width : canvasWidth}} value={scale} min={0.8} max={2.5} step={0.01} 
                        onChange={(e:any) => setScale(parseFloat(e.target.value))} />
                    {removeLink}
                </Col>
                <Col md={4}>
                    <p><img src={file instanceof File ? URL.createObjectURL(file) : ''} width={90} className="border mb-4" alt="Min Profile" /></p>
                    <p> <img src={file instanceof File ? URL.createObjectURL(file) : ''} width={90} className="rounded-circle border" alt="Min Profile" /></p>
                </Col>
            </Row>;
        }
        
    }, [file, getInputProps, getRootProps, newFile, scale, placeholder, removeLink, URL]);

    return <FormGroup className={className}>
        <Label for={name}>{label}</Label>
        <div className="text-center">{content}</div>        
        <FormFeedback component="small" className="text-danger">{touched[name] && errors[name]}</FormFeedback>
    </FormGroup>
}

export default AdjustableImageFileGroup;