import { BaseAPI } from "core/api/BaseAPI";
import ShortUser from "directory/api/models/ShortUser";


class PublicationAuthorAPI extends BaseAPI {

    async getCandidates(name : string) : Promise<ShortUser[]> {
        const params = { name };
        const { data } = await this.axios.get('/candidates/', { params } );
        return data.map( (item : any) => new ShortUser(item) )
    }
}

export default PublicationAuthorAPI;