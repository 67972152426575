import User from "directory/api/models/User";
import * as React from 'react';
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from "reactstrap";
import Obfuscate from 'react-obfuscate';
import { useCallback, useMemo } from "core/api/equality";
import { BasicItemProps, SelectableItemProps } from "core/items/types";

type UserProps = BasicItemProps<User>
  & SelectableItemProps<User>;

const UserBigCard : React.FC<UserProps> = ({
    value : user,
    selectable, onSelect
  }) => {

    const handleSelect = useCallback(() => {
      onSelect && onSelect(user);
    }, [onSelect, user]);

    const affiliation = user.getPrimaryOrFirstCurrentAffiliation();

    const smallIfExists = (text : string | undefined) => 
      text ? <><br /><small className="text-muted">{text}</small></> : null;
  
    const locations = useMemo(() => {
      const locations = affiliation ? affiliation.getLocationList() : [];
      return smallIfExists(locations.join(' / '));
    }, [affiliation]);
  
    return <div className="col mb-3 btn" onClick={selectable ? handleSelect : undefined}>
        <Card style={{overflow: 'hidden'}} className="h-100">
        <div className="row g-0 align-items-center h-100" style={{minHeight: '12rem'}}>
            <div className="col-md-4">
            <img src={user.getAvatar()} width={160} className="img-fluid" alt="Avatar" />
            </div>
            <div className="col-md-8">
            <CardBody>
            <CardTitle tag="h5" className="p-3">{user.first_name} {user.last_name}
                {smallIfExists(affiliation?.position)}
            </CardTitle>
            {<CardSubtitle className="small pb-3">{affiliation ? affiliation.institution.getName() : 'No institution'}
                    {smallIfExists(affiliation?.department)}
                    {locations}</CardSubtitle>}
            {<CardText><Obfuscate email={user.email} className="small" /></CardText>}
            </CardBody>
        </div>
        </div>
        </Card>
    </div>
};

export default UserBigCard;