import { is } from "core/api/equality";
import { PublicationTypeField } from "publications/api/types";
import { MscSubject } from "msc/api/models";
import Author from "./Author";
import { ShortSerial } from "serials/api/models";


export default class Publication {
    id: number;
    citekey: string;
    user_authors: Author[];
    keywords: string[];
    type: PublicationTypeField;
    title: string;
    year: number | null;
    month: number | null;
    serial: ShortSerial | null;
    journal: string;
    book_title: string;
    publisher: string;
    institution: string;
    volume: number | null;
    number: number | null;
    pages: string;
    note: string;
    url: string;
    doi: string;
    abstract: string;
    isbn: string;
    pdf: string | null;
    thumbnail: string | null;
    msc_subjects: MscSubject[];
    arxiv_id : string | null;
    zbmath_id : string | null;
    arxiv_loaded : boolean;
    zbmath_loaded : boolean;

    constructor(json: any) {
        json.serial = json.serial === null ? null : new ShortSerial(json.serial);
        json.msc_subjects = json.msc_subjects.map((subject:any) => new MscSubject(subject));
        json.user_authors = json.user_authors.map((author: any) => new Author(author));
        Object.assign(this, json);
    }

    is(pub: Publication) {
        return this.id === pub.id
            && this.citekey === pub.citekey
            && is(this.user_authors, pub.user_authors)
            && is(this.keywords, pub.keywords)
            && this.type === pub.type
            && this.title === pub.title
            && this.year === pub.year
            && this.month === pub.month
            && this.journal === pub.journal
            && this.book_title === pub.book_title
            && this.publisher === pub.publisher
            && this.institution === pub.institution
            && this.volume === pub.volume
            && this.number === pub.number
            && this.pages === pub.pages
            && this.note === pub.note
            && this.url === pub.url
            && this.doi === pub.doi
            && this.abstract === pub.abstract
            && this.isbn === pub.isbn
            && this.pdf === pub.pdf
            && this.thumbnail === pub.thumbnail
            && this.arxiv_id === pub.arxiv_id
            && this.zbmath_id === pub.zbmath_id
            && this.arxiv_loaded === pub.arxiv_loaded
            && this.zbmath_loaded === pub.zbmath_loaded
            && is(this.msc_subjects, pub.msc_subjects);
    }

    getAuthorsFullNames(): string[] {
        const toFullName = (author: Author): string => {
            const parts = author.name.split(',');
            return parts[1] + ' ' + parts[0];
        };
        return this.user_authors.map(toFullName);
    }

    getPages(): string {
        if (this.pages.match(/^[0-9]/)) {
            return 'pp.' + this.pages.replace('--', '\u2014');
        }
        return this.pages;
    }

    static typeList: PublicationTypeField[] = [
        'ARTICLE', 'INPROCEEDINGS', 'PROCEEDINGS', 'INCOLLECTION',
        'BOOK', 'PHDTHESIS', 'MASTERTHESIS', 'MISC', 'UNPUBLISHED',
        'TECHREPORT'
    ];

    static typeToString(type: PublicationTypeField): string {
        switch (type) {
            case 'ARTICLE': return 'Article';
            case 'BOOK': return 'Book';
            case 'BOOKLET': return 'Booklet';
            case 'MANUAL': return 'Manual';
            case 'INBOOK': return 'In book';
            case 'PROCEEDINGS': return 'Proceedings';
            case 'INCOLLECTION': return 'In collection';
            case 'INPROCEEDINGS': return 'In proceedings';
            case 'MASTERTHESIS': return 'Master thesis';
            case 'PHDTHESIS': return 'PhD thesis';
            case 'MISC': return 'Miscellaneous';
            case 'TECHREPORT': return 'Technical report';
            case 'UNPUBLISHED': return 'Unpublished';
        }
    }
}
