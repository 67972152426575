import * as React from 'react';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { BasicItemProps } from "core/items/types";
import ShortInstitution from "institutions/api/models/ShortInstitution";

type ShortInstitutionCardProps = BasicItemProps<ShortInstitution>;

const ShortInstitutionCard : React.FC<ShortInstitutionCardProps> = ({value : institution}) => {
  
    return <div className="col mb-3 btn">
      <Card>
        <div className="row g-0 align-items-center" style={{minHeight: '7rem'}}>
         <div className="col-md-4">
            <img src={institution.getLogo()} width={70} className="img-fluid" alt="Logo" />
         </div>
         <div className="col-md-8">
          <CardBody>
            <CardTitle tag="h5" className="small">{institution.getName()}</CardTitle>
            {<CardSubtitle className="small">{institution.getLocationList()}</CardSubtitle>}
          </CardBody>
      </div>
      </div>
      </Card>
      </div>;
};

export default ShortInstitutionCard;