import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useState } from 'react';
import Modal, { ModalState } from "core/components/Modal";
import User from "directory/api/models/User";
import TalkListForm from "talks/forms/TalkListForm";
import { ButtonType } from "core/forms/types";

export type TalkListFormModalProps = {
    user : User;
    modal : ModalState
};

const TalkListFormModal : React.FC<TalkListFormModalProps>= ({
    user, modal}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);

    return <Modal state={modal} size="lg">
        <ModalHeader className="bg-light" toggle={modal.close}>Edit talks</ModalHeader>

        <ModalBody>
            <TalkListForm value={user} onButtonsChange={setButtons} displayButtons={false}  />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="secondary" onClick={modal.close}>Close</Button>
        </ModalFooter>
    </Modal>
};

export default TalkListFormModal;