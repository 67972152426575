import BasicInformationForm from "directory/forms/BasicInformationForm";
import React from 'react';
import { PanelProps } from 'core/pages/PanelPage';
import ChangePasswordForm from "directory/forms/ChangePasswordForm";
import ProfilePictureForm from "directory/forms/ProfilePictureForm";
import BasicForm from "core/components/forms/BasicForm";

const UserPanel : React.FC<PanelProps> = ({user}) => {

    return <div>

        <BasicForm title="Basic Information">
            <BasicInformationForm value={user} />
        </BasicForm>

        <BasicForm title="Change password">
            <ChangePasswordForm value={user} />
        </BasicForm>

        <BasicForm title="Profile Picture">
            <ProfilePictureForm value={user} />
        </BasicForm>

    </div>;
}

export default UserPanel;