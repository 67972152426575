
export const DEFAULT_POSITION_LIST = [
    'Faculty Member', 
    'Post Doctorate', 
    'Graduate Student', 
    'Undergraduate', 
    'Emeritus/Emerita', 
    'Alumnus/Alumna'
];

export const DEFAULT_DEPARTMENT_LIST = [
    'Mathematics', 
    'Computer Science'
];