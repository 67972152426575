import * as React from 'react';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { BasicItemProps } from "core/items/types";
import { ShortSerial } from 'serials/api/models';

type ShortSerialCardProps = BasicItemProps<ShortSerial>;

const ShortSerialCard : React.FC<ShortSerialCardProps> = ({value : serial}) => {

    return <div className="col mb-3 btn">
      <Card>
          <CardBody>
            <CardTitle tag="h5" className="small"><a href={serial.getURL()}>{serial.title}</a></CardTitle>
            {<CardSubtitle className="small">{serial.publisher.split(';').map(publisher => <div key={publisher}>{publisher}</div>)}</CardSubtitle>}
          </CardBody>
      </Card>
      </div>;
};

export default ShortSerialCard;