import React from 'react';
import { SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { City } from 'geonames/api/models';


export type CityItemProps = 
    SelectableItemProps<City>;

const CityItem : React.FC<CityItemProps> = ({
    value : city, style, rootRef, className,
    selectable = false, onSelect, 
    action = false, active = false}) => {

    return <BaseGroupItem active={active} action={action || selectable} className={'small ' + className} role="button" 
        onClick={selectable ? () => onSelect && onSelect(city) : undefined} 
        style={style} rootRef={rootRef}>
        {city.name}<br />
        <small className="muted">{city.region.display_name}</small>
    </BaseGroupItem>;
};

export default CityItem;