
import { createSlice } from '@reduxjs/toolkit';
import ShortUser from "directory/api/models/ShortUser";

export interface AuthSlice {
    token? : String;
    user? : ShortUser
};

const initialState = {
    token : null,
    user : null
};

const authSlice = createSlice({
    name : 'auth',
    initialState,
    reducers : {
        setAuthTokens(state, action) {
            state.token = action.payload.token
        },
        setUser(state, action) {
            state.user = action.payload;
        },
        logout(state) {
            state.token = null;
            state.user = null;
        }
    }
});



export default authSlice;