import { Col, Row } from 'reactstrap';
import React from 'react';
import { DeletableItemProps, SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { useCallback } from "core/api/equality";
import { X } from "react-bootstrap-icons";
import { ShortVideo } from 'videos/api/models/ShortVideo';

export type ShortVideoItemProps = SelectableItemProps<ShortVideo>
    & DeletableItemProps<ShortVideo>;

const ShortVideoItem : React.FC<ShortVideoItemProps> = ({
    value : video, className,
    selectable = false, onSelect, 
    deletable = false, onDelete,
    action = false, active = false, style, rootRef}) => {

    
    const handleDelete = useCallback(() => {
        onDelete && onDelete(video);
    }, [onDelete, video]);

    return <BaseGroupItem action={action || selectable} active={active} role={onSelect === undefined ? '' : 'button'} 
        onClick={selectable ? () => onSelect && onSelect(video) : undefined} style={style} rootRef={rootRef} className={className}>
        <Row className="small">
            <Col md={2} className="d-none d-md-block text-start"><img src={video.thumbnail} className="w-100" alt="Logo" /></Col>
            <Col md={10 - (deletable ? 2 : 0)}>{video.title}
            </Col>
            {deletable ? <Col md={2} className="text-end" role="button" onClick={handleDelete}><X width={32} /></Col> : null}
        </Row>
    </BaseGroupItem>;
};


export default ShortVideoItem;