
import {ListGroup} from 'reactstrap';
import React, {  useCallback, useEffect, useState } from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import _uniqueId from 'lodash/uniqueId';
import {
    DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
  import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
  } from '@dnd-kit/sortable';
import Autocomplete, { AutocompleteContext } from 'core/components/forms/Autocomplete';
import { DraggableItemProps, DeletableItemProps, MutableItemProps } from 'core/items/types';

export type ItemProps<ValueType> = 
    DraggableItemProps<ValueType> & DeletableItemProps<ValueType> & MutableItemProps<ValueType>;

export type ObjectManyOneContext<ValueType> = {
    autocompleteContext : AutocompleteContext<string>;
    exclude : ValueType[]
};

export type ObjectManyOneProps<ValueType> = {
    id: string;
    name: string;
    bsSize? : 'lg' | 'sm';
    invalid? : boolean;
    placeholder? : string;
    toValue? : (text:string) => ValueType;
    toItem : React.FC<ItemProps<ValueType>>;
    valueList : ValueType[];
    sortable? : boolean;
    deletable? : boolean;
    draggable? : boolean;
    mutable? : boolean;

    // Whether the values list is separated from the search field
    // Default : false
    disjoint? : boolean;

    // Whether the object should be unique or not
    // Default : false
    // If set to true, the completion will remove from the proposed values the ones already present
    unique? : boolean;

    // Callback called when a mutable item changes
    onItemChange? : (value:ValueType) => void;

    // Callback to call when a new item is created
    // Must have toValue non-empty
    onCreate? : (value:ValueType) => void;

    // Callback to call when an item is removed from the list
    onDelete? : (value:ValueType) => void;

    // Callback to call when an item is rearranged in the list (by drag & drop)
    onReorder? : (values:ValueType[]) => void;

    children? : (ctx:ObjectManyOneContext<ValueType>) => React.ReactNode;

};


type OuterItemProps<ValueType> = {
    id : string;
    value : ValueType;
    toItem : React.FC<ItemProps<ValueType>>;
};


const uid_decorator = <ValueType, >(values:ValueType[]) : Array<{ value: ValueType, id : string }> => {
    return values.map(value => ({
        value,
        id : _uniqueId('my-item')
    }));
}

const ObjectManyOne = <ValueType, >(props : ObjectManyOneProps<ValueType>) => {

    const {id, name, invalid, placeholder, valueList, 
        toItem, toValue,
        children,
        bsSize,
        disjoint=false,
        sortable=false, deletable = false, draggable = false, mutable = false,
        unique = false,
        onCreate, onDelete, onReorder, onItemChange} = props;

    const [editingText, setEditingText] = useState('');
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
      );
    const [decoratedValueList, setDecoratedValueList] = useState([] as Array<{ value: ValueType, id : string }>);

    useEffect(() => {
        setDecoratedValueList(uid_decorator(valueList));
        setEditingText('');
    }, [valueList]);

    const handleAdd = useCallback((text:string) => {
        if(toValue === undefined) return;
        onCreate && onCreate(toValue(text));
        setEditingText('');
    }, [onCreate, toValue]);

    const handleReorder = useCallback((event:any) => {
        const {active, over} = event;
    
        if (active.id !== over.id) {

            const ids = decoratedValueList.map(item => item.id);
            const oldIndex = ids.indexOf(active.id);
            const newIndex = ids.indexOf(over.id);
            onReorder && onReorder(arrayMove(valueList, oldIndex, newIndex));
       
        }
       
    }, [decoratedValueList, onReorder, valueList]);

    const handleRemove = (value : ValueType) => {
        onDelete && onDelete(value);
    };

    const SortableItem : React.FC<OuterItemProps<ValueType>> = ({id, value, toItem}) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
          } = useSortable({id: id, disabled : !sortable});

        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
        };

        return toItem({
            rootRef : setNodeRef,
            value : value,
            style : style,
            draggableProps : {...attributes, ...listeners},
            deletable,
            draggable : draggable || sortable,
            mutable,
            onDelete : handleRemove,
            onChange : onItemChange
        });
    };

    return <div className={invalid ? 'is-invalid' : ''}>
       <div className={'app-manyone' + (disjoint ? ' app-manyone-disjoint' : '')}>
            <Autocomplete name={name} id={id} placeholder={placeholder} invalid={invalid} value={editingText} 
                inputStyle={{ borderBottomLeftRadius : 0, borderBottomRightRadius : 0, borderBottom: valueList.length ? 0 : undefined }}
                buttonStyle={{ borderBottomRightRadius : 0, borderBottom: 0}}
                onChange={value => setEditingText(value)}
                addable={true}
                bsSize={bsSize}
                onAdd={handleAdd}>
                {children && (ctx => children({ autocompleteContext : ctx, exclude : unique ? valueList : [] }))}
            </Autocomplete>
            <div className="app-manyone-content">
                <ListGroup style={{ borderRadius : 0}}>
                <DndContext 
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleReorder}
                >
                <SortableContext 
                    items={decoratedValueList.map(item => item.id)}
                    strategy={verticalListSortingStrategy}
                >
                    {decoratedValueList.map((item, index) => {
                        return <SortableItem key={item.id} id={item.id} value={item.value} toItem={toItem} />
                    })}
                </SortableContext>
                </DndContext>
                </ListGroup>
            </div>
            </div>
        </div>;

};

export default ObjectManyOne;