import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route } from 'react-router';
import { Outlet } from 'react-router-dom';
import { AuthState } from 'store';


interface ProtectedRouteProps {
    children : React.ReactNode;
    path? : string;
};

const ProtectedRoute : React.FC<ProtectedRouteProps> = (props) => {

    const auth = useSelector((state:AuthState) => state.auth);

    if(!auth.user) {
        return <Navigate to={'/auth/login'} replace />;
    }

    if(props.path === '/auth/login' ) {
        return <Navigate to={'/'} replace />;
    }

    if(props.path) {
        return <Route {...props} />;
    }
    
    if(props.children) {
        return <>{props.children}</>;
    }
    
    return <Outlet />;
};

export default ProtectedRoute;