import { FormGroupProps } from 'core/forms/groups/types';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';


export type InputGroupProps = FormGroupProps;

const CheckGroup : React.FC<InputGroupProps> = ({
    label, name, 
    className='', bsSize
}) => {

    const { touched, errors } = useFormikContext<object>();

    return <FormGroup check inline className={className}>
        <Input id={name} tag={Field} bsSize={bsSize} name={name} type="checkbox" invalid={touched[name] && name in errors} />
        <Label for={name}>{label}</Label>
        <FormFeedback component="small" className="text-danger">{touched[name] && errors[name]}</FormFeedback>
    </FormGroup>
}

export default CheckGroup;