import React, { useRef } from 'react';
import { DeletableItemProps, SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { Col, Row } from 'reactstrap';
import { useCallback } from 'core/api/equality';
import { X } from 'react-bootstrap-icons';
import { useWidth } from 'core/hooks/width';
import { Language } from 'languages/api/models';


export type LanguageItemProps = 
    SelectableItemProps<Language> & 
    DeletableItemProps<Language>;

const LanguageItem : React.FC<LanguageItemProps> = ({
    value : language, style, rootRef, className,
    selectable = false, onSelect, 
    deletable = false, onDelete,
    action = false, active = false}) => {

    // Hack to be responsive
    const row = useRef(null);
    const width = useWidth(row);
    const small = width < 500;

    const handleRemove = useCallback(() => {
        onDelete && onDelete(language);
    }, [onDelete, language]);

    return <BaseGroupItem active={active} action={action || selectable} className={'small ' + className} role="button" 
        onClick={selectable ? () => onSelect && onSelect(language) : undefined} 
        style={style} rootRef={rootRef}>
        <Row>
            <Col md={12 - (deletable ? (small ? 2 : 1) : 0)}>{language.name}
            </Col>
            {deletable ? <Col md={small ? 2 : 1} className={'text-end' + (small ? ' p-0' : '')} role="button" onClick={handleRemove}><X width={32} /></Col> : null}
        </Row>
    </BaseGroupItem>;
};

export default LanguageItem;