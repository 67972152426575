import EntityAPI from 'core/api/EntityAPI';
import Publication from "publications/api/models/Publication";
import { AuthorshipValidationType, NewPublicationType, UpdatePublicationType } from "publications/api/types";

class PublicationAPI extends EntityAPI<number, Publication, NewPublicationType, UpdatePublicationType> {
    
    
    async batch(entries : NewPublicationType[]) : Promise<Publication[]> {
        const myToken = this.token();
        const headers = {
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.post('/batch/', 
            {entries}, { headers });
        return data;
    }

    async updateAuthorship(publication_id : number, validation : AuthorshipValidationType) : Promise<void> {
        const myToken = this.token();
        const form = new FormData();
        form.append('validation', validation);
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `token ${myToken}`
        };
        await this.axios.put(publication_id + '/authorship/', form, { headers });
    }
    
};

export default PublicationAPI;
