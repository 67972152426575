import React, {useContext, useEffect, useMemo, useState} from "react";
import {Row, Col, Button} from 'reactstrap';
import AutoDismissAlert from "core/components/AutoDismissAlert";
import { userAPI } from 'directory/api/entities';
import { errorToText } from "core/helpers/error";
import { useAction } from "core/hooks/action";
import { MarkdownEditor } from "core/components/Markdown";
import { fetchUserAboutKey } from "directory/queryKeys";
import { useQuery, useQueryClient } from "react-query";
import User from "directory/api/models/User";
import { BaseFormProps } from "core/forms/types";
import { useCallback } from "core/api/equality";
import { ToasterContext } from "core/hooks/toaster";

export type AboutFormProps = BaseFormProps<User, string>;


const AboutForm : React.FC<AboutFormProps> = ({
    value : user, 
    onButtonsChange,
    onSubmit, 
    displayButtons = true}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [value, setValue] = useState('');
    const queryClient = useQueryClient();
    const addToast = useContext(ToasterContext);

    const { data : about } = 
        useQuery([fetchUserAboutKey, user.id], () => userAPI.getAbout(user.id));

    useEffect(() => {
        setValue(about);
    }, [about]);

    const submitHandler = useAction(useCallback(async () => {
        try {
            await userAPI.updateAbout(user.id, value);
            queryClient.setQueryData([fetchUserAboutKey, user.id], value);
            addToast({
                type : 'success',
                title : 'About Tab updated',
                children : 'About Tab successfully updated'
            })
            return value;
        }
        catch(e) {
            setAlertMessage(errorToText(e));
            setAlertColor('danger');
            setAlertVisible(true);
        }
        return;
        
    }, [queryClient, user.id, value, addToast]));

    const buttons = useMemo(() => {
        return [<Button key="save" color="primary" {...submitHandler.buttonProps}>Save</Button>];
    }, [submitHandler]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange]);

    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>
        
        <MarkdownEditor 
                className="pb-3"
                value={value}
                onChange={setValue} />

            {displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
            </Row> : null}

    </>;

};

export default AboutForm;