import { Routes, Route } from "react-router-dom";
import React from 'react';
import LoginPage from "auth/pages/LoginPage";
import ForgotPasswordPage from "auth/pages/ForgotPasswordPage";
import ResetPasswordPage from "auth/pages/ResetPasswordPage";
import NotFoundErrorPage from "core/pages/errors/NotFoundErrorPage";

const AuthRoute = () => {

    return <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path="/forgottenPassword" element={<ForgotPasswordPage />} />
        <Route path="/resetPassword" element={<ResetPasswordPage />} />
        <Route path='*' element={<NotFoundErrorPage />} />
    </Routes>;

};

export default AuthRoute;