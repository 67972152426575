import { Form } from "reactstrap";
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import React, { useEffect } from "react";
import { MscSubject } from "msc/api/models";
import { BaseSearchProps } from "core/forms/searches/types";
import InputGroup from "core/forms/groups/InputGroup";
import MscSubjectManyOneGroup from "msc/forms/groups/MscSubjectManyOneGroup";
import ShortUserManyOneGroup from "directory/forms/groups/ShortUserManyOneGroup";
import ShortUser from "directory/api/models/ShortUser";
import { ShortSerial } from "serials/api/models";
import ShortSerialManyOneGroup from "serials/forms/groups/ShortSerialManyOneGroup";

export type PublicationFilter = {
    search : string;
    msc_subjects : MscSubject[];
    authors : ShortUser[];
    serials : ShortSerial[];
};

export type PublicationSearchProps = BaseSearchProps<PublicationFilter>;

const PublicationSearch : React.FC<PublicationSearchProps> = ({
    onChange
}) => {

    const formik = useFormik<PublicationFilter>({
        initialValues : {
            search : '',
            msc_subjects : [],
            authors : [],
            serials : []
        },
        onSubmit : () => {}
    });

    const { values } = formik;

    useEffect(() => {
        onChange && onChange(values)
    }, [values, onChange]);


    return <FormikProvider value={formik}>
        <Form tag={FkForm} className="mt-3">

            <InputGroup className="small" bsSize="sm" label="Search" name="search" placeholder="Filter..." />

            <MscSubjectManyOneGroup className="small" bsSize="sm" label="Research subjects" name="msc_subjects" />

            <ShortUserManyOneGroup className="small" bsSize="sm" label="Authors" name="authors" />

            <ShortSerialManyOneGroup className="small" bsSize="sm" label="Journals" name="serials" />
        </Form>

        

    </FormikProvider>;
};

export default PublicationSearch;