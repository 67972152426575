import { PublicationTypeField } from "publications/api/types";

export type FieldListType =  'authors' | 'keywords' | 'type' | 'title' 
| 'year' | 'month' | 'journal' | 'book_title' | 'publisher' | 'editor' | 'series' | 'organization' | 'howpublished'
| 'institution' | 'volume' | 'number' | 'pages' | 'note' | 'url' | 'doi' | 'abstract' | 'isbn' | 'pdf';

export type TypeSchema = {
    required : FieldListType[],
    optional : FieldListType[]
};

export type BibtexSchemaType = Record<PublicationTypeField, TypeSchema>

export const BibtexSchema : BibtexSchemaType = {

    "ARTICLE" : {
        required : ['authors', 'title', 'journal', 'year'],
        optional : ['volume', 'number', 'pages', 'month', 'note']
    },

    "INPROCEEDINGS" : {
        required : ['authors', 'title', 'book_title', 'year'],
        optional : ['editor', 'pages', 'organization','publisher', 'month', 'note']
    },

    "PROCEEDINGS" : {
        required : ['title', 'year'],
        optional : ['editor', 'publisher', 'organization', 'month', 'note']
    },

    'INBOOK' : {
        required : ['editor', 'title', 'publisher', 'year'],
        optional : ['editor', 'pages', 'volume', 'number', 'series', 'month', 'note']
    },

    'BOOKLET' : {
        required : ['title'],
        optional : ['year', 'month', 'howpublished', 'note']
    },

    'MANUAL' : {
        required : ['title'],
        optional : ['organization', 'howpublished', 'month', 'year', 'note']
    },

    "INCOLLECTION" : {
        required : ['authors', 'title', 'book_title', 'year'],
        optional : ['editor', 'pages', 'institution', 'publisher', 'month', 'note']
    },

    "BOOK" : {
        required : ['authors', 'title', 'publisher', 'year'],
        optional : ['editor', 'volume', 'month', 'note', 'isbn']

    },

    "PHDTHESIS" : {
        required : ['authors', 'title', 'institution', 'year'],
        optional : ['month', 'note']
    },

    "MASTERTHESIS" : {
        required : ['authors', 'title', 'institution', 'year'],
        optional : ['month', 'note']
    },

    "MISC" : {
        required : [],
        optional : ['authors', 'title', 'month', 'year', 'note'],
    },

    "UNPUBLISHED" : {
        required : ['authors', 'title', 'note'],
        optional : ['month', 'year']

    },

    "TECHREPORT" : {
        required : ['authors', 'title', 'institution', 'year'],
        optional : ['number', 'month', 'note']
    }

};