import { talkAPI } from "talks/api/entities";
import User from "directory/api/models/User";
import TalkCard from 'talks/cards/TalkCard';
import * as React from 'react';
import { useQuery } from 'react-query';
import { fetchTalksKey } from "talks/queryKeys";

type TabProps = {
    user : User;
    name : string;
}

const TalksTab : React.FC<TabProps> = ({user, name}) => {

    const { data : talks } = 
        useQuery([fetchTalksKey, user.id], () => talkAPI.getAll({
            user_id : user.id,
            limit : 50
        }));

    if(!talks || talks.count === 0) {
        return <p>No talk</p>;
    }

    /*
    return <ListGroup>{talks.map(talk => (
        <TalkListItem key={talk.id} talk={talk} />
        ))}</ListGroup>;
    */

    return <div className="row row-cols-2 row-cols-lg-3 row-cols-md-2 row-cols-lg-3">{talks.results.map(talk => (
        <TalkCard key={talk.id} value={talk} />
        ))}</div>;
};

export default TalksTab;
