import { Form } from "reactstrap";
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import React, { useEffect } from "react";
import { BaseSearchProps } from "core/forms/searches/types";
import InputGroup from "core/forms/groups/InputGroup";

export type PlaylistVideoFilter = {
    search : string;
};

export type PlaylistVideoSearchProps = BaseSearchProps<PlaylistVideoFilter>;

const PlaylistVideoSearch : React.FC<PlaylistVideoSearchProps> = ({
    onChange
}) => {

    const formik = useFormik<PlaylistVideoFilter>({
        initialValues : {
            search : ''
        },
        onSubmit : () => {}
    });

    const { values } = formik;

    useEffect(() => {
        onChange && onChange(values)
    }, [values, onChange]);


    return <FormikProvider value={formik}>
        <Form tag={FkForm} className="mt-3">

            <InputGroup className="small" bsSize="sm" label="Search" name="search" placeholder="Filter..." />

        </Form>

    </FormikProvider>;
};

export default PlaylistVideoSearch;