import React, { useEffect, useMemo, useState } from "react";
import {Button, Col, Row, Table} from 'reactstrap';
import { useModalState } from "core/components/Modal";
import { useAction } from "core/hooks/action";
import { useQuery } from "react-query";
import { useCallback } from "core/api/equality";
import { ZeroFormProps } from "core/forms/types";
import { fetchBookListKey } from "books/queryKeys";
import { bookAPI } from "books/api/entities";
import BookFormModal from "books/modals/BookFormModal";
import BookItem from "books/items/BookItem";
import { Book } from "books/api/models";
import ImportFormModal from "books/modals/ImportFormModal";

export type BookListFormProps = ZeroFormProps<void>;

const BookListForm : React.FC<BookListFormProps> = ({
    displayButtons=true,
    onButtonsChange
}) => {

    const { data : books, isLoading, isError } = useQuery([fetchBookListKey], () => bookAPI.getAll({}));
    const [editedBook, setEditedBook] = useState(undefined as Book | undefined);
    const bookModal = useModalState();
    const importModal = useModalState();
    
    const createHandler = useAction(useCallback(async () => {
        setEditedBook(undefined);
        bookModal.open();
        return null;
    }, [bookModal]));

    const importHandler = useAction(useCallback(async () => {
        importModal.open();
        return null;
    }, [importModal]));

    const editBook = useCallback((book : Book) => {
        setEditedBook(book);
        bookModal.open();
    }, [bookModal]);


    const buttons = useMemo(() => {
        return [
            <Button key="import" color="secondary" className="me-2" {...importHandler.buttonProps}>Import books</Button>,
            <Button key="new" color="primary" {...createHandler.buttonProps}>New book</Button>
        ];
    }, [createHandler, importHandler]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [onButtonsChange, buttons]);

    if(isLoading || isError || books === undefined)
        return null;
  
    return <>

        <BookFormModal modal={bookModal} value={editedBook} />
        <ImportFormModal modal={importModal} />

        {displayButtons ? <Row className="row-cols-lg-auto  mb-3">
            <Col className="ms-auto">{buttons}</Col>
        </Row> : null}

        <Table striped hover={books.count > 0} className="border bg-white">
            <thead>
            <tr>
                <th>Books</th>
            </tr>
            </thead>
            <tbody>
            {books.count === 0 ? (<tr><td className="text-center small">You have no book yet</td></tr>)
            : (books.results.map(book => (
                <tr key={book.id}>
                    <td><BookItem value={book} selectable={true} onSelect={editBook} /></td>
                </tr>
            )))}
            </tbody>
        </Table>
    </>;

};

export default BookListForm;