import {Form, FormGroup, Label, FormFeedback, Row, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {Form as FkForm, useFormik, FormikProvider} from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as Yup from 'yup';
import AutoDismissAlert from 'core/components/AutoDismissAlert';
import { errorToText } from 'core/helpers/error';
import Author from "publications/api/models/Author";
import ShortUser from "directory/api/models/ShortUser";
import InputGroup from 'core/forms/groups/InputGroup';
import ObjectManyOneField from 'core/components/forms/ObjectManyOneField';
import ShortUserCompletion from 'directory/completions/ShortUserCompletion';
import AuthorItem from 'publications/items/AuthorItem';
import { CUForm } from 'core/forms/types';
import { useAction } from 'core/hooks/action';
import { ToasterContext } from 'core/hooks/toaster';
import { MscSubject } from 'msc/api/models';
import { Book, BookCategory } from 'books/api/models';
import { NewBookType, UpdateBookType } from 'books/api/types';
import { Language } from 'languages/api/models';
import { bookAPI } from 'books/api/entities';
import LanguageSelectGroup from 'languages/forms/groups/LanguageSelectGroup';
import { useUpdateBooks } from 'books/hooks/book';
import MscSubjectManyOneGroup from 'msc/forms/groups/MscSubjectManyOneGroup';
import BookCategoryManyOneGroup from 'books/forms/groups/BookCategoryManyOneGroup';
import MarkdownGroup from 'core/forms/groups/MarkdownGroup';
import ImageFileGroup from 'core/forms/groups/ImageFileGroup';
import { ObjectWithId } from 'core/api/BaseAPI';

export type BookFormProps = CUForm<Book>;


// In this type, number | string is a subtitute for number | null
// Since input fields don't accept null values
// We therefore convert null into the empty string
// And we convert back every string to the null value 
type BookFormType = {
    id? : number,
    title : string;
    subtitle : string;
    authors: Author[];
    isbn : string;
    google_books_id : string;
    zbmath_id : number | null;
    categories : BookCategory[];
    description : string;
    publisher : string;
    year : number | string;
    month : number | string;
    thumbnail_url : undefined,
    thumbnail : File | null | string;
    page_count : number;
    language : Language | null;
    msc_subjects : MscSubject[];
};


const BookConverter = {

    undefinedToFormType : () : BookFormType => {
        return {
            title : '',
            subtitle : '',
            authors: [],
            isbn : '',
            google_books_id : '',
            zbmath_id : null,
            categories : [],
            description : '',
            publisher : '',
            year : '',
            month : '',
            thumbnail : null,
            thumbnail_url : undefined,
            page_count : 0,
            language : null,
            msc_subjects : []
       }; 
    },

    bookToFormType : (book:Book) : BookFormType => {
        return {
            ...book,
            authors : book.user_authors,
            year : book.year === null ? '' : book.year,
            month : book.month === null ? '' : book.month,
            thumbnail_url : undefined,
            thumbnail : book.thumbnail,
            zbmath_id : book.zbmath_id
        };
    },

    formTypeToNewBook : (values:BookFormType) : NewBookType => {
        return {
            ...values,
            authors : values.authors.map(author => ({
                name : author.name,
                user_id : author.user === null ? null : author.user.id
            })),
            thumbnail : typeof values.thumbnail === 'string' ? undefined : values.thumbnail,
            msc_subject_codes : values.msc_subjects.map(subject => subject.code),
            category_ids : values.categories.map(category => category.id),
            categories : undefined,
            msc_subjects : undefined,
            language : undefined,
            user_authors : undefined,
            year : typeof values.year === 'string' ? null : values.year,
            month : typeof values.month === 'string' ? null : values.month,
            language_id : values.language === null ? null : values.language.id
        } as NewBookType;
    },

    formTypeToUpdateBook : (values:BookFormType & ObjectWithId<number>) : UpdateBookType => {
        return {
            ...values,
            authors : values.authors.map(author => ({
                name : author.name,
                user_id : author.user === null ? null : author.user.id
            })),
            thumbnail : typeof values.thumbnail === 'string' ? undefined : values.thumbnail,
            msc_subject_codes : values.msc_subjects.map(subject => subject.code),
            category_ids : values.categories.map(category => category.id),
            categories : undefined,
            msc_subjects : undefined,
            language : undefined,
            user_authors : undefined,
            year : typeof values.year === 'string' ? null : values.year,
            month : typeof values.month === 'string' ? null : values.month,
            language_id : values.language === null ? null : values.language.id
        } as UpdateBookType;
    }
};


const BookForm : React.FC<BookFormProps> = ({ 
    value : book,
    onButtonsChange,
    onCreate, 
    onUpdate,
    onSubmit,
    displayButtons=true}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    //const [bookCover, setBookCover] = useState(undefined as File | undefined);
    const addToast = useContext(ToasterContext);
    const updateBooks = useUpdateBooks();

    const [activeTab, setActiveTab] = useState(0 as 0 | 1 | 2);

    const initialValues = useMemo(() => (
        book === undefined ? 
            BookConverter.undefinedToFormType() :
            BookConverter.bookToFormType(book)
    ), [book]);

    const submitFunction :  () => void = () => submitAction.trigger();

    const formik = useFormik({

        initialValues,
        validationSchema : Yup.lazy(values => Yup.object({
            title : Yup.string().required()
        })),
        onSubmit : submitFunction
    });

    const {values, errors, setFieldValue, isValid, setSubmitting } = formik;

    const handleCreate = useCallback(async (book: NewBookType) => {
        const newBook =  await bookAPI.create(book);
        onCreate && onCreate(newBook);
        onSubmit && onSubmit(newBook);
        updateBooks()
        addToast({
            type : 'success',
            title : 'Book created',
            children : 'Book successfully created'
        })
        return newBook;
    }, [onCreate, onSubmit, addToast, updateBooks]);

    const handleUpdate  = useCallback(async (book : UpdateBookType) => {
        const updateBook =  await bookAPI.update(book);
        onUpdate && onUpdate(updateBook);
        onSubmit && onSubmit(updateBook);
        updateBooks()
        addToast({
            type : 'success',
            title : 'Book updated',
            children : 'Book successfully updated'
        })
        return updateBook;
    }, [onUpdate, onSubmit, addToast, updateBooks]);

    const submitAction = useAction<Book>(useCallback(async () => {

        setSubmitting(false);
        try {
            if(values.id !== undefined) {
                return handleUpdate(
                    BookConverter.formTypeToUpdateBook(
                        values as BookFormType & ObjectWithId<number>)
                );
            }
            return handleCreate(
                BookConverter.formTypeToNewBook(values)
            );
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return undefined;
    }, [handleCreate, handleUpdate, setSubmitting, values]));

    const buttons = useMemo(() => {
        return [<Button key="save" color="primary" {...submitAction.buttonProps} disabled={!isValid || submitAction.loading}>Save</Button>];
    }, [submitAction, isValid]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange])


    const newAuthor = useCallback((value:string) : Author => {
        return new Author({
            name : value,
            user : null
        });
    }, []);

    const newUserAuthor = useCallback((user:ShortUser) : Author => {
        return new Author({
            name : user.last_name + ', ' + user.first_name,
            user
        });
    }, []);

    const handleAuthorChange = useCallback((author:Author) : void => {
        const newAuthors = values.authors.map(auth => auth.name === author.name ? author : auth );
        setFieldValue('authors', newAuthors);
    }, [setFieldValue, values.authors]);


    // enableReinitialize={true}
    return <>
    <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

    <FormikProvider value={formik}>
        <Form tag={FkForm}>

            <Nav tabs>
                <NavItem><NavLink href="#" active={activeTab === 0} onClick={() => setActiveTab(0)}>Book</NavLink></NavItem>
                <NavItem><NavLink href="#" active={activeTab === 1} onClick={() => setActiveTab(1)}>Cover and Description</NavLink></NavItem>
                <NavItem><NavLink href="#" active={activeTab === 2} onClick={() => setActiveTab(2)}>Metadata</NavLink></NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="pt-3">
                <TabPane tabId={0}>

                    <InputGroup name="title" label="Title" placeholder="Title" />

                    <InputGroup name="subtitle" label="Subtitle" placeholder="Subtitle" />

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="title">Authors</Label>
                                <div className="form-control p-3 bg-light">
                                <ObjectManyOneField id="authors" name="authors" 
                                    toValue={newAuthor} toItem={AuthorItem} 
                                    sortable={true} deletable={true} mutable={true}
                                    onItemChange={(author:Author) => handleAuthorChange(author)}
                                    placeholder="Last name, First name" invalid={'authors' in errors}>
                                    {ctx => <ShortUserCompletion text={ctx.autocompleteContext.value} onSelect={user => {
                                        setFieldValue('authors', [...values.authors, newUserAuthor(user) ] )
                                    }}/>}
                                </ObjectManyOneField>
                                <FormFeedback component="small" className="text-danger">{typeof errors.authors === 'string' ? errors.authors : ''}</FormFeedback>
                                </div>
                            </FormGroup>
                        </Col>

                        <Col md={6}>

                            <InputGroup name="publisher" label="Publisher"  />


                            <InputGroup name="isbn" label="ISBN" />

                            <Row>
                                <Col md="4"><InputGroup label="Year" name="year" /></Col>
                                <Col md="4"><InputGroup label="Month" name="month" /></Col>
                                <Col md="4"><InputGroup label="Page count" name="page_count" /></Col>
                            </Row>

                            <LanguageSelectGroup name="language" label="Language" />
                            
                        </Col>
                    </Row>

                </TabPane>

                <TabPane tabId={1}>

                    <ImageFileGroup name="thumbnail" maxWidth="150px" label="Book cover" />
                    <Row><MarkdownGroup name="description" label="Description" /></Row>
                </TabPane>

                <TabPane tabId={2}>
                    <Row>
                        <Col><InputGroup name="google_books_id" label="Google Books ID"  /></Col>
                        <Col><InputGroup name="zbmath_id" label="ZbMath ID"  /></Col>
                    </Row>
                    <Row><BookCategoryManyOneGroup name="categories" label="Categories" /></Row>
                    <Row><MscSubjectManyOneGroup name="msc_subjects" label="MSC Subjects" /></Row>
                </TabPane>
            </TabContent>

        

            { displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
            </Row> : null}
        </Form>
    </FormikProvider>
    </>;
};


export default BookForm;