import { CompletionProps } from 'core/completions/types';
import StringItem from 'core/items/StringItem';
import React from 'react';
import { ListGroup } from 'reactstrap';

export type StringCompletionProps = CompletionProps<string> & {
    emptyList : string[];
};

const StringCompletion : React.FC<StringCompletionProps> = ({
    text, onSelect, 
    emptyList, selectedValue, limit = 5
}) => {

    const list = emptyList.filter(value => value.toLowerCase().includes(text.toLowerCase()));
    
    return <ListGroup>
        {list.map(value => (
            <StringItem action key={value} value={value} 
                active={(selectedValue !== undefined) && selectedValue === value}
                selectable={true}
                onSelect={onSelect} />
        ))}
    </ListGroup>;

};

export default StringCompletion;