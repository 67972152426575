import { useCallback } from 'core/api/equality';
import ObjectManyOneField from 'core/components/forms/ObjectManyOneField';
import Institution from "institutions/api/models/Institution";
import { CompletionProps } from 'core/completions/types';
import { ManyOneFormGroupProps } from 'core/forms/groups/types';
import { SelectableItemProps } from 'core/items/types';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';

export type ManyOneGroupProps<ValueType> = ManyOneFormGroupProps & {
    Item : React.FC<SelectableItemProps<ValueType>>;
    Completion : React.FC<CompletionProps<ValueType>>;

};

const ManyOneGroup = <ValueType, >({ 
    label, placeholder = 'Select a value', name,
    className,
    Completion, Item, bsSize, disjoint,
} : ManyOneGroupProps<ValueType>) => {

    const { values, errors, setFieldValue } = useFormikContext<object>();

    const setValue = useCallback((value:ValueType) => {
        setFieldValue(name, [...values[name], value ] );
    }, [setFieldValue, values, name]);

    return <FormGroup className={className}>
        <Label for={name}>{label}</Label>
        <ObjectManyOneField 
            bsSize={bsSize}
            placeholder={placeholder}
            id={name} name={name} invalid={name in errors} 
            toItem={Item}
            unique={true}
            disjoint={disjoint}
            deletable={true}
            >
        {ctx => <Completion text={ctx.autocompleteContext.value} exclude={ctx.exclude} onSelect={setValue} />}
        </ObjectManyOneField>
        <FormFeedback component="small" className="text-danger">{(errors[name] as Institution)?.id}</FormFeedback>
    </FormGroup>
};

export default ManyOneGroup;

