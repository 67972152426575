
import { Routes, Route } from "react-router-dom";
import React from 'react';
import BookListPage from 'books/pages/BookListPage';
import BookPage from "books/pages/BookPage";

const BooksRoute : React.FC<{}> = () => {

    return <Routes>
        <Route path="/:bookId" element={<BookPage />} />
        <Route path="/" element={<BookListPage />} />
    </Routes>;

};

export default BooksRoute;