import { is } from "core/api/equality";
import { City } from "geonames/api/models";


export default class ShortInstitution {
    id: number;
    name_en: string;
    name_local: string;
    logo : string | null;
    website: string;
    city: City | null;

    constructor(json: any) {
        json.city = json.city === null ? null : new City(json.city);
        Object.assign(this, json);
    }

    is(inst: ShortInstitution): boolean {
        return this.id === inst.id
            && this.name_en === inst.name_en
            && this.name_local === inst.name_local
            && is(this.city, inst.city)
            && this.website === inst.website
            && this.logo === inst.logo;
    }

    getLogo(): string {
        if (this.logo === null) {
            return '/images/institutions/default_logo.svg';
        }
        return this.logo;
    }

    getName(): string {
        return this.name_local === '' ? this.name_en : this.name_local;
    }

    getLocationList(): string[] {
        if (this.city === null)
            return ['Unknown location'];
        return [this.city.name, this.city.region.name, this.city.region.country.name];
    }
}
