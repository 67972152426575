import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
  } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice, {AuthSlice} from "store/slices/auth";

export interface AuthState {
    auth : AuthSlice
};

const rootReducer = combineReducers({
    auth: authSlice.reducer,
});


// Transforms our reducer into a persisted one
const persistedReducer = persistReducer(
    {
        key : 'root',
        version : 1,
        storage : storage
    },
    rootReducer
);

const store = configureStore({
    reducer : persistedReducer,
    middleware : getDefaultMiddleware({
        serializableCheck : {
            ignoredActions : [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        }
    })
});

export const persistor = persistStore(store);
export default store;