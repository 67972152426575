import { _hyperlinkToId } from "core/api/models";
import ShortInstitution from "institutions/api/models/ShortInstitution";


export default class Affiliation {
    id: number;
    institution: ShortInstitution;
    user: string;
    position: string;
    department: string;
    start_date: Date | null;
    end_date: Date | null;

    constructor(json: any) {
        json.institution = new ShortInstitution(json.institution);
        json.start_date = json.start_date === null ? null : new Date(json.start_date);
        json.end_date = json.end_date === null ? null : new Date(json.end_date);
        Object.assign(this, json);
    }

    is(aff: Affiliation): boolean {
        return this.id === aff.id
            && this.institution.is(aff.institution)
            && this.user === aff.user
            && this.position === aff.position
            && this.department === aff.department
            && ((this.start_date === null && aff.start_date === null)
                || (this.start_date !== null && aff.start_date !== null
                    && this.start_date.getTime() === aff.start_date.getTime()))
            && ((this.end_date === null && aff.end_date === null)
                || (this.end_date !== null && aff.end_date !== null
                    && this.end_date.getTime() === aff.end_date.getTime()));
    }

    /*
        Returns a sublist of [city, state, country] for the defined values
    */
    getLocationList(): string[] {
        return this.institution.getLocationList();
    }

    /*
        Returns the period into a human readable value
    */
    getHumanPeriod(): string {
        if (this.start_date === null) {
            if (this.end_date === null) {
                return 'Current period';
            }
            else {
                return 'Until ' + this._toHumanYearMonth(this.end_date);
            }
        }
        else {
            if (this.end_date === null) {
                return 'Since ' + this._toHumanYearMonth(this.start_date);
            }
            else {
                return 'From ' + this._toHumanYearMonth(this.start_date) +
                    ' to ' + this._toHumanYearMonth(this.end_date);
            }
        }
    };

    /*
        Checks whether the affiliation ends later than the current date, or has no end date
    */
    isCurrent() {
        return this.end_date === null || (this.end_date) > (new Date());
    }

    _toHumanYearMonth(date: Date) {
        return date.toLocaleString('en-us', { month: 'short', year: 'numeric' });
    }

    getUserId(): number {
        return _hyperlinkToId(this.user) || 0;
    }
};
