import AffiliationFormModal from "directory/modals/AffiliationFormModal";
import React, { useEffect, useMemo, useState } from "react";
import {Button, Col, Row, Table} from 'reactstrap';
import { useModalState } from "core/components/Modal";
import { useAction } from "core/hooks/action";
import User from "directory/api/models/User";
import Affiliation from "directory/api/models/Affiliation";
import AffiliationItem from "directory/items/AffiliationItem";
import { ZeroFormProps } from "core/forms/types";

export type AffiliationListFormProps = ZeroFormProps<User>;

const AffiliationListForm : React.FC<AffiliationListFormProps> = ({
    value : user, 
    displayButtons=true,
    onButtonsChange
}) => {

    const {affiliations} = user;
    const [editedAffiliation, setEditedAffiliation] = useState(undefined as Affiliation | undefined);
    const modal = useModalState();
    
    const createHandler = useAction(async () => {
        setEditedAffiliation(undefined);
        modal.open();
        return null;
    });

    const editAffiliation = (affiliation : Affiliation) => {
        setEditedAffiliation(affiliation);
        modal.open();
    };

    const buttons = useMemo(() => {
        return [
            <Button key="new_affiliation" color="primary" {...createHandler.buttonProps}>New affiliation</Button>
        ];
    }, [createHandler]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [onButtonsChange, buttons]);
  
    return <>

        <AffiliationFormModal modal={modal}
            user={user} value={editedAffiliation}  />

        <Table striped hover className="border bg-white">
            <thead>
            <tr>
                <th>Affiliations</th>
            </tr>
            </thead>
            <tbody>
            {affiliations.length === 0 ? (<tr><td className="text-center small">You have no affiliation yet</td></tr>)
            : (affiliations.map(affiliation => (
                <tr key={affiliation.id} role="button" onClick={() => editAffiliation(affiliation)}>
                    <td><AffiliationItem value={affiliation} /></td>
                </tr>
            )))}
            </tbody>
        </Table>

        {displayButtons ? <Row className="row-cols-lg-auto">
            <Col className="ms-auto">{buttons}</Col>
        </Row> : null}
    </>;

};

export default AffiliationListForm;