import { Button, Card, CardBody, CardText, CardTitle, Col, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import React, { useEffect, useMemo, useState } from 'react';
import Modal, { ModalState } from "core/components/Modal";
import GenericStepper from "books/forms/steppers/GenericStepper";

export type ImportFormModalProps = {
    onSubmit? : () => void;
    modal : ModalState
};

type ImportSourceChoiceProps = {
    onChoiceChange : (choice : 'google' | 'springer' ) => void;
}

const ImportSourceChoice : React.FC<ImportSourceChoiceProps> = ({
    onChoiceChange
}) => {

    return <div>
        <h4 className="ps-5 pt-4">Choose your import source</h4>
        <Row className="m-3 g-3 mt-0">
            <Col md={6}>
                <Card role="button" style={{height: '200px'}} className="hover" onClick={() => onChoiceChange('google')}>
                    <img className="mx-2 my-3" alt="Google books" src="/images/books/imports/google.png" />
                    <CardBody>
                        <CardTitle tag="h5">Google books</CardTitle>
                        <CardText className="small">
                            Import from Google Books
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
            <Col md={6}>
                <Card role="button" style={{height: '200px'}} className="hover" onClick={() => onChoiceChange('springer')}>
                    <img className="mx-2 my-3" alt="Springer" src="/images/books/imports/springer.png" />
                    <CardBody>
                        <CardTitle tag="h5">Springer</CardTitle>
                        <CardText className="small">
                            Import books from Springer
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </div>;
}

const ImportFormModal : React.FC<ImportFormModalProps>= ({modal, onSubmit}) => {

    const [sourceChoice, setSourceChoice] = useState(undefined as undefined | 'google' | 'springer');
    const [buttons, setButtons] = useState([] as React.ReactElement[]);

    useEffect(() => {
        if(modal.isOpen) {
            setSourceChoice(undefined);
            setButtons([]);
        }
    }, [modal.isOpen])

    const formChoice = useMemo(() => {
        switch(sourceChoice) {
            case undefined : 
                return <ImportSourceChoice onChoiceChange={setSourceChoice} />
            case 'google' :
                return <GenericStepper source="google" value={undefined} displayButtons={false} onSubmit={modal.close} onButtonsChange={setButtons} />
            case 'springer' :
                return <GenericStepper source="springer" value={undefined} displayButtons={false} onSubmit={modal.close} onButtonsChange={setButtons} />

        }
    }, [sourceChoice, modal.close])

    return <Modal state={modal} size={sourceChoice === undefined ? 'md' : 'lg'}>
        <ModalHeader className="bg-light" toggle={modal.close}>Import Books</ModalHeader>

        <ModalBody className="p-0">
            {formChoice}
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default ImportFormModal;