import { useQueryClient } from "react-query";
import { useCallback } from "core/api/equality";
import { fetchTalksKey } from "talks/queryKeys";




export const useUpdateTalks = () => {
    const queryClient = useQueryClient();
    return useCallback(() => {
        queryClient.invalidateQueries({ queryKey : [fetchTalksKey] })
    }, [queryClient]);
};
