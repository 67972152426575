import AffiliationForm from "directory/forms/AffiliationForm";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {affiliationAPI} from 'directory/api/entities';
import React, { useContext, useState } from 'react';
import Modal from "core/components/Modal";
import User from "directory/api/models/User";
import Affiliation from "directory/api/models/Affiliation";
import { CUDFormModal } from "core/modals/types";
import { ButtonType } from "core/forms/types";
import { useAction } from "core/hooks/action";
import { useCallback } from "core/api/equality";
import { useUpdateUser } from "directory/hooks/user";
import { ToasterContext } from "core/hooks/toaster";
import { listRemove } from "core/helpers/list";

export type AffiliationFormModalProps = CUDFormModal<Affiliation> & {
    user : User;
};

const AffiliationFormModal : React.FC<AffiliationFormModalProps>= ({
    value, user,
    modal,
    onSubmit, onCreate, onUpdate, onDelete}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);
    const updateUser = useUpdateUser();
    const addToast = useContext(ToasterContext);

    const handleSubmit = React.useCallback(async (affiliation : Affiliation) => {
        modal.close();
        onSubmit && onSubmit(affiliation);
    }, [modal, onSubmit]);

    const deleteAction = useAction<Affiliation>(useCallback(async () => {
        if(value === undefined) {
            return undefined;
        }
        const data =  await affiliationAPI.delete(value);
        modal.close();
        onDelete && onDelete(value);
        const newUser = user.clone();
        newUser.affiliations = listRemove(user.affiliations, data);
        updateUser(newUser);
        addToast({
            type : 'danger',
            title : 'Affiliation removed',
            children : 'Affiliation successfully removed'
        });
        return data;
    }, [modal, onDelete, value, user, updateUser, addToast]));

    return <Modal state={modal} size="md">
        <ModalHeader className="bg-light" toggle={modal.close}>{value !== undefined  ? "Edit affiliation" : "New affiliation"}</ModalHeader>

        <ModalBody>

            <AffiliationForm user={user} value={value} displayButtons={false} onButtonsChange={setButtons}
                onSubmit={handleSubmit} onCreate={onCreate} onUpdate={onUpdate} />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            {value !== undefined ? <Button color="danger" {...deleteAction.buttonProps}>Delete</Button> : null}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default AffiliationFormModal;