import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { institutionAPI } from "institutions/api/entities";
import React, { useState } from 'react';
import Modal from "core/components/Modal";
import Institution from "institutions/api/models/Institution";
import { CUDFormModal } from "core/modals/types";
import InstitutionForm from "institutions/forms/InstitutionForm";
import { ButtonType } from "core/forms/types";
import { useCallback } from "core/api/equality";
import ConfirmationButton from "core/components/forms/ConfirmationButton";

export type InstitutionFormModalProps = CUDFormModal<Institution> & {
    defaultName? : string;
}

const InstitutionFormModal : React.FC<InstitutionFormModalProps>= ({
    value, 
    modal,
    defaultName,
    onSubmit, onCreate, onUpdate, onDelete}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);

    const handleSubmit = React.useCallback(async (value : Institution) => {
            modal.close();
            onSubmit && onSubmit(value);
        }, [modal, onSubmit]);

    const handleDelete = useCallback(async () => {
        if(value === undefined) {
            return;
        }
        const data =  await institutionAPI.delete(value);
        modal.close();
        onDelete && onDelete(value);
        return data;
    }, [modal, value, onDelete]);

    return <Modal state={modal} size="md">
        <ModalHeader className="bg-light" toggle={modal.close}>{value !== undefined ? "Edit institution" : "New institution"}</ModalHeader>

        <ModalBody>

            <InstitutionForm value={value} displayButtons={false} defaultName={defaultName}
                onButtonsChange={setButtons}
                onSubmit={handleSubmit} onCreate={onCreate} onUpdate={onUpdate} />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            {value !== undefined && value.id ? <ConfirmationButton color="danger" onClick={handleDelete}
                confirmQuestion={<>
                    <p>Are you sure you want to delete this institution?</p>
                </>}
                >Delete</ConfirmationButton> : null}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default InstitutionFormModal;