import ShortUser from "directory/api/models/ShortUser";
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { BasicItemProps } from "core/items/types";
import { useCallback } from "core/api/equality";

type ShortUserProps = BasicItemProps<ShortUser>;

const ShortUserCard : React.FC<ShortUserProps> = ({value : user}) => {

    const navigate = useNavigate();
  
    const handleClick = useCallback(() => {
      navigate(user.getProfilePage());
    }, [navigate, user]);
  
    return <div className="col mb-3 btn" onClick={handleClick}>
      <Card>
        <div className="row g-0 align-items-center" style={{minHeight: '7rem'}}>
         <div className="col-md-4">
            <img src={user.getAvatar()} width={80} className="img-fluid" alt="Avatar" />
         </div>
         <div className="col-md-8">
          <CardBody>
            <CardTitle tag="h5" className="small">{user.first_name} {user.last_name}</CardTitle>
            {<CardSubtitle className="small">{user.email}</CardSubtitle>}
          </CardBody>
      </div>
      </div>
      </Card>
      </div>;
};

export default ShortUserCard;