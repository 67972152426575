import { Col, Input, Pagination, PaginationItem, PaginationLink, Row } from "reactstrap";
import { useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import "core/components/PaginatedComponent.scss";
import { PaginatedData } from "core/api/BaseAPI";

export type LimitSelectProps = {
    limits? : Array<number>;
    limit? : number;
    onChange : (limit:number) => void;
};

const LimitSelect : React.FC<LimitSelectProps> = ({limits = [10, 20, 50, 100],limit = 10, onChange }) => {

    return <Input name="select" type="select" value={limit} onChange={(e) => onChange(parseInt(e.target.value))}>
        {limits.map(i => (
            <option key={i} value={i}>{i}</option>
        ))}
    </Input>
};

export type PaginationContext = {
    paginationNav : React.ReactNode;
    itemNumberSelect : React.ReactNode;
    limit : number;
    page : number;
    offset : number;
    count : number;
    firstItem : number;
    lastItem : number;
};

export type PaginationQuery = {
    offset : number;
    limit : number;
};


export type PaginationHeaderProps = {
    pagination : PaginationContext
    children? : React.ReactNode
};

export type PaginationFooterProps = {
    pagination : PaginationContext
};

export const PaginationHeader : React.FC<PaginationHeaderProps> = ({
    pagination,
    children
}) => {

    return <Row className="row-cols-lg-auto g-2 align-items-center mb-3 app-paginated-list-header">
        <Col>Show</Col>
        <Col>{pagination.itemNumberSelect}</Col>
        <Col className="me-auto">entries</Col>
        <Col>{children}
        </Col>
    </Row>;
};

export const PaginationFooter : React.FC<PaginationFooterProps> = ({pagination}) => {
    return <Row className="row-cols-lg-auto g-3 align-items-center app-paginated-list-footer pt-3">
        <Col className="me-auto mt-0"><p>Showing {pagination.firstItem+1} to {pagination.lastItem+1} of {pagination.count} elements</p></Col>
        <Col className="mt-0">{pagination.paginationNav}</Col>
    </Row>;
};


export type PaginatedComponentProps<DataType> = {
    query : (params : PaginationQuery) => Promise<PaginatedData<DataType>>;
    queryKey: any;
    children : (results:DataType[], pagination:PaginationContext) => React.ReactNode;
    defaultLimit? : number;
};

const PaginatedComponent = <DataType, > (props : PaginatedComponentProps<DataType>) => {

    const {query, queryKey, children, defaultLimit=100} = props;

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(defaultLimit);

    const queryHandler = useCallback(() => {
        return query({
            offset : page * limit,
            limit
        })
    }, [page, limit, query]);

    // If the query changes, then reset page number
    useEffect(() => 
        setPage(0), [queryKey,limit]);

    const { data, error, isError, isLoading } = useQuery(
        [queryKey, limit, page], queryHandler, { keepPreviousData : true } );

    const itemNumberSelect = useMemo(() => <LimitSelect limit={limit} onChange={setLimit} />, [limit, setLimit]);


    const count = data !== undefined ? data.count : 0;
    const pages = Math.ceil(count/limit);
    const offset = page * limit;

    const paginationNav = useMemo(() => (
        pages <= 1 ? null : <Pagination className="flex-wrap">
        {pages <= 2 ? null : <><PaginationItem disabled={page===0}><PaginationLink first onClick={() => setPage(0)}/></PaginationItem>
        <PaginationItem disabled={page===0}><PaginationLink previous onClick={() => setPage(page-1)} /></PaginationItem></>}
        {Array.from(Array(pages).keys()).map(i => (
             <PaginationItem active={page===i} key={i}><PaginationLink onClick={() => setPage(i)}>{i+1}</PaginationLink></PaginationItem>
        ))}
        {pages <= 2 ? null : <><PaginationItem disabled={page===(pages-1)}><PaginationLink next onClick={() => setPage(page+1)} /></PaginationItem>
        <PaginationItem disabled={page===(pages-1)}><PaginationLink last onClick={() => setPage(pages-1)} /></PaginationItem></>}
    </Pagination>
    ), [page, pages]);


    const pagination = useMemo(() => ({
        paginationNav,
        itemNumberSelect,
        limit,
        page,
        offset,
        count,
        firstItem: offset,
        lastItem: ((page+1)*limit > count? count-1 : (page+1)*limit-1)
    }), [count, itemNumberSelect, limit, offset, page, paginationNav]) as PaginationContext;

    if(isLoading) {
        return null;
    }

    if(isError) {
        throw error;
    }

    if(!data) {
        return null;
    }

    return <>{children(data.results, pagination)}</>;
};

export default PaginatedComponent;