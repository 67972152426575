import ReadEntityAPI from 'core/api/ReadEntityAPI';
import ViewEntityAPI from 'core/api/ViewEntityAPI';
import { Playlist } from 'videos/api/models/Playlist';
import { ShortVideo } from 'videos/api/models/ShortVideo';
import { Video } from 'videos/api/models/Video';
import { NewVideoType, UpdateVideoType } from 'videos/api/types';


export const playlistAPI = new ReadEntityAPI<string, Playlist>({
    baseURL: 'videos/playlists/',
    factory: Playlist
});

export const videoAPI = new ViewEntityAPI<string, Video, ShortVideo, NewVideoType, UpdateVideoType>({
    baseURL: 'videos/',
    factory: Video,
    shortFactory: ShortVideo
});

