import ReadEntityAPI from "core/api/ReadEntityAPI";
import { City, Country, Region } from "geonames/api/models";

export const cityAPI = new ReadEntityAPI<number, City>({
    baseURL : 'geonames/cities',
    factory : City,
});

export const regionAPI = new ReadEntityAPI<number, Region>({
    baseURL : 'geonames/regions',
    factory : Region
});

export const countryAPI = new ReadEntityAPI<number, Country>({
    baseURL : 'geonames/countries',
    factory : Country
});