import AutocompleteField from 'core/components/forms/AutocompleteField';
import { CompletionProps } from 'core/completions/types';
import { FormGroupProps } from 'core/forms/groups/types';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';

export type AutocompleteGroupProps = FormGroupProps & {
    Completion : React.FC<CompletionProps<string>>;
    emptyList? : string[];
};

const AutocompleteGroup : React.FC<AutocompleteGroupProps> = ({ 
    label, placeholder = 'Select a value', name,
    className, Completion, emptyList, bsSize,
}) => {

    const { errors } = useFormikContext<object>();

    return <FormGroup className={className}>
        <Label for={name}>{label}</Label>
        <AutocompleteField
            placeholder={placeholder}
            bsSize={bsSize}
            id={name} name={name} invalid={name in errors} 
            >
        {({value, setValue}) => <Completion text={value} onSelect={setValue} emptyList={emptyList} />}
        </AutocompleteField>
        <FormFeedback component="small" className="text-danger">{(errors[name] as { id : string })?.id}</FormFeedback>
    </FormGroup>
};

export default AutocompleteGroup;