import React from 'react';
import { PanelProps } from 'core/pages/PanelPage';
import BasicForm from "core/components/forms/BasicForm";
import AboutForm from 'directory/forms/AboutForm';


const AboutPanel : React.FC<PanelProps> = ({user}) => {

    return <div>

        <BasicForm title="About Tab">
            <AboutForm value={user} />
        </BasicForm>

                    

    </div>;
}

export default AboutPanel;