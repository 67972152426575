import Talk from "talks/api/models/Talk";
import React, { useMemo } from 'react';
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import TalkRenderer from 'talks/renderers/TalkRenderer';
import { BasicItemProps } from "core/items/types";

export type TalkProps = BasicItemProps<Talk>;


const TalkCard : React.FC<TalkProps> = ({ value : talk}) => {

    const renderer = useMemo(() => new TalkRenderer(talk), [talk]);

    return <div className="app-talk mb-3">
        <Card className="h-100">
            {renderer.getThumbnail()}
            <CardBody>
            <CardTitle tag="h5" className="small text-center">{talk.title}</CardTitle>
            <CardSubtitle className="mb-2 text-muted small text-center" tag="h6">
            {renderer.getEventName()}
            </CardSubtitle>
            <CardText className="text-muted small text-center">{renderer.getEventHumanDate()}</CardText>
            </CardBody>
        </Card>
    </div>;

};


export default TalkCard;