import Toast, { ToastProps } from 'core/components/Toast';
import React from 'react';

export type ToasterProps = {
    values : ToastProps[]
};

const Toaster : React.FC<ToasterProps> = ({values}) => {


    return <div className="toast-container position-fixed top-0 start-50 translate-middle-x p-3">
        {values.map((props) => <Toast {...props} />)}
    </div>;
};

export default Toaster;