import { Tooltip, TooltipContent, TooltipTrigger } from "core/components/Tooltip";
import React from 'react';
import { ShortSerial } from "serials/api/models";
import ShortSerialCard from "serials/cards/ShortSerialCard";

class ShortSerialRenderer {

    serial : ShortSerial;

    constructor(serial: ShortSerial) {
        this.serial = serial;
    }

    getTitleWithTip() : React.ReactNode {

        const serial = this.serial;

        const handleClick = () => {
            window.location.href = serial.getURL(); 
        }

        return <Tooltip placement="bottom">
                <TooltipTrigger
                 asChild={true}><span className="a" onClick={handleClick}>{this.serial.title}</span></TooltipTrigger>
                <TooltipContent className="p-0 m-0"><ShortSerialCard value={this.serial} /></TooltipContent>
            </Tooltip>;
    }
};

export default ShortSerialRenderer;