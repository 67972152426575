import { FormGroupProps } from 'core/forms/groups/types';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { X } from 'react-bootstrap-icons';

export type ImageFileGroupProps = FormGroupProps & {
    maxWidth? : string;
    validate? : (values : any, props : any) => string;
}

/*
Shorthand for FormGroup, Label, Input, FormFeedback
*/

const ImageFileGroup : React.FC<ImageFileGroupProps> = ({
    label, placeholder = "Drag 'n' drop an image here, or click to select images", 
    validate, maxWidth,
    name, className='', bsSize
}) => {


    
    const { touched, errors, values, setFieldValue } = useFormikContext<object>();
    const file = values[name] as File | null | string;

    const {getRootProps, getInputProps} = useDropzone({
        onDrop : (acceptedFiles : File[]) => {
            setFieldValue(name, acceptedFiles[0])
        },
        accept: {
            'image/png': ['.png' ],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/gif' : ['.gif']
          }
    });

    const removeLink = useMemo( () => {
        return <p><span className="a small" onClick={() => setFieldValue(name, null)}><X color="red" /> Remove</span></p>;
    }, [setFieldValue, name]);

    const content = useMemo( () => {
        if(file instanceof File) {
            return <>
                <img src={URL.createObjectURL(file)} className="w-100" style={{maxWidth: maxWidth}} alt="Uploaded" />
                {removeLink}
            </>;
            
        }
        if(file === null) {
            return <div {...getRootProps({className:'dropzone mt-3', role: 'button', style:{ minHeight: '100px' }})}>
                <input {...getInputProps()} />
                <p><img src="/images/upload.png" className="w-100 opacity-50" style={{maxWidth: '100px'}} alt="Upload" /></p>
                <p>{placeholder}</p>
            </div>;
        }
        else {
            return <>
                <img src={file} className="w-100" style={{maxWidth: maxWidth}} alt="Existing" />
                {removeLink}
            </>;
        }
        
    }, [file, getInputProps, getRootProps, placeholder, removeLink, maxWidth]);

    return <FormGroup className={className}>
        <Label for={name}>{label}</Label>
        <div className="text-center">{content}</div>        
        <FormFeedback component="small" className="text-danger">{touched[name] && errors[name]}</FormFeedback>
    </FormGroup>
}

export default ImageFileGroup;