import Affiliation from "directory/api/models/Affiliation";
import React from 'react';
import { SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { Col, Row } from 'reactstrap';
import { useMemo } from "core/api/equality";
import ShortInstitutionRenderer from "institutions/renderers/ShortInstitutionRenderer";


export type AffiliationItemProps = 
    SelectableItemProps<Affiliation>;

const AffiliationItem : React.FC<AffiliationItemProps> = ({
    value : affiliation, style, rootRef, 
    selectable = false, onSelect, 
    action = false, active = false}) => {

    const renderer = useMemo(() => {
        return new ShortInstitutionRenderer(affiliation.institution);
    }, [affiliation.institution])

    return <BaseGroupItem active={active} action={action || selectable} className="small" role="button" 
        onClick={selectable ? () => onSelect && onSelect(affiliation) : undefined} 
        style={style} rootRef={rootRef}>
        <Row>
            <Col md={7}>
                {renderer.getNameWithTip()}<br />
                <small className="text-muted">{affiliation.department}</small>
            </Col>
            <Col md={5}>
                {affiliation.position}<br />
                <small className="text-muted">{affiliation.getHumanPeriod()}</small>
            </Col>
        </Row>
    </BaseGroupItem>;
};

export default AffiliationItem;