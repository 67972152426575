
//import Autocomplete from "components/forms/Autocomplete";
import { useFormikContext } from "formik";
import React, { useCallback } from 'react';
import ObjectManyOne, { ItemProps, ObjectManyOneContext } from "core/components/forms/ObjectManyOne";

/**
    Formik-controlled autocomplete field
 */

type Props<ValueType> = {
    name : string;
    invalid? : boolean;
    id : string;

    bsSize? : 'lg' | 'sm';

    toValue? : (text:string) => ValueType;
    toItem : React.FC<ItemProps<ValueType>>;
    deletable? : boolean;
    draggable? : boolean;
    sortable? : boolean;
    mutable? : boolean;

    // Whether the values list is separated from the search field
    // Default : false
    disjoint? : boolean;

    // Whether the object should be unique or not
    // Default : false
    // If set to true, the completion will remove from the proposed values the ones already present
    unique? : boolean;

    // Callback called when a mutable item changes
    onItemChange? : (value:ValueType) => void;

     // Callback to call when an item is removed from the list
    onDelete? : (value:ValueType) => void;

    placeholder? : string;

    children? : (ctx:ObjectManyOneContext<ValueType>) => React.ReactNode;
};

const ObjectManyOneField = <ValueType, >(props:Props<ValueType>) => {

    const { name, invalid, children } = props;
    const { values, setFieldValue } = useFormikContext<object>();

    const setValueList = useCallback(
        (valueList : ValueType[]) => setFieldValue(name, valueList), 
        [name, setFieldValue]);

    const handleCreate = useCallback((value: ValueType) => {
        setValueList([...values[name], value]);
    }, [name, setValueList, values]);

    const handleDelete = useCallback((value: ValueType) => {
        setValueList(values[name].filter((v:string) => v !== value));
    }, [name, setValueList, values]);

    const handleReorder = useCallback((values: ValueType[]) => {
        setValueList(values);
    }, [setValueList]);

    return <div className={invalid ? 'is-invalid' : ''}>
        <ObjectManyOne {...props} valueList={values[name]} onCreate={handleCreate} onDelete={handleDelete} onReorder={handleReorder}>
            {children}
        </ObjectManyOne>
    </div>;
};

export default ObjectManyOneField;