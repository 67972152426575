import SideMainLayout from "core/components/layouts/SideMainLayout";
import React, { useState } from "react";
import BookSearch, { BookFilter } from "books/forms/searches/BookSearch";
import BookList from "books/lists/BookList";

const BookListPage : React.FC<{}> = () => {

    const [filter, setFilter] = useState({
        search : '',
        msc_subjects : [],
        categories : []
    } as BookFilter);

    return <SideMainLayout sideBarTitle="Filter" sideBarBody={
        <nav>
            <BookSearch onChange={setFilter} />
        </nav>}>
            <BookList filter={filter} />
        </SideMainLayout>;
};

export default BookListPage;