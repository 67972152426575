import { publicationAPI } from "publications/api/entities";
import User from "directory/api/models/User";
import PublicationItem from 'publications/items/PublicationItem';
import * as React from 'react';
import { useQuery } from 'react-query';
import { ListGroup } from 'reactstrap';
import { fetchPublicationsKey } from "publications/queryKeys";

type TabProps = {
    user : User;
    name : string;
}

const PublicationsTab : React.FC<TabProps> = ({user, name}) => {

    const { data : publications } = 
        useQuery([fetchPublicationsKey, user.id], () => publicationAPI.getAll({
            user_id : user.id,
            limit : 50
    }));

    if(!publications || publications.count === 0) {
        return <p>No publication</p>;
    }

    return <ListGroup>{publications.results.map(pub =>
            <PublicationItem key={pub.id} value={pub} />
        )}</ListGroup>;
};

export default PublicationsTab;