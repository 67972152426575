import React, { useEffect } from "react";
import { Alert } from "reactstrap";

export type AutoDismissAlertProps = {
    isOpen: boolean; // Decides whether the alert is visible or not
    setIsOpen: (state:boolean) => void; // Changes the visibility or the alert
    children: React.ReactNode; // Message of the alert
    color?: string  // General color of the alert : primary | secondary | danger | warning | info | light | dark
};

const AutoDismissAlert : React.FC<AutoDismissAlertProps> 
    = ({isOpen, setIsOpen, children, color='success'}) => {

    useEffect(() => {
        if(isOpen) {
            setTimeout(() => setIsOpen(false), 5000);
        }
    }, [isOpen, setIsOpen]);

    return <Alert isOpen={isOpen} color={color} toggle={() => setIsOpen(false)}>
        {children}
    </Alert>;
};

export default AutoDismissAlert;