import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { useState } from 'react';
import BasicInformationForm from "directory/forms/BasicInformationForm";
import Modal from "core/components/Modal";
import User from "directory/api/models/User";
import { UFormModal } from "core/modals/types";
import { ButtonType } from "core/forms/types";
import { useCallback } from "core/api/equality";

export type BasicInformationFormModalProps = UFormModal<User>;

const BasicInformationFormModal : React.FC<BasicInformationFormModalProps>= ({
    value, modal, 
    onSubmit, onUpdate}) => {

    const [buttons, setButtons] = useState([] as ButtonType[]);

    const handleSubmit = useCallback(async (user : User) => {
        modal.close();
        return onSubmit && onSubmit(user);
    }, [modal, onSubmit]);

    return <Modal state={modal} size="md">
        <ModalHeader className="bg-light" toggle={modal.close}>Edit basic information</ModalHeader>

        <ModalBody>
            <BasicInformationForm value={value} displayButtons={false} onButtonsChange={setButtons} onUpdate={onUpdate} onSubmit={handleSubmit}  />
        </ModalBody>

        <ModalFooter className="bg-light">
            {buttons}
            <Button color="secondary" onClick={modal.close}>Cancel</Button>
        </ModalFooter>
    </Modal>
};

export default BasicInformationFormModal;