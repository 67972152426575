import Publication from 'publications/api/models/Publication';
import { NewPublicationType } from "publications/api/types";
import BibtexDirectTextStep from 'publications/forms/steps/BibtexDirectTextStep';
import BibtexEntryChoiceStep from 'publications/forms/steps/BibtexEntryChoiceStep';
import { BaseFormProps } from 'core/forms/types';
import React, { useCallback, useMemo, useState } from 'react';
import Stepper from 'core/components/Stepper';
import { Col, Row } from 'reactstrap';



export type BibtexDirectStepperProps = BaseFormProps<undefined, Publication[]>;

const BibtexDirectStepper : React.FC<BibtexDirectStepperProps> = ({
    displayButtons: buttons = true,
    onSubmit,
    onButtonsChange
}) => {

    const [step, setStep] = useState(0 as 0 | 1);
    const [entries, setEntries] = useState([] as NewPublicationType[]);
    const [buttonsState, setButtonsState] = useState([] as React.ReactElement[]);

    const toStep1 = useCallback((entries : NewPublicationType[]) => {
        setEntries(entries);
        setStep(1);
    }, []);

    const handleButtonsChange = useCallback((buttons : React.ReactElement[]) => {
        setButtonsState(buttons);
        onButtonsChange && onButtonsChange(buttons);
    }, [onButtonsChange])

    const currentForm = useMemo(() => {
        switch(step) {
            case 0:
                return <BibtexDirectTextStep input={undefined} onButtonsChange={handleButtonsChange} onSubmit={toStep1}/>
            case 1:
                return <BibtexEntryChoiceStep input={entries} onButtonsChange={handleButtonsChange} onSubmit={onSubmit} />;
        }
    }, [step, entries, onSubmit, handleButtonsChange, toStep1])

    return <div>
        <Stepper 
            steps={[
                { label : 'Bibtex input' },
                { label : 'Publications choice'}
            ]} 
            activeStep={step} />

        {currentForm}

        {buttons ? (
            <Row className="row-cols-lg-auto">
                <Col className="ms-auto">
                    {buttonsState}
                </Col>
            </Row>
        ) : null}

    </div>
}

export default BibtexDirectStepper;