import ViewEntityAPI from "core/api/ViewEntityAPI";
import BookImportAPI from "books/api/BookImportAPI";
import { Book, BookCategory } from "books/api/models";
import BookAPI from "books/api/BookAPI";

export const bookCategoryAPI = new ViewEntityAPI<number, BookCategory, BookCategory, BookCategory, BookCategory>({
    baseURL : 'books/categories/',
    factory : BookCategory,
    shortFactory : BookCategory
 });
 
 export const bookAPI = new BookAPI({
    baseURL : 'books/',
    factory : Book,
 });

 export const bookImportAPI = new BookImportAPI({
    baseURL : 'books/imports/'
 });