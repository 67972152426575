
import React from 'react';
import { NavLink } from 'react-router-dom';
import { PORTAL_NAME } from 'config';
import UserMenu from 'core/components/UserMenu';
import { ThreeDots } from 'react-bootstrap-icons';


export interface AppNavBarProps {
    nav? : React.ReactNode;
};

const AppNavBar : React.FC<AppNavBarProps> = ({nav}) => {
    
    return <header className="navbar p-0 bd-navbar sticky-top">
        <nav className="container-xxl p-2 bd-gutter navbar-dark text-bg-dark flex-wrap flex-lg-nowrap" aria-label="Main navigation">

            {nav}

            {/* User menu */}
            <div className="order-lg-last"><UserMenu /></div>

            {/* Search bar and button to switch the right side bar */ }
            <div className="d-flex">
                <button className="navbar-toggler d-flex d-lg-none order-3 p-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#bdNavbar" aria-controls="bdNavbar" aria-label="Toggle navigation">
                <ThreeDots width="16" height="16" />
                </button>
            </div>
            
            {/* Right navigation menu */}
            <div className="offcanvas-lg offcanvas-end text-bg-dark flex-grow-1" id="bdNavbar" aria-labelledby="bdNavbarOffcanvasLabel" data-bs-scroll="true">
                <div className="offcanvas-header px-4 pb-0">
                    <h5 className="offcanvas-title text-white" id="bdNavbarOffcanvasLabel">Navigation</h5>
                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" data-bs-target="#bdNavbar"></button>
                </div>
                <div className="offcanvas-body p-4 pt-0 p-lg-0">
                    <hr className="d-lg-none text-white-50" />
                    {/*<NavLink className="navbar-brand justify-content-lg-start" to="/">{PORTAL_NAME}</NavLink> &nbsp; | */}
                    <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                        <li><NavLink className="nav-link px-2 text-white" to="/">{PORTAL_NAME}</NavLink></li>
                    </ul>
                </div> 
            </div>
        </nav>
        
        <nav className="container-xxl d-flex flex-wrap border-bottom text-bg-light shadow-sm">
                <ul className="nav m-auto app-nav-underlined">
                    <li><NavLink className="hover nav-link px-2 text-black" to="/directory">Community</NavLink></li>
                    <li><NavLink className="hover nav-link px-2 text-black" to="/books">Books</NavLink></li>
                    <li><NavLink className="hover nav-link px-2 text-black" to="/publications">Publications</NavLink></li>
                    <li><NavLink className="hover nav-link px-2 text-black" to="/videos">Videos</NavLink></li>
                </ul>
        </nav>
    </header>;
    
}

export default AppNavBar;