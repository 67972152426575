import { Book } from "books/api/models";
import { NewBookType } from "books/api/types";
import ShortUserRenderer from "directory/renderers/ShortUserRenderer";
import React from 'react';

class BaseBookRenderer<T extends Book | NewBookType | undefined> {

    book : T;

    constructor(book: T) {
        this.book = book;
    }

    getAuthors() : React.ReactNode {
        const book = this.book;
        if(book === undefined) {
            return;
        }
        if(book instanceof Book) {
            if(book.user_authors.length === 0) {
                return null;
            }
            const authors = book.user_authors.map(author => (
                author.user === null ? <>{author.getFullName()}</> : (new ShortUserRenderer(author.user)).getFullNameWithTip()
            ));
            const first_author = authors.shift();
            return authors.reduce(((element, author) => <>{element}, {author}</>), first_author);
        }
        if(book.authors.length === 0) {
            return null;
        }
        const authors = book.authors.map(author => author.name).join(', ');
        return authors;
    }

    getFullTitle() : React.ReactNode {
        const book = this.book;
        if(book === undefined) {
            return;
        }

        var title = <strong>{book.title}</strong>;
        if(book.subtitle) {
            return <>{title}<br />{book.subtitle}</>;
        }
        return title;
    }

    getZbmathId() : React.ReactNode {
        const book = this.book;
        if(book === undefined) {
            return;
        }
        if(book.zbmath_id !== null) {
            return <a href={"https://zbmath.org/" + book.zbmath_id}>{book.zbmath_id}</a>
        }
        return;
    }

    getGoogleBooksId() : React.ReactNode {
        const book = this.book;
        if(book === undefined) {
            return;
        }
        if(book.google_books_id !== null) {
            return <a href={"https://books.google.com/books?id=" + book.google_books_id}>{book.google_books_id}</a>
        }
        return;
    }

    getThumbnailUrl() : string {
        if(this.book === undefined) {
            return '';
        }
        if(this.book instanceof Book) {
            if(this.book.thumbnail === null) {
                return '';
            }
            return this.book.thumbnail;
        }

        if(this.book.thumbnail instanceof File) {
            return URL.createObjectURL(this.book.thumbnail);
        }

        if(this.book.thumbnail_url !== undefined) {
            return this.book.thumbnail_url;
        }

        return '';
    }

    getThumbnail() : React.ReactNode {

        const thumbnail = this.getThumbnailUrl();
        if(thumbnail === '') {
            return <div className="app-book-thumbnail border small text-bg-secondary p-3 text-center m-auto" style={{fontSize: '10px', maxWidth: '200px'}}>No thumbnail available</div>
        }
        return <img src={thumbnail} className="w-100" style={{maxWidth: '200px'}} alt="Book" />;
    }
};

export default BaseBookRenderer;