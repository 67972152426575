import SideMainLayout from "core/components/layouts/SideMainLayout";
import React, { useState } from "react";
import VideoSearch, { VideoFilter } from "videos/forms/searches/VideoSearch";
import PlaylistList from "videos/lists/PlaylistList";
import VideoList from "videos/lists/VideoList";

const VideoListPage : React.FC<{}> = () => {

    const [filter, setFilter] = useState({
        search : ''
    } as VideoFilter);

    return <SideMainLayout sideBarTitle="Filter" sideBarBody={
        <nav>
            <VideoSearch onChange={setFilter} />
        </nav>}>
            {filter.search === '' ? <PlaylistList filter={filter} /> : <VideoList filter={filter} />}
        </SideMainLayout>;
};

export default VideoListPage;