import { AutocompleteContext } from 'core/components/forms/Autocomplete';
import AutocompleteSelect from 'core/components/forms/AutocompleteSelect';
import { CompletionProps } from 'core/completions/types';
import { AutocompleteSelectFormGroupProps } from 'core/forms/groups/types';
import { SelectableItemProps } from 'core/items/types';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';

export type AutocompleteSelectGroupProps<ValueType> = AutocompleteSelectFormGroupProps & {
    Item : React.FC<SelectableItemProps<ValueType>>;
    Completion : React.FC<CompletionProps<ValueType>>;
    onAdd? : (value:string, ctx : AutocompleteContext<string>) => void;
};

const AutocompleteSelectGroup = <ValueType, >({ 
    label, placeholder = 'Select a value', name,
    className, bsSize,
    addable = false, onAdd,
    Completion, Item
} : AutocompleteSelectGroupProps<ValueType>) => {

    const { errors } = useFormikContext<object>();

    return <FormGroup className={className}>
        <Label for={name}>{label}</Label>
        <AutocompleteSelect 
            bsSize={bsSize}
            placeholder={placeholder}
            id={name} name={name} invalid={name in errors} 
            Item={Item}
            addable={addable}
            onAdd={onAdd}
            >
        {({autocompleteValue, setValue}) => <Completion text={autocompleteValue} onSelect={setValue} />}
        </AutocompleteSelect>
        <FormFeedback component="small" className="text-danger">{(errors[name] as { id : string })?.id}</FormFeedback>
    </FormGroup>
};

export default AutocompleteSelectGroup;