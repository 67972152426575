import { CompletionProps } from 'core/completions/types';
import CityItem from 'geonames/items/CityItem';
import { cityAPI } from 'geonames/api/entities';
import { City } from 'geonames/api/models';
import React from 'react';
import { fetchCitiesKey } from "geonames/queryKeys";
import DefaultCompletion from 'core/completions/DefaultCompletion';

export type CityCompletionProps = CompletionProps<City>;

const CityCompletion : React.FC<CityCompletionProps> = (props) => {
    return <DefaultCompletion 
        Item={CityItem} 
        queryKey={fetchCitiesKey}
        query={cityAPI.getAll.bind(cityAPI)}
        {...props} />
};

export default CityCompletion;

