import AutoDismissAlert from "core/components/AutoDismissAlert";
import { StepProps } from "core/forms/steps/types";
import { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import React from 'react';
import { errorToText } from "core/helpers/error";
import { useAction } from "core/hooks/action";
import { NewBookType } from "books/api/types";
import { bookImportAPI } from "books/api/entities";

export type SearchStepProps = StepProps<'google' | 'springer', NewBookType[]>;

const SearchStep : React.FC<SearchStepProps> = ({
    input,
    onSubmit,
    onButtonsChange
}) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('success');
    const [search, setSearch] = useState('');
    const submitHandler = useAction<NewBookType[]>(async () => {
        try {
            const result = await bookImportAPI.search(input, search);
            return result.entries;
        }
        catch(err) {
            setAlertColor('danger');
            setAlertMessage(errorToText(err))
            setAlertVisible(true);
            console.log(err);
        }
        return [];
    });
    submitHandler.addListener(onSubmit);

    useEffect(() => {
        onButtonsChange && onButtonsChange([
            <Button key="direct-step-1" color="primary" {...submitHandler.buttonProps}>Search</Button>
        ])
    }, [onButtonsChange, submitHandler.buttonProps])

    return <>

        <AutoDismissAlert isOpen={alertVisible} color={alertColor} setIsOpen={setAlertVisible}>{alertMessage}</AutoDismissAlert>

        <div className="p-5">
        <Input type="text" rows="10" placeholder="Search for a book" onChange={e => setSearch(e.target.value)} />
        </div>
    </>;

};

export default SearchStep;