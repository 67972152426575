import { Link } from "react-router-dom";
import MainLayout from "core/components/layouts/MainLayout";
import React from 'react';

export interface GenericErrorPageProps {
    title? : string;
    message? : string;
};

const GenericErrorPage : React.FC<GenericErrorPageProps> = (props) => {

    const title = props.title || 'An error has occured';
    const message = props.message || 'An unknown error occured. Please, go back to the main page';

    return <MainLayout>
        <div className="bg-light p-5 rounded mt-3">
        <h1>{title}</h1>
        <p className="lead">{message}</p>
        <Link to="/" className="btn btn-lg btn-primary">Main page</Link>
        </div>
        </MainLayout>;
        
};

export default GenericErrorPage;