import React from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/index.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { QueryClient, QueryClientProvider } from "react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

// Hack to disable very specific harmless warnings
const DISABLED_WARNINGS = [
  'useCustomCompareCallback should not be used with no dependencies. Use React.useCallback instead.',
  'useCustomCompareCallback should not be used with dependencies that are all primitive values. Use React.useCallback instead.',
  'useCustomCompareMemo should not be used with no dependencies. Use React.useCallback instead.',
  'useCustomCompareMemo should not be used with dependencies that are all primitive values. Use React.useMemo instead.',
  'useCustomCompareEffect should not be used with no dependencies. Use React.useEffect instead.',
  'useCustomCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.'
];
const DISABLED_ERRORS = [
  'Warning: %s is deprecated in StrictMode. %s was passed an instance of %s which is inside StrictMode. Instead, add a ref directly to the element you want to reference. Learn more about using refs safely here: https://reactjs.org/link/strict-mode-find-node%s'
];
const warn = console.warn;
const error = console.error;
console.warn = (message : any, ...rest) => {
  if(DISABLED_WARNINGS.indexOf(message) !== -1)
    return;
  warn(message, ...rest);
}
console.error = (message : any, ...rest) => {
  if(DISABLED_ERRORS.indexOf(message) !== -1)
    return;
  error(message, ...rest);
}

const queryClient = new QueryClient();

const rootElement = document.getElementById('root');
if(!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
