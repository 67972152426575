import ResearchInterestListForm from "directory/forms/lists/ResearchInterestListForm";
import AffiliationListForm from 'directory/forms/lists/AffiliationListForm';
import React from 'react';
import { PanelProps } from 'core/pages/PanelPage';
import BasicForm from "core/components/forms/BasicForm";

const AcademicPanel : React.FC<PanelProps> = ({user}) => {

    return <div>

        <BasicForm title="Affiliations">
            <AffiliationListForm value={user} />
        </BasicForm>

        <BasicForm title="Research Interests">
            <ResearchInterestListForm value={user} />
        </BasicForm>
                    

    </div>;
}

export default AcademicPanel;