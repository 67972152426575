import Publication from "publications/api/models/Publication";
import BaseGroupItem from 'core/items/BaseGroupItem';
import { BasicItemProps } from 'core/items/types';
import PublicationRenderer from 'publications/renderers/PublicationRenderer';
import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';

export type PublicationProps = BasicItemProps<Publication>;


const DetailedPublicationItem : React.FC<PublicationProps> = ({value : publication, style, rootRef}) => {

    const renderer = useMemo(() => new PublicationRenderer(publication), [publication]);


    return <BaseGroupItem className="app-publication border-0" style={style} rootRef={rootRef}>
        <Row className="small">
            <Col md={1} className="pb-0 pe-0 d-none d-md-block">{renderer.getThumbnail()}</Col>
            <Col>
                <table>
                    <tr>
                        <td className="text-secondary align-top pe-2">Title</td>
                        <td className="fw-bold">{renderer.getTitle()}</td>    
                    </tr>
                    <tr>
                        <td className="text-secondary align-top pe-2">Authors</td>
                        <td>{renderer.getAuthors()}</td>
                    </tr>
                    {renderer.getSerial() === null ? null : (
                    <tr>
                        <td className="text-secondary align-top pe-2">Serial</td>
                        <td>{renderer.getSerial()}</td>
                    </tr>
                    )}
                </table>
            </Col>
            <Col md="3" className="small">
                <table>
                    <tr>
                        <td className="text-secondary align-top pe-2">Arxiv ID</td>
                        <td>{renderer.getArxivId()}</td>
                    </tr>
                    <tr>
                        <td className="text-secondary align-top pe-2">ZbMath ID</td>
                        <td>{renderer.getZbmathId()}</td>
                    </tr>
                </table>
            </Col>
        </Row>
    </BaseGroupItem>;

};


export default DetailedPublicationItem;