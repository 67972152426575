import 'App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from 'react';
import NotFoundErrorPage from 'core/pages/errors/NotFoundErrorPage';
import IndexPage from 'core/pages/IndexPage';
import PanelPage from 'core/pages/PanelPage';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store, { persistor } from 'store/index';
import DirectoryRoute from 'directory/routes';
import CatchRoute from 'routes/CatchRoute';
import ProtectedRoute from 'routes/ProtectedRoute';
import { queryClient } from 'util/queryClient';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools} from 'react-query/devtools';
import "react-widgets/styles.css";
import UserPanel from 'directory/panels/UserPanel';
import AcademicPanel from 'directory/panels/AcademicPanel';
import AboutPanel from 'directory/panels/AboutPanel';
import AuthRoute from 'auth/routes';
import PublicationsPanel from 'publications/panels/PublicationsPanel';
import TalksPanel from 'talks/panels/TalksPanel';
import { ToasterContext, useToaster } from 'core/hooks/toaster';
import Toaster from 'core/components/Toaster';
import BooksRoute from 'books/routes';
import BooksPanel from 'books/panels/BooksPanel';
import PublicationsRoute from 'publications/routes';
import VideosRoute from 'videos/routes';

const panels = [
  {
    title : 'Profile',
    path : 'profile',
    sections : [
      {
        title : 'User Profile',
        path : 'user',
        element : UserPanel
      },
      {
        title : 'Academic Profile',
        path : 'academic',
        element : AcademicPanel
      },
      {
        title : 'About',
        path : 'about',
        element : AboutPanel
      },
      {
        title : 'Publications',
        path : 'publications',
        element : PublicationsPanel
      },
      {
        title : 'Talks',
        path : 'talks',
        element : TalksPanel
      }
    ]
  },
  {
    title : 'Books Management',
    path : 'books',
    sections : [
      {
        title : 'Books',
        path : 'books',
        element : BooksPanel
      }
    ] 

  }
];

export default function App() {

  const [toasts, addToast] = useToaster();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <Router>
            <div className="App">
                <ToasterContext.Provider value={addToast}>
                  <Toaster values={toasts} />
                <CatchRoute>
                <Routes>
                  <Route path="/directory/*" element={<DirectoryRoute />} />
                  <Route path="/publications/*" element={<PublicationsRoute />} />
                  <Route path="/books/*" element={<BooksRoute />} />
                  <Route path="/videos/*" element={<VideosRoute />} />
                  <Route path="/auth/*" element={<AuthRoute />} />
                  <Route path='/panel/*' element={<ProtectedRoute><PanelPage panels={panels} /></ProtectedRoute>} />

                  <Route path='/' element={<IndexPage />} />
                  <Route path='*' element={<NotFoundErrorPage />} />
                </Routes>
                </CatchRoute>
                </ToasterContext.Provider>
            </div>
          </Router>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
