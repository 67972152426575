import { CompletionProps } from 'core/completions/types';
import CountryItem from 'geonames/items/CountryItem';
import { countryAPI } from 'geonames/api/entities';
import { Country } from 'geonames/api/models';
import React from 'react';
import { fetchCountriesKey } from "geonames/queryKeys";
import DefaultCompletion from 'core/completions/DefaultCompletion';

export type CountryCompletionProps = CompletionProps<Country>;

const CountryCompletion : React.FC<CountryCompletionProps> = (props) => {
    return <DefaultCompletion 
        Item={CountryItem} 
        queryKey={fetchCountriesKey}
        query={countryAPI.getAll.bind(countryAPI)}
        {...props} />
};

export default CountryCompletion;
