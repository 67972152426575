import ShortUser from "../../../directory/api/models/ShortUser";


export default class Author {
    name: string;
    user: ShortUser | null;

    constructor(json: any) {
        json.user = json.user === null ? null : new ShortUser(json.user);
        Object.assign(this, json);
    }

    is(author: Author): boolean {
        return this.name === author.name
            && ((this.user === null && author.user === null)
                || (this.user !== null && author.user !== null && this.user.is(author.user)));
    }

    getFullName(): string {
        if (this.user === null) {
            const parts = this.name.split(',');
            return parts[1] + ' ' + parts[0];
        }
        return this.user.getFullName();
    }
}

