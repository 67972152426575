import { BaseAPI } from "core/api/BaseAPI";
import { NewPublicationType } from "publications/api/types";

type BibtexStatsType = {
    num_errors : number;
    entries : NewPublicationType[]
};

export type ImportAuthor = {
    name : string;
    url : string;
    bibtex_url : string;
    num_errors : number;
    num_entries : number;
    example_entries : NewPublicationType[]
};

class PublicationImportAPI extends BaseAPI {

    async bibtex_check(files : File[] | URL | string) : Promise<BibtexStatsType> {

        const myToken = this.token();
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `token ${myToken}`
        };
        const form = new FormData();
        if(files instanceof Array<File>) {
            for(const file of files) {
                form.append('files', file);
            }
        }
        else if(files instanceof URL) {
            form.append('url', files.href);
        }
        else {
            form.append('text', files)
        }
        const { data } = await this.axios.put('/bibtex/check/', 
            form, { headers });

        return data as BibtexStatsType;
    }

    async authors(source : 'dblp' | 'zbmath') : Promise<ImportAuthor[]> {

        const myToken = this.token();
        const headers = {
            Authorization: `token ${myToken}`
        };

        const { data } = await this.axios.get('/' + source + '/authors/', { headers });

        return data as ImportAuthor[];
    }
}

export default PublicationImportAPI;