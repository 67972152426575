import SideMainLayout from "core/components/layouts/SideMainLayout";
import IndexPanel from "core/panels/IndexPanel";
import { Routes, Route, NavLink } from "react-router-dom";
import { userAPI } from 'directory/api/entities';
import React from 'react';
import _ from 'lodash';

import { NavItem } from "reactstrap";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { fetchUserKey } from "directory/queryKeys";
import { AuthState } from "store/index";

import "core/pages/PanelPage.scss";
import User from "directory/api/models/User";

/*
type IconProps = {
    iconName : string;
    className? : string;
};*/

export type PanelProps = {
    user : User;
};

export type PanelItem = {
    path: string;
    title: string; 
    element: (props: PanelProps) => React.ReactNode;
};

export type PanelInterface = {
    path: string;
    title: string;
    sections : PanelItem[]
};

interface Props {
    panels: Array<PanelInterface>;
};

const SideBar : React.FC<Props> = ({panels}) => {

    return <div className="d-flex flex-column w-100 p-0 h-100">
        <ul className="nav nav-pills flex-column mb-auto">
        {panels.map(panel => {
            const uniqueId = _.uniqueId('panel');
            return <NavItem key={panel.path}>
                <button className="btn btn-toggle align-items-center rounded" data-bs-toggle="collapse" data-bs-target={"#" + uniqueId} aria-expanded="true">
                {panel.title}
                </button>
                <div className="collapse show" id={uniqueId}>
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    {panel.sections.map(section => (
                        <li key={panel.path + '/' + section.path}><NavLink className="link-dark rounded" to={"/panel/" + panel.path + '/' + section.path} aria-current="page">{section.title}</NavLink></li>
                    ))}
                </ul>
                </div>
            </NavItem>
        })}
        </ul>
    </div>;
}

const PanelPage : React.FC<Props> = ({panels}) => {

    const auth = useSelector( (state : AuthState) => state.auth);

    const { data : user, isError, isLoading } = 
        useQuery([fetchUserKey, auth.user?.id], () => userAPI.get(auth.user?.id || 0));

    if(!auth.user) {
        return null;
    }

    if(isLoading || isError || !user) {
        return null;
    }

    return <SideMainLayout sideBarTitle="Administration" sideBarBody={<SideBar panels={panels} />}>
        <div className="app-panel-page">
        <Routes>
            {<Route index element={<IndexPanel />} />}
            {panels.map((panel,i) => (
                panel.sections.map((section,j) => (
                    <Route key={panel.path + '/' + section.path} path={panel.path + '/' + section.path} element={section.element({user})} />
                ))
            ))}
        </Routes>
        </div>
    </SideMainLayout>;

};




export default PanelPage;