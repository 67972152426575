import { is } from "core/api/equality";
import Affiliation from "./Affiliation";
import ShortUser from "./ShortUser";
import { _hyperlinkToId } from "../../../core/api/models";
import { MscSubject } from "msc/api/models";


export default class User {
    id: number;
    first_name: string;
    last_name: string;
    webpage: string;
    email: string;
    avatar: string | null;
    msc_subjects: MscSubject[];
    affiliations: Affiliation[];
    primary_affiliation: string | undefined;

    constructor(json: any) {
        json.msc_subjects = json.msc_subjects.map((item: any) => new MscSubject(item));
        json.affiliations = json.affiliations.map((item: any) => new Affiliation(item));
        Object.assign(this, json);
    }

    is(user: User): boolean {
        return this.id === user.id
            && this.first_name === user.first_name
            && this.last_name === user.last_name
            && this.webpage === user.webpage
            && this.email === user.email
            && this.avatar === user.avatar
            && is(this.msc_subjects, user.msc_subjects)
            && is(this.affiliations, user.affiliations)
            && this.primary_affiliation === user.primary_affiliation;
    }

    clone(): User {
        const obj = Object.assign({}, this);
        Object.setPrototypeOf(obj, Object.getPrototypeOf(this));
        return obj;
    }


    /*
        Get the User Profile Page
    */
    getProfilePage(): string {
        return this.toShortUser().getProfilePage();
    }

    getAvatar(): string {
        return this.toShortUser().getAvatar();
    }

    /*
        Get the primary affiliation of the user, if it has one
        Otherwise, returns undefined.
    */
    getPrimaryAffiliation(): Affiliation | undefined {

        if (!this.primary_affiliation) {
            return undefined;
        }

        const affiliationId = _hyperlinkToId(this.primary_affiliation);

        return this.affiliations.reduce((prevValue, currentValue) => currentValue.id === affiliationId ? currentValue : prevValue, undefined);

    }

    /*
        Returns the list of all the current affiliations
    */
    getCurrentAffiliationList(): Affiliation[] {
        return this.affiliations.filter(item => item.isCurrent());
    }

    /*
        Returns the primary affiliation of a user
        or one current affiliation if no primary one is set
    */
    getPrimaryOrFirstCurrentAffiliation(): Affiliation | undefined {
        const primary = this.getPrimaryAffiliation();
        if (primary)
            return primary;
        const currentList = this.getCurrentAffiliationList();
        return currentList.length > 0 ? currentList[0] : undefined;
    }

    toShortUser(): ShortUser {
        return new ShortUser({
            id: this.id,
            first_name: this.first_name,
            last_name: this.last_name,
            webpage: this.webpage,
            email: this.email,
            avatar: this.avatar
        });
    }
}
