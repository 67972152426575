
import { API_URL } from 'config';
import Axios from 'axios';
import store from 'store/index';

const API_AUTH_URL = API_URL + 'auth/';

const axiosInstance = Axios.create({
    baseURL: API_AUTH_URL
});

export const login = async (login: string, password: string) => {
    
    const { data } = await axiosInstance.post('login/', {
        username: login,
        password
    }, {});
    return data;
      
};

export const logout = async () => {
    
    const myToken = store.getState().auth.token;
    const { data } = await axiosInstance.post('logout/', {}, {
       headers: {
            Authorization: `token ${myToken}`
       } 
    });
    return data;
};

export const sendResetEmail = async (email:string) => {
    const { data } = await axiosInstance.post('password-reset/', {
        email
   }, {});
    return data;
};

export const resetPassword = async (token:string, password:string) => {
    const { data } = await axiosInstance.post('password-reset/confirm/', {
        token,
        password
   }, {});
    return data;
};