import Publication from "publications/api/models/Publication";
import BaseGroupItem from 'core/items/BaseGroupItem';
import { BasicItemProps } from 'core/items/types';
import PublicationRenderer from 'publications/renderers/PublicationRenderer';
import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';

export type PublicationProps = BasicItemProps<Publication>;


const PublicationItem : React.FC<PublicationProps> = ({value : publication, style, rootRef}) => {

    const renderer = useMemo(() => new PublicationRenderer(publication), [publication]);


    return <BaseGroupItem className="app-publication border-0" style={style} rootRef={rootRef}>
        <Row>
            <Col md={1} className="pb-0 pe-0 d-none d-md-block">{renderer.getThumbnail()}</Col>
            <Col>{renderer.toElement()}</Col></Row>
    </BaseGroupItem>;

};


export default PublicationItem;