
import BasicForm from "core/components/forms/BasicForm";
import MainLayout from "core/components/layouts/MainLayout";
import React from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ResetPasswordForm from "auth/forms/ResetPasswordForm";

const ResetPasswordPage = () => {

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token') || '';

    if(!token) {
        throw Error('Missing reset token');
    }

    return  <MainLayout>
        <section className="py-5 row justify-content-center">
        <BasicForm title="Reset your password" style={{maxWidth: '600px'}}>
            <ResetPasswordForm token={token} />
            <Row className="mt-2">
                <Col><small><NavLink to="/auth/register">Register</NavLink></small></Col>
                <Col className="text-end"><small><NavLink to="/auth/login">Log in</NavLink></small></Col>
            </Row>
        </BasicForm>
        </section>
        </MainLayout>;
};

export default ResetPasswordPage;