import React from "react";
import { useCustomCompareEffect, useCustomCompareMemo, useCustomCompareCallback } from "use-custom-compare";

// Well known ZIP function (see python library)
// Assumption : all arrays have the same length
export const zip = (arrays : any[][]) => {
    return arrays[0].map(function(_,i : number){
        return arrays.map(function(array : any){return array[i]})
    });
}

// Equality function
// It is equivalent to referential equality, except for
// - arrays, which use extensionnality
// - objects : either they implement their own is() function, or they use referential equality
export const is = (x:any, y:any) :  boolean => {
    if(x === y) {
        return true;
    }
    if(x instanceof Array && y instanceof Array
        && x.length === y.length
        && zip([x, y]).reduce((eq, vals) => eq && is(vals[0], vals[1]), true)) {
        
        return true;
    }
    return typeof x === typeof y
        && x?.is !== undefined
        && x.is(y);
}

// Equality function for objects based on keys
// Assumption : they have the same set of keys
export const isSameObject = (x:object, y:object, keys = undefined) : boolean => {
    const iterator = keys === undefined ? x : keys;
    for(var key in iterator) {
        if(!is(x[key], y[key]))
            return false;
    }
    return true;
}

// Usual hooks whose dependencies are based on our new equality

export const useEffect = <TDependencyList extends React.DependencyList>(effect : React.EffectCallback, deps : readonly [...TDependencyList]) => 
    useCustomCompareEffect(effect, deps, is);

export const useMemo = <T, TDependencyList extends React.DependencyList>(memo : () => T, deps : readonly [...TDependencyList]) => 
    useCustomCompareMemo(memo, deps, is);

export const useCallback = <T extends (...args: any[]) => any, TDependencyList extends React.DependencyList>(factory : T, 
    deps : readonly [...TDependencyList]) => 
    useCustomCompareCallback(factory, deps, is);

// Use of React.memo base on our new equality

export const MemoFC = <P extends object>(Component : React.FunctionComponent<P>) => 
    React.memo(Component, isSameObject);
