
import React, { useState } from 'react';
import { fetchUsersKey } from 'directory/queryKeys';
import UserCard from 'directory/cards/UserCard';
import UserBigCard from 'directory/cards/UserBigCard';
import UserItem from 'directory/items/UserItem';
import { UserFilter } from 'directory/forms/searches/UserSearch';
import { userAPI } from 'directory/api/entities';
import { useCallback } from 'core/api/equality';
import User from 'directory/api/models/User';
import { useNavigate } from 'react-router-dom';
import PaginatedComponent, { PaginationFooter, PaginationHeader } from 'core/components/PaginatedComponent';
import { Button, Table } from 'reactstrap';
import { Grid, Grid3x3Gap, ListColumns } from 'react-bootstrap-icons';


export type UserListProps  = {
    filter : UserFilter;
};


export const enum ViewStyle {
  TABLE = 'TABLE',
  CARD = 'CARD',
  BIG_CARD = 'BIG_CARD'
};


const UserList : React.FC<UserListProps> = ({filter}) => {

  const navigate = useNavigate();
  const [viewStyle, setViewStyle] = useState(ViewStyle.TABLE);
  

  const query = useCallback((params : object) => {
      return userAPI.getAll({
          ...params,
          search : filter.search,
          msc_code : filter.msc_subjects.map(subject => subject.code).join(','),
          country_id : filter.countries.map(country => country.id).join(',')
      })
  }, [filter.countries, filter.msc_subjects, filter.search]);

  const handleClick = useCallback((user : User) => {
    navigate(user.getProfilePage());
  }, [navigate]);

  const content = useCallback((users : User[]) => {
    switch(viewStyle) {
      case ViewStyle.TABLE :
        return <Table striped hover className="border bg-white">
            <thead>
                  <tr>
                    <th>Users</th>
                  </tr>
                </thead>
                <tbody>
                  {users.length <= 0? (
                  <tr>
                    <td align="center">
                      <b>No user found.</b>
                    </td>
                  </tr>
                  ) : (
                    users.map(user => (
                    <tr key={user.id}><td><UserItem value={user}  selectable={true} onSelect={handleClick}/></td></tr>
                    ))
                  )}
                </tbody>
              </Table>;
      case ViewStyle.CARD :
            return <div className="row row-cols-2 row-cols-lg-4">
            {users.map(user => (
              <UserCard key={user.id} value={user} selectable={true} onSelect={handleClick} />
             ))}
             </div>;
      case ViewStyle.BIG_CARD :
            return <div className="row row-cols-2 row-cols-lg-2">
            {users.map(user => (
              <UserBigCard key={user.id} value={user} selectable={true} onSelect={handleClick} />
            ))}
            </div>;
    }
  }, [handleClick, viewStyle])

  return <PaginatedComponent queryKey={[fetchUsersKey, filter]} query={query} defaultLimit={20}>
    {(values, pagination) => <>
      <PaginationHeader pagination={pagination}>
        <Button outline className="border ms-2" onClick={() => setViewStyle(ViewStyle.CARD)} active={viewStyle === ViewStyle.CARD}>
                <Grid3x3Gap /></Button>

            <Button outline className="border ms-2" onClick={() => setViewStyle(ViewStyle.BIG_CARD)} active={viewStyle === ViewStyle.BIG_CARD}>
                <Grid /></Button>
            <Button outline className="border ms-2"  onClick={() => setViewStyle(ViewStyle.TABLE)} active={viewStyle === ViewStyle.TABLE}>
                <ListColumns /></Button>
      </PaginationHeader>{content(values)}<PaginationFooter pagination={pagination} />
    </>}
    </PaginatedComponent>;
   
};

export default UserList;
  