import React, { useCallback } from 'react';
import { Col, Input, Row } from 'reactstrap';
import { CheckableItemProps, SelectableItemProps } from 'core/items/types';
import BaseGroupItem from 'core/items/BaseGroupItem';
import { useMemo } from 'core/api/equality';
import { NewBookType } from 'books/api/types';
import BaseBookRenderer from 'books/renderers/BaseBookRenderer';


type NewBookItemProps = SelectableItemProps<NewBookType> &
    CheckableItemProps<NewBookType>;
  
const NewBookItem : React.FC<NewBookItemProps> = ({
        value, style, rootRef,
        selectable, onSelect,
        checkable=false, onCheckChange, checked=false
    }) => {

    const renderer = useMemo(() => new BaseBookRenderer(value), [value]);

    const handleCheckChange = useCallback(() => {
        onCheckChange && onCheckChange(value, !checked)
    }, [value, checked, onCheckChange]);
  
    const smallIfExists = (text : string | undefined) => 
      text ? <><br /><small className="text-muted">{text}</small></> : null;
  
    const handleClick = useCallback(() => {
        onSelect && onSelect(value);
    }, [onSelect, value]);

    return <BaseGroupItem role="button" onClick={selectable ? handleClick : undefined} style={style} rootRef={rootRef}>
        <Row>
        {checkable ? <Col md={1}>
                <Input type="checkbox" checked={checked} onChange={handleCheckChange} />
            </Col> : null}
        <Col md={2} className="d-none d-md-block" style={{maxWidth:'100px'}}>{renderer.getThumbnail()}</Col>
        <Col md={10 - (checkable ? 1 : 0)}>{value.title}
            {smallIfExists(value.subtitle)}<br />
            {renderer.getAuthors()}
        </Col>
      </Row>
      </BaseGroupItem>;
};

export default NewBookItem;

