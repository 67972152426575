import { Link } from "react-router-dom";
import MainLayout from "core/components/layouts/MainLayout";
import React from 'react';

const IndexPage = () => {

    return <MainLayout>
        <section className="py-5 text-center">
            <div className="row py-lg-5">
                <div className="col-lg-6 col-md-8 mx-auto">
                    <h1>Welcome to the portal of Computability Theory</h1>
                    <p className="lead text-muted">This portal contains resources in Computability Theory, 
                        destinated to student and researchers in the area, 
                        but also to science-enthousiastics.</p>
                    <p>
                        <Link className="btn btn-primary my-2" to="/">Learn the field</Link>
                        &nbsp;
                        <Link className="btn btn-secondary my-2" to="/directory">Meet the community</Link>
                    </p>
                </div>
            </div>
        </section>
    </MainLayout>
        
};

export default IndexPage;