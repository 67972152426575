import { useCallback, useEffect } from 'core/api/equality';
import { publicationAuthorAPI } from "publications/api/entities";
import Author from "publications/api/models/Author";
import ShortUserCompletion from 'directory/completions/ShortUserCompletion';
import { AutoFormProps } from 'core/forms/types';
import { useAction } from 'core/hooks/action';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { fetchAuthorCandidatesKey } from 'publications/queryKeys';


type AuthorPickerFormProps = AutoFormProps<Author> & {
    value : Author;
}

const AuthorPickerForm : React.FC<AuthorPickerFormProps> = ({ 
    value : author, 
    onButtonsChange,
    onSubmit, 
    displayButtons = true 
}) => {

    const [pickedUser, setPickedUser] = useState(author.user === null ? undefined : author.user)
    const [searchText, setSearchText] = useState('');

    const { data : emptyList } = 
        useQuery([fetchAuthorCandidatesKey, author.name], () => publicationAuthorAPI.getCandidates(author.name), {
            keepPreviousData : true
        } );

    const submitHandler = useAction<Author>(useCallback(async () => {
        const newAuthor = new Author({
            name : author.name,
            user : pickedUser === undefined ? null : pickedUser
        });
        onSubmit && onSubmit(newAuthor);
        return newAuthor;
    }, [author.name, pickedUser, onSubmit]));

    const handleSubmit = useCallback((e:SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        submitHandler.trigger();
    }, [submitHandler]);

    useEffect(() => {
        if(emptyList === undefined || emptyList.length === 0)
            return;
        setPickedUser(emptyList[0])
    }, [emptyList]);

    const buttons = useMemo(() => {
        return [<Button key="pick" color="primary" {...submitHandler.buttonProps}>Pick</Button>];
    }, [submitHandler]);

    useEffect(() => {
        onButtonsChange && onButtonsChange(buttons);
    }, [buttons, onButtonsChange])

    return <>
        <Alert color="info">
            Select a user corresponding to {author.name}
        </Alert>

        <Form onSubmit={handleSubmit}>

            <FormGroup>
                <Label for="search_text">User name</Label>
                <Input id="search_text" type="text" onChange={e => setSearchText(e.target.value)} />
            </FormGroup>
            
        </Form>

        {emptyList === undefined ? null :
        <ShortUserCompletion text={searchText} 
            onSelect={setPickedUser} 
            emptyList={emptyList} 
            selectedValue={pickedUser} />}

        {displayButtons ? <Row className="row-cols-lg-auto">
                <Col className="ms-auto">{buttons}</Col>
        </Row> : null}
    </>;
};

export default AuthorPickerForm;