import { NewBookType } from "books/api/types";
import { BaseAPI } from "core/api/BaseAPI";

type ImportStatsType = {
    num_errors : number;
    entries : NewBookType[]
};



class BookImportAPI extends BaseAPI {

    async search(source : 'google' | 'springer', search: string) : Promise<ImportStatsType> {

        const myToken = this.token();
        const headers = {
            Authorization: `token ${myToken}`
        };
        const { data } = await this.axios.put('/' + source + '/search/', 
            { search }, { headers });

        return data as ImportStatsType;
    }

}

export default BookImportAPI;